import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, IconButton, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  checkInAction: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const CheckInAction = ({ icon: Icon, label }) => {
  const classes = useStyles();

  return (
    <div>
      <IconButton className={classes.checkInAction} aria-label={label}>
        <Icon />
      </IconButton>
      <Box py={1}>
        <Typography variant="body2">{label}</Typography>
      </Box>
    </div>
  );
};

CheckInAction.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.elementType,
};

export default CheckInAction;
