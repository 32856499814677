import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { DELIMITER, STEPS } from "utils/constants";
import { formatDateMedium } from "utils/timekeep";

const useStyles = makeStyles((theme) => ({
  calendarIcon: {
    marginRight: "8px",
    fontSize: "16px",
    color: theme.palette.text.secondary,
  },
  timeIcon: {
    fontSize: "12px",
  },
  accordionSummary: {
    padding: "0px",
  },
  accordionDetails: {
    display: "block",
    padding: "0px",
  },
  description: {
    textTransform: "capitalize",
  },
}));

const DiningSelections = (props) => {
  const classes = useStyles();
  const diningEntries = Object.entries(props.diningSelections);
  return (
    <Box>
      <Divider />
      <Accordion elevation={0} defaultExpanded={true}>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="body2" color="textPrimary">
            {STEPS.SELECT_DINING_TIMES.STEPPER_TITLE}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {diningEntries.length > 0 ? (
            diningEntries.map(([date, selections], i) => {
              return (
                <Box mt={1} key={`date-${i}`}>
                  <Grid container>
                    <CalendarTodayIcon className={classes.calendarIcon} />
                    <Typography variant="caption" color="textSecondary">
                      {formatDateMedium(date)}
                    </Typography>
                  </Grid>
                  {Object.entries(selections).map(([type, value], i) => (
                    <Box key={`selection-${i}`}>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        className={classes.description}
                      >
                        {type} <AccessTimeIcon className={classes.timeIcon} />{" "}
                        {`${Object.values(value)[0]}, ${Object.keys(
                          value
                        )[0].substring(
                          Object.keys(value)[0].lastIndexOf(DELIMITER) +
                            DELIMITER.length
                        )}`}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              );
            })
          ) : (
            <Typography variant="caption" color="textSecondary">
              No dining selected
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

DiningSelections.propTypes = {
  diningSelections: PropTypes.object,
};

export default DiningSelections;
