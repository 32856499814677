import { createSlice } from "@reduxjs/toolkit";

const roomSlice = createSlice({
  name: "room",
  initialState: {
    roomDetails: [],
    individualRoomAvailability: {},
  },
  reducers: {
    setRoomDetails: (state, action) => {
      state.roomDetails = action.payload;
    },
    setIndividualRoomAvailability: (state, action) => {
      state.individualRoomAvailability = {
        ...state.individualRoomAvailability,
        [action.payload.unitGroupId]: action.payload.availability,
      };
    },
  },
});

export const {
  setRoomDetails,
  setIndividualRoomAvailability,
} = roomSlice.actions;
export default roomSlice.reducer;
