import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { sub, compareAsc } from "date-fns";
import { groupBy } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
} from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";

import { STEPS } from "utils/constants";
import api from "utils/api";
import {
  startOfDayInTimezone,
  endOfDayInTimezone,
  formatInTimezone,
  defaultTimezone,
} from "utils/timekeep";
import {
  setDuringStayEntertainment,
  setSelectedEntertainment,
  removeSelectedEntertainment,
} from "features/entertainment/entertainmentSlice";
import { getSelectedProperty } from "selectors/selectedProperty";
import { PLAN_STAY, SELECT_ADDONS } from "config/routes";
import { ENTERTAINMENT_START } from "utils/dateFormats";
import StepBreadcrumb from "components/StepBreadcrumb";
import Heading from "components/Heading";
import Basket from "components/Basket";
import LoadingSpinner from "components/LoadingSpinner";
import Navbar from "components/Navbar";
import FooterNavBar from "components/FooterNavBar";

import placeholderImage from "images/placeholder-image.jpg";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    flexDirection: "column",
    margin: "0 auto 1rem auto",
    paddingBottom: "8rem",
    width: theme.contentSize.mobilePageWidth,
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      width: theme.contentSize.pageWidth,
      maxWidth: theme.contentSize.maxPageWidth,
    },
  },
  splitContent: {
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      flexDirection: "row",
    },
  },
  detailsContainer: {
    flexGrow: 1,
  },
  basket: {
    width: "100%",
    marginBottom: "20px",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      marginLeft: "20px",
      width: "280px",
      flexShrink: 0,
    },
  },
  loadingSpinner: {
    display: "grid",
    padding: "3rem 0",
  },
  entertainment: {},
  entertainmentRow: {
    width: "100%",
    paddingBottom: "20px",
  },
  entertainmentRowBorder: {
    paddingTop: "20px",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  entertainmentCard: {
    width: "50%",
    padding: "5px",
  },
  entertainmentOption: {
    marginTop: theme.spacing(1.5),
    alignItems: "flex-start",
  },
  entertainmentTitle: {
    marginTop: "3px",
  },
  entertainmentCaption: {
    display: "block",
    margin: `${theme.spacing(2)}px 0`,
  },
  entertainmentImageContainer: {
    height: "230px",
    overflow: "hidden",
  },
  entertainmentImage: {
    maxWidth: "100%",
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
}));

// AJD: TODO - Refactor into a 'BookingStep' component, which contains all the common elements
const SelectEntertainment = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  const roomReservations = useSelector(
    (state) => state.booking.roomReservations
  );
  const selectedProperty = useSelector(getSelectedProperty);
  const propertyTimezone = selectedProperty?.timezone || defaultTimezone;
  const selectedArrival = roomReservations[0]?.arrival;
  const selectedDeparture = roomReservations[0]?.departure;
  const entertainmentOptions = useSelector(
    (state) => state.entertainment.duringStayEntertainment
  );
  const previouslySelectedEntertainmentChoices = useSelector(
    (state) => state.entertainment.selectedEntertainment
  );
  const [loading, setLoading] = useState(true);
  const [entertainmentChoices, setEntertainmentChoices] = useState(
    previouslySelectedEntertainmentChoices || {}
  );

  // Fetch entertainment for the duration of the stay
  // AJD: TODO - We aren't currently checking availability for the POC
  useEffect(() => {
    // Check we have the data we need for this step
    if (!selectedArrival) {
      props.history.push(PLAN_STAY);
      return;
    }

    let entertainmentRequest = api.getEntertainment({
      startDate: startOfDayInTimezone(selectedArrival, propertyTimezone),
      endDate: endOfDayInTimezone(
        sub(new Date(selectedDeparture), {
          days: 1, // Entertainment not applicable for last day of stay
        }).toISOString(),
        propertyTimezone
      ),
    });

    Promise.all([entertainmentRequest])
      .then((responses) => {
        const entertainmentRes = responses[0];

        if (entertainmentRes.status === 200) {
          dispatch(setDuringStayEntertainment(entertainmentRes.data));
          setLoading(false);
        }
      })
      .catch((error) => {
        props.onNotifOpen(error.message, { variant: "error" });
        props.history.push(PLAN_STAY);
      });
  }, []);

  const skipToNextStep = () => {
    // Clear any existing selections from redux
    dispatch(removeSelectedEntertainment());

    // Move to next step
    props.history.push({
      pathname: SELECT_ADDONS,
      data: {},
      state: {},
    });
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleContinue = () => {
    // Add selections to redux
    dispatch(setSelectedEntertainment(entertainmentChoices));

    // Move on to the next step
    props.history.push({
      pathname: SELECT_ADDONS,
      data: {},
      state: {},
    });
  };

  const makeEntertainmentChoice = (date, eventInfo) => (event) => {
    if (event.target.checked) {
      setEntertainmentChoices({
        ...entertainmentChoices,
        [date]: eventInfo,
      });
    }
  };

  const buildEntertainmentSelection = () => {
    // Group by day
    const groupedEntertainment = groupBy(
      entertainmentOptions,
      (entertainment) =>
        formatInTimezone(
          entertainment.start_time,
          propertyTimezone,
          "yyyy-MM-dd"
        )
    );

    // Make sure the keys are sorted in date order
    const dates = Object.keys(groupedEntertainment).sort((a, b) => {
      return compareAsc(new Date(a), new Date(b));
    });

    // Create a row of events for each date
    const entertainmentRows = [];
    dates.map((date, index) => {
      // Sort the entertainment for this date by performance value
      const performanceLookup = {
        High: 3,
        Mid: 2,
        Low: 1,
      };
      const entertainment = groupedEntertainment[date]
        .sort((a, b) => {
          const aVal = performanceLookup[a.performance_value];
          const bVal = performanceLookup[b.performance_value];
          if (aVal > bVal) {
            return -1;
          } else if (aVal < bVal) {
            return 1;
          }

          return 0;
        })
        .slice(0, 2); // Limit display to two events max

      // Collate the entertainment for this date
      const entertainmentCards = [];
      entertainment.map((event, index) => {
        entertainmentCards.push(
          <Grid
            container
            direction="column"
            key={`${date}-${index}`}
            className={classes.entertainmentCard}
          >
            <Grid item>
              <Typography variant="overline" color="textSecondary">
                {event?.venue?.name}
              </Typography>
            </Grid>

            <Grid item>
              <div className={classes.entertainmentImageContainer}>
                <img
                  alt="event image"
                  src={event?.image?.url || placeholderImage}
                  className={classes.entertainmentImage}
                />
              </div>
            </Grid>
            <Grid item>
              <FormControlLabel
                className={classes.entertainmentOption}
                value={event.title}
                control={
                  <Radio
                    size="small"
                    checked={entertainmentChoices[date]?.id === event.id}
                    // AJD - TODO: Duplicating some event info in state for now, for speed
                    onChange={makeEntertainmentChoice(date, {
                      id: event.id,
                      title: event.title,
                      date: event.start_time,
                      venueId: event.venue?.external_id,
                    })}
                  />
                }
                label={
                  <>
                    <Typography
                      variant="h6"
                      className={classes.entertainmentTitle}
                    >
                      {event?.title}
                    </Typography>
                    <Typography variant="body2">
                      {event?.description}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      Starts at{" "}
                      {formatInTimezone(
                        new Date(event?.start_time),
                        propertyTimezone,
                        ENTERTAINMENT_START
                      )}
                    </Typography>
                  </>
                }
              />
            </Grid>
          </Grid>
        );
      });

      // Add the complete row
      entertainmentRows.push(
        <Grid
          container
          direction="column"
          key={date}
          className={`${classes.entertainmentRow}${
            index > 0 ? ` ${classes.entertainmentRowBorder}` : ""
          }`}
        >
          <Grid item>
            <Typography variant="h6">{`Day ${index + 1}`}</Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              className={classes.entertainmentCaption}
            >{`Select your preferred entertainment option for ${formatInTimezone(
              date,
              propertyTimezone,
              "EEEE, d MMMM"
            )}.`}</Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row">
              {entertainmentCards}
            </Grid>
          </Grid>
        </Grid>
      );
    });

    return entertainmentRows;
  };

  return (
    <>
      <Navbar withProfile onNotifOpen={props.onNotifOpen} />
      <Grid container className={classes.mainContent}>
        <StepBreadcrumb currentStep={STEPS.SELECT_ENTERTAINMENT.INDEX} />
        <Heading titleText={STEPS.SELECT_ENTERTAINMENT.PAGE_TITLE}>
          <Button endIcon={<ArrowForward />} onClick={skipToNextStep}>
            {`skip (do this later)`}
          </Button>
        </Heading>
        <Grid container className={classes.splitContent}>
          <Grid className={classes.detailsContainer}>
            {!loading ? (
              <Grid container className={classes.entertainment}>
                {buildEntertainmentSelection()}
              </Grid>
            ) : (
              <div className={classes.loadingSpinner}>
                <LoadingSpinner loading size={70} />
              </div>
            )}
          </Grid>
          <Basket
            onNotifOpen={props.onNotifOpen}
            className={classes.basket}
            onServiceRemoved={() => {}}
          />
        </Grid>
      </Grid>
      <FooterNavBar onBack={handleBack} onContinue={handleContinue} />
    </>
  );
};

SelectEntertainment.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object,
  onNotifOpen: PropTypes.func,
};

export default SelectEntertainment;
