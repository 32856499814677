import React, { useReducer, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { eachDayOfInterval, parseISO, formatISO } from "date-fns";
import {
  compose,
  first,
  get,
  getOr,
  has,
  isEmpty,
  last,
  lowerCase,
  map,
  negate,
  nth,
  over,
  pick,
  set,
  size,
  sum,
  unset,
  update,
  values,
} from "lodash/fp";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Divider,
  Grid,
  Box,
  Tabs,
  Tab,
  Typography,
} from "@material-ui/core";
import { ArrowForward, Done } from "@material-ui/icons";
import moment from "moment";

import { STEPS, DELIMITER } from "utils/constants";
import {
  DINING_DATES_IN,
  DINING_DATES_OUT,
  DINING_TIMES_OUT,
  DINING_TIMES_IN,
} from "utils/dateFormats";
import { PLAN_STAY, SELECT_ENTERTAINMENT } from "config/routes";
import { DINING_START_TIME, DINING_END_TIME } from "utils/constants";
import {
  addDiningSelections,
  removeDiningSelections,
  setPrefetchAvailabilityViewed,
} from "features/dining/diningSlice";
import {
  selectDiningSelections,
  selectPrefetchAvailability,
} from "selectors/booking";
import DayHeader from "./DayHeader";
import { transformAPIAvailabilities } from "./utils";
import Navbar from "components/Navbar";
import StepBreadcrumb from "components/StepBreadcrumb";
import Heading from "components/Heading";
import LoadingSpinner from "components/LoadingSpinner";
import Basket from "components/Basket";
import FooterNavBar from "components/FooterNavBar";
import api from "utils/api";

const periodLabel = {
  padding: "0px 6px",
  borderRadius: "5px",
  textAlign: "center",
};

const fullParentSize = {
  width: "100%",
  height: "100%",
};

const minHeight = "2em";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    flexDirection: "column",
    margin: "0 auto 1rem auto",
    paddingBottom: "8rem",
    width: theme.contentSize.mobilePageWidth,
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      width: theme.contentSize.pageWidth,
      maxWidth: theme.contentSize.maxPageWidth,
    },
  },
  splitContent: {
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      flexDirection: "row",
    },
  },
  detailsContainer: {
    flexGrow: 1,
  },
  basket: {
    width: "100%",
    marginBottom: "20px",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      marginLeft: theme.spacing(8),
      width: "280px",
      flexShrink: 0,
    },
  },
  tabs: {
    minHeight,
  },
  tab: {
    padding: "0 5px",
    width: "50px",
    minWidth: 0,
    minHeight,
    ...theme.typography.body2,
  },
  selectedTab: {
    ...periodLabel,
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  timesRow: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(10em, 14em))",
    justifyContent: "space-around",
    alignItems: "center",
    gridGap: ".5rem",
  },
  scrollButtons: {
    color: theme.palette.text.secondary,
    width: "auto",
    "&.Mui-disabled": {
      opacity: 0.3,
    },
  },
  tabsIndicator: {
    display: "none",
  },
  "shiftCategory-dinner": {
    backgroundColor: theme.palette.info.lightBg,
    ...periodLabel,
  },
  "shiftCategory-breakfast": {
    backgroundColor: theme.palette.success.lightBg,
    ...periodLabel,
  },
  verticalLabel: {
    writingMode: "vertical-lr",
    transform: "rotate(180deg)",
    letterSpacing: "1px",
  },
  imagesLabel: {
    display: "grid",
    gridTemplateColumns: "1fr 5fr",
    alignItems: "center",
    gridGap: ".7rem",
  },
  images: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: ".5rem",
    ...fullParentSize,
  },
  figure: {
    ...fullParentSize,
    display: "flex",
    flexFlow: "column",
    margin: "0 0",
  },
  figureCaption: {
    ...theme.typography.overline,
    position: "absolute",
    transform: `translate(${theme.spacing(1)}px, 0px)`,
    backgroundColor: theme.palette.background.default,
    width: "fit-content",
    padding: "0 .5em",
  },
  image: {
    width: "20rem",
    ...fullParentSize,
  },
  labelGroup: {
    display: "grid",
    gridTemplateColumns: "5fr 1fr",
  },
  tick: {
    color: theme.palette.success.light,
  },
  loadingSpinner: {
    display: "grid",
    padding: "3rem 0",
  },
}));

const ACTION_TYPES = {
  SELECT: "select",
  DESELECT: "deselect",
};

const reducer = (
  state,
  { type, payload: { date, shiftCategory, time, venue } }
) => {
  switch (type) {
    case ACTION_TYPES.SELECT:
      return compose(
        set([date, shiftCategory, venue], time),
        unset([date, shiftCategory])
      )(state);
    case ACTION_TYPES.DESELECT:
      return unset([date, shiftCategory, venue], state);
    default:
      return state;
  }
};

// AJD: TODO - Refactor into a 'BookingStep' component, which contains all the common elements
const SelectDiningTimes = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const reduxDispatch = useDispatch();
  const previouslySelectedDiningChoices = useSelector(selectDiningSelections);
  const diningPrefetchAvailability = useSelector(selectPrefetchAvailability);
  const [state, dispatch] = useReducer(
    reducer,
    previouslySelectedDiningChoices || {}
  );
  const [availabilites, setAvailabilities] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);
  const [venueIds, setVenueIds] = useState([]);

  // Necessary to carry the restaurant name along to the summary page
  // `index` tracks which restaurant is picked
  // hacky? Definitely :-(
  const venueIdWithName = (venueId, index) =>
    venueId + DELIMITER + compose(get("name"), nth(index))(imageURLs);

  const renderKitchenTimes = (date, shiftCategory) => {
    const Times = ({ times, name: venue }, index) => {
      const formattedTimes = map(
        update("time", (time) =>
          moment(time, DINING_TIMES_IN).format(DINING_TIMES_OUT)
        ),
        times
      );

      const onClickHandler = (time) =>
        dispatch({
          type: has([date, shiftCategory, venue], state)
            ? ACTION_TYPES.DESELECT
            : ACTION_TYPES.SELECT,
          payload: {
            date,
            shiftCategory,
            time,
            venue: venueIdWithName(venue, index),
          },
        });

      return (
        <Box key={`${date}:${shiftCategory}:${venue}:${index}`}>
          <Tabs
            value={getOr(
              false,
              [date, shiftCategory, venueIdWithName(venue, index)],
              state
            )}
            onChange={(_, value) => onClickHandler(value)}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="select dinning slot"
            classes={{
              root: classes.tabs,
              scrollButtons: classes.scrollButtons,
              indicator: classes.tabsIndicator,
            }}
          >
            {map(
              ({ time, available = true }) => (
                <Tab
                  key={time}
                  classes={{
                    root: classes.tab,
                    selected: classes.selectedTab,
                  }}
                  value={time}
                  label={time}
                  disabled={!available}
                />
              ),
              formattedTimes
            )}
          </Tabs>
        </Box>
      );
    };

    Times.displayName = "Times";

    Times.propTypes = {
      times: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
    };

    return Times;
  };

  const renderPeriod = (label, periodTimes) => {
    const Period = (date) =>
      periodTimes ? (
        <Box py={1}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={2}>
              <div className={classes.labelGroup}>
                <Typography
                  variant="overline"
                  className={classes[`shiftCategory-${lowerCase(label)}`]}
                >
                  {label}
                </Typography>
                {compose(negate(isEmpty), get([date, label]))(state) && (
                  <Done className={classes.tick} />
                )}
              </div>
            </Grid>
            <Grid item xs={10}>
              <div className={classes.timesRow}>
                {periodTimes.map(renderKitchenTimes(date, label))}
              </div>
            </Grid>
          </Grid>
        </Box>
      ) : null;

    Period.displayName = "Period";

    return Period;
  };

  const renderRow = (kitchen) => {
    const Row = (date) => {
      const breakfastTimes = getOr([], ["breakfastTimes"], kitchen);
      const dinnerTimes = getOr([], ["dinnerTimes"], kitchen);

      return (
        <>
          {first(dates) !== date &&
            renderPeriod("breakfast", breakfastTimes)(date)}
          {last(dates) !== date && renderPeriod("dinner", dinnerTimes)(date)}
        </>
      );
    };

    Row.displayName = "Row";

    return Row;
  };

  const loading = size(availabilites) === 0;

  const renderDays = ({ date, ...rest }, index) => (
    <Grid key={date}>
      <DayHeader
        title={`Day ${index + 1} - ${moment(date, DINING_DATES_IN).format(
          DINING_DATES_OUT
        )}`}
      />
      <Box py={1}>
        <Grid>{renderRow(rest)(date)}</Grid>
      </Box>
    </Grid>
  );

  const skipToNextStep = () => {
    // Clear any existing selections from redux
    reduxDispatch(removeDiningSelections());

    // Move to next step
    props.history.push({
      pathname: SELECT_ENTERTAINMENT,
      data: {},
      state: {},
    });
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleContinue = () => {
    // Add selections to redux
    reduxDispatch(addDiningSelections(state));

    // Move on to the next step
    props.history.push(SELECT_ENTERTAINMENT);
  };

  const reservation = useSelector(
    compose(first, getOr([], ["booking", "roomReservations"]))
  );
  const arrivalDate = getOr("", ["arrival"], reservation).substring(0, 10);
  const departureDate = getOr("", ["departure"], reservation).substring(0, 10);
  const partySize = compose(
    sum,
    values,
    pick(["adults", "children"])
  )(reservation);

  let dates;
  if (arrivalDate && departureDate) {
    dates = compose(
      map((date) => formatISO(date, { representation: "date" })),
      eachDayOfInterval
    )({
      start: parseISO(arrivalDate),
      end: parseISO(departureDate),
    });
  }

  useEffect(() => {
    // Check we have the data we need for this step
    if (!arrivalDate) {
      props.history.push(PLAN_STAY);
      return;
    }

    api.getVenuesImages().then(
      compose(
        over([
          // fetch the targeted values
          // then, perform two state updates
          compose(setVenueIds, map(get(["external_id"]))),
          compose(
            setImageURLs,
            map(
              compose(
                // fetch url and name only. url must be digged out from "hero_image"
                update("hero_image", get("url")),
                pick(["hero_image", "name"])
              )
            )
          ),
        ]),
        getOr([], ["data"]) // fetch "data" and default to empty array
      )
    );
  }, []);

  useEffect(() => {
    // If this is the first viewing of this step, check if we have pre-fetched availability data available (triggered by the ChooseRoom component)
    if (
      !diningPrefetchAvailability.loading &&
      !diningPrefetchAvailability.viewed &&
      diningPrefetchAvailability.data.length &&
      !isEmpty(venueIds)
    ) {
      // Mark the prefetched data as viewed
      reduxDispatch(setPrefetchAvailabilityViewed(true));

      // Update our component state with the data
      setAvailabilities(
        transformAPIAvailabilities(diningPrefetchAvailability.data)
      );
    }
    // Check pre-fetched data isn't still loading, and try to fetch availability now
    else if (!diningPrefetchAvailability.loading && !isEmpty(venueIds)) {
      api
        .getVenuesAvailabilities({
          party_size: partySize,
          start_date: arrivalDate,
          end_date: departureDate,
          start_time: DINING_START_TIME,
          end_time: DINING_END_TIME,
          venues: venueIds,
        })
        .then(
          compose(
            setAvailabilities,
            transformAPIAvailabilities,
            getOr([], "data")
          )
        );
    }
  }, [venueIds, diningPrefetchAvailability.loading]);

  return (
    <>
      <Navbar withProfile onNotifOpen={props.onNotifOpen} />
      <Grid container className={classes.mainContent}>
        <StepBreadcrumb currentStep={STEPS.SELECT_DINING_TIMES.INDEX} />
        <Heading titleText={STEPS.SELECT_DINING_TIMES.PAGE_TITLE}>
          <Button endIcon={<ArrowForward />} onClick={skipToNextStep}>
            {`skip (do this later)`}
          </Button>
        </Heading>
        <Grid container className={classes.splitContent}>
          <Grid className={classes.detailsContainer}>
            {!isEmpty(imageURLs) ? (
              <Box pb={4}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={2}>
                    <div className={classes.imagesLabel}>
                      <Grid item className={classes.verticalLabel}>
                        <Typography variant="overline" color="textSecondary">
                          Our Restaurants
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Divider />
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={10}>
                    <div className={classes.images}>
                      {map(
                        ({ hero_image: url, name }) => (
                          <figure key={name} className={classes.figure}>
                            <figcaption className={classes.figureCaption}>
                              {name}
                            </figcaption>
                            <img
                              className={classes.image}
                              key={url}
                              src={url}
                              alt={name}
                            />
                          </figure>
                        ),
                        imageURLs
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <div className={classes.loadingSpinner}>
                <LoadingSpinner loading size={70} />
              </div>
            )}
            {loading && (
              <div className={classes.loadingSpinner}>
                <LoadingSpinner loading={loading} size={70} />
              </div>
            )}
            {availabilites.map(renderDays)}
          </Grid>
          <Basket
            onNotifOpen={props.onNotifOpen}
            className={classes.basket}
            onServiceRemoved={() => {}}
          />
        </Grid>
      </Grid>
      <FooterNavBar onBack={handleBack} onContinue={handleContinue} />
    </>
  );
};

SelectDiningTimes.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object,
  onNotifOpen: PropTypes.func,
};

export default SelectDiningTimes;
