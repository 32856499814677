import React from "react";
import PropTypes from "prop-types";

import { Button, Grid, Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    display: "flex",
    justifyContent: "center",
    position: "fixed",
    bottom: "0",
    width: "100%",
    padding: "1rem 0",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  footer: {
    width: "80%",
    maxWidth: "1300px",
    display: "flex",
  },
  continueButton: {
    height: "40px",
    marginLeft: "auto",
  },
}));

const FooterNavBar = (props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.footerContainer}>
      <Grid container className={classes.footer} justify="space-between">
        <Button color="inherit" variant="contained" onClick={props.onBack}>
          Back
        </Button>
        <Button
          className={classes.continueButton}
          color="primary"
          variant="contained"
          onClick={props.onContinue}
        >
          Continue
        </Button>
      </Grid>
    </Paper>
  );
};

FooterNavBar.propTypes = {
  onBack: PropTypes.func,
  onContinue: PropTypes.func,
};

export default FooterNavBar;
