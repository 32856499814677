export const KIOSK = "kiosk";

// Special addon service code used to associate ev charging to a service
export const EVPARKING_SERVICE_CODE = "EV_CHARGING";
export const EVPARKING_UNITGROUP_CODE = "PARKING";
export const AFTERNOON_TEA_CODE = "AFTEA";

export const STEPS = {
  CHOOSE_ROOM: {
    INDEX: 1,
    STEPPER_TITLE: "Select a room",
    PAGE_TITLE: "Choose your room",
  },
  CHOOSE_ROOM_NUMBER: {
    INDEX: 1,
    STEPPER_TITLE: "Select a room",
    PAGE_TITLE: "Select a specific room",
  },
  SELECT_DINING_TIMES: {
    INDEX: 2,
    STEPPER_TITLE: "Dining",
    PAGE_TITLE: "Select dining times",
  },
  SELECT_ENTERTAINMENT: {
    INDEX: 3,
    STEPPER_TITLE: "Entertainment",
    PAGE_TITLE: "Select your entertainment",
  },
  SELECT_ADDONS: {
    INDEX: 4,
    STEPPER_TITLE: "Enhancements",
    PAGE_TITLE: "Enhance your stay",
  },
  CHECKOUT: {
    INDEX: 5,
    STEPPER_TITLE: "Payment",
    PAGE_TITLE: "Payment",
  },
  SUMMARY: {
    INDEX: 6,
    STEPPER_TITLE: "Summary",
    PAGE_TITLE: "Booking Summary",
  },
};

export const DINING_START_TIME = "6:00";
export const DINING_END_TIME = "22:00";

export const DELIMITER = "~:~";

export const BREAK_LENGTHS = [3, 5, 7];
