import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";

import api from "utils/api";
import { trackRefundEvent } from "utils/analytics";
import { formatCurrency } from "utils/helpers";
import { removeReservationFromProfile } from "features/user/userSlice";

import { Typography, Box } from "@material-ui/core";
import MolliesModal from "components/MolliesModal";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cancelModel: {
    width: "50%",
  },
  loadingContainer: {
    textAlign: "center",
  },
}));

const CancelReservationModal = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const cancelReservation = () => {
    setLoading(true);

    const reservationParams = {
      propertyId: props.reservation.property.code,
      reservationId: props.reservation.id,
      lastName: props.reservation.primaryGuest.lastName,
    };
    api
      .cancelReservation(reservationParams)
      .then((response) => {
        dispatch(removeReservationFromProfile(props.reservation));
        trackRefundEvent(props.reservation);
        props.onReservationCancelled();
        props.onClose();
        props.onNotifOpen(
          `Reservation ${reservationParams.reservationId} successfully cancelled`,
          { variant: "success" }
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        props.onNotifOpen(error.message, { variant: "error" });
        setLoading(false);
      });
  };

  const freeCancellation = (reservation) => {
    const cancellationPrice = reservation?.cancellationFee?.fee?.amount || 0;

    return (
      cancellationPrice <= 0 ||
      new Date() < new Date(reservation.cancellationFee.dueDateTime)
    );
  };

  return (
    <MolliesModal
      title="Are you sure you would like to cancel this booking?"
      open={props.open}
      loading={loading}
      className={classes.cancelModel}
      content={
        freeCancellation(props.reservation)
          ? "You will receive a full refund as you are within the free cancellation period."
          : `You will be charged a cancellation fee of ${
              props.reservation.cancellationFee.fee
                ? formatCurrency(
                    props.reservation.cancellationFee.fee.currency,
                    props.reservation.cancellationFee.fee.amount
                  )
                : null
            }. If there is any remaining balance, a refund will be processed, and a separate invoice issued.`
      }
      buttons={[
        <Button
          key="reject"
          variant="outlined"
          color="primary"
          onClick={props.onClose}
        >
          {freeCancellation(props.reservation)
            ? "Do not cancel my booking"
            : "No, close"}
        </Button>,
        <Button
          key="confirm"
          variant="contained"
          color="primary"
          onClick={cancelReservation}
        >
          Cancel My Reservation
        </Button>,
      ]}
      loadingContent={
        <div>
          <Box mb={3}>
            <Typography variant="h6" color="textPrimary" align="center">
              Cancelling reservation
            </Typography>
          </Box>
          <Box className={classes.loadingContainer}>
            <LoadingSpinner loading={true} />
          </Box>
        </div>
      }
    />
  );
};

CancelReservationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  reservation: PropTypes.object,
  onClose: PropTypes.func,
  onReservationCancelled: PropTypes.func,
  onNotifOpen: PropTypes.func,
};

export default CancelReservationModal;
