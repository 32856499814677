import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import "react-dates/initialize";
import { DayPickerSingleDateController } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

import { formatCurrency } from "utils/helpers";
import { Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dayCell: {
    width: "38px",
    height: "38px",
    padding: "2px",

    ".CalendarDay__selected > &": {
      background: theme.palette.primary.main,
      borderRadius: "22px",
    },
  },
  dayCellDate: {
    fontFamily: `Gotham, "Helvetica", "Arial", sans-serif`,
    fontSize: "16px",
    lineHeight: "14px",
    letterSpacing: "0.15px",
    textTransform: "none",
    fontWeight: 400,
  },
  dayCellPrice: {
    fontFamily: `Gotham, "Helvetica", "Arial", sans-serif`,
    fontStyle: "normal",
    fontSize: "10px",
    lineHeight: "12px",
    fontWeight: 400,
    letterSpacing: "0.4px",
    opacity: 0.67,
  },
  datePickerContainer: {
    /* react-dates date range picker style overrides */

    // Modifies the container
    "& .DayPicker": {
      background: "transparent",
    },

    // The month grid
    "& .CalendarMonth, & .CalendarMonthGrid": {
      background: "transparent",
    },

    // The month name
    "& .CalendarMonth_caption ": {
      color: theme.palette.text.primary,
    },

    // The month forward / back buttons
    "& .DayPickerNavigation_button": {
      background: "transparent",
      border: "none",
    },

    // Color the arrow on forward / back buttons
    "& .DayPickerNavigation_svg__horizontal": {
      fill: theme.palette.text.primary,
    },

    // Day of week (Mon - Sun) headers
    "& .DayPicker_weekHeader_li ": {
      color: theme.palette.text.disabled,
    },

    // Modifies all days
    "& .CalendarDay": {
      background: "transparent",
      color: theme.palette.text.primary,
      border:
        "none !important" /* we never want to show borders so make important */,
    },

    // Modifies all days hover effect
    "& .CalendarDay:hover": {
      background: theme.palette.action.hover,
      border: "none",
    },

    // Will edit style of items between the range
    "& .CalendarDay__hovered_span": {
      background: theme.palette.action.hover,
      border: "none",
    },

    // Modifies all dates outside the range
    "& .CalendarDay__blocked_out_of_range, & .CalendarDay__blocked_out_of_range:hover": {
      background: "transparent",
      color: theme.palette.text.disabled,
    },

    // Modifies all blocked dates
    "& .CalendarDay__blocked_calendar, & .CalendarDay__blocked_calendar:hover": {
      background: "transparent",
      color: theme.palette.text.disabled,
      cursor: "default",
    },

    // Will edit everything selected
    "& .CalendarDay__selected_span": {
      background: theme.palette.primary.main,
      color: theme.palette.text.primary,
      border: "none",
    },

    // Hover effect for selected dates in a range
    "& .CalendarDay__selected_span:hover": {
      background: theme.palette.primary.border,
      border: "none",
    },

    // Will edit selected date or the endpoints of a range of dates
    "& .CalendarDay__selected": {
      color: theme.palette.grey[50],
    },

    // /* Style the start and end date with custom border radius */
    "& .CalendarDay__selected_start": {
      background: theme.palette.primary.main,
      borderTopLeftRadius: "22px",
      borderBottomLeftRadius: "22px",

      "&.CalendarDay:hover": {
        background: theme.palette.primary.main,
        border: "none",
      },
    },

    "& .CalendarDay__selected_end": {
      background: theme.palette.primary.main,
      borderTopRightRadius: "22px",
      borderBottomRightRadius: "22px",

      "&.CalendarDay:hover": {
        background: theme.palette.primary.main,
        border: "none",
      },
    },
  },
}));

const CheckInDatePicker = (props) => {
  const classes = useStyles();

  const onDateChanged = (date) => {
    props.dateUpdated(date ? date.toDate() : null);
  };

  const renderMonthContent = ({ month }) => {
    return (
      <Typography variant="body1" color="textPrimary">
        {month.format("MMMM YYYY")}
      </Typography>
    );
  };

  const isDayBlocked = (date) => {
    // Only allow breaks to start on a Monday (1), Wednesday (3) or Friday (5)
    return ![1, 3, 5].includes(date.day());
  };

  const isOutsideRange = (date) => {
    const fromDate = props.allowDatesFrom || moment();
    const today = moment();

    // Block anything before the from date or today - whichever is latest
    const latest = moment.max(fromDate, today);
    return date.isBefore(latest, "days");
  };

  const renderDayContents = (date) => {
    const price = props.priceForDate ? props.priceForDate(date) : null;
    let priceString = "";

    if (price) {
      const amount = price.amount;
      const currency = price.currency;

      priceString = formatCurrency(currency, amount, false);
    }

    return (
      <Grid
        container
        direction="column"
        justify="space-evenly"
        alignItems="center"
        className={classes.dayCell}
      >
        <span className={classes.dayCellDate}>{date.date()}</span>
        {price ? (
          <span className={classes.dayCellPrice}>{priceString}</span>
        ) : null}
      </Grid>
    );
  };

  return (
    <div className={classes.datePickerContainer}>
      <DayPickerSingleDateController
        date={moment(props.date)}
        onDateChange={onDateChanged}
        focused={true}
        renderMonthElement={renderMonthContent}
        renderDayContents={renderDayContents}
        noBorder={true}
        hideKeyboardShortcutsPanel={true}
        daySize={40}
        numberOfMonths={1}
        isDayBlocked={isDayBlocked}
        isOutsideRange={isOutsideRange}
        onNextMonthClick={props.onMonthChanged}
        onPrevMonthClick={props.onMonthChanged}
      />
    </div>
  );
};

CheckInDatePicker.propTypes = {
  date: PropTypes.instanceOf(Date),
  dateUpdated: PropTypes.func,
  priceForDate: PropTypes.func,
  onMonthChanged: PropTypes.func,
  allowDatesFrom: PropTypes.instanceOf(moment),
};

export default CheckInDatePicker;
