import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "features/user/userSlice";
import roomReducer from "features/room/roomSlice";
import propertyReducer from "features/property/propertySlice";
import bookingReducer from "features/booking/bookingSlice";
import paymentReducer from "features/payment/paymentSlice";
import diningReducer from "features/dining/diningSlice";
import entertainmentReducer from "features/entertainment/entertainmentSlice";
import afternoonTeaReducer from "features/afternoonTea/afternoonTeaSlice";

// Store persistance - to enable user auth to persist across sessions
const authPersistConfig = {
  key: "auth",
  version: 1,
  storage,
};

const store = configureStore({
  reducer: combineReducers({
    user: persistReducer(authPersistConfig, userReducer),
    room: roomReducer,
    property: propertyReducer,
    booking: bookingReducer,
    payment: paymentReducer,
    dining: diningReducer,
    entertainment: entertainmentReducer,
    afternoonTea: afternoonTeaReducer,
  }),
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

const persistor = persistStore(store);

export { store, persistor };
