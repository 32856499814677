import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import { compareAsc } from "date-fns";
import {
  compose,
  find,
  first,
  getOr,
  groupBy,
  includes,
  keys,
  last,
  map,
  split,
} from "lodash/fp";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
  Divider,
} from "@material-ui/core";
import {
  DateRange,
  Schedule,
  Phone,
  Chat,
  Event,
  Public,
  Email,
  Group,
} from "@material-ui/icons";

import { STEPS, DELIMITER } from "utils/constants";
import {
  CHECK_INOUT_IN,
  CHECK_INOUT_OUT,
  ARRIVAL_IN,
  ARRIVAL_OUT,
  ITINERARY_OUT,
} from "utils/dateFormats";
import { guestCountForReservation } from "utils/helpers";
import { defaultTimezone } from "utils/timekeep";
import {
  diningSelectionsToItinerary,
  entertainmentSelectionsToItinerary,
  afternoonTeaSelectionsToItinerary,
} from "./utils";
import {
  selectDiningSelections,
  selectEntertainmentSelections,
  selectAfternoonTeaSelections,
} from "selectors/booking";
import { PLAN_STAY } from "config/routes";
import Navbar from "components/Navbar";
import StepBreadcrumb from "components/StepBreadcrumb";
import Heading from "components/Heading";
import LoadingSpinner from "components/LoadingSpinner";
import Basket from "components/Basket";
import CancelReservationModal from "components/CancelReservationModal";
import CheckInAction from "./CheckInAction";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    flexDirection: "column",
    margin: "0 auto 1rem auto",
    paddingBottom: "8rem",
    width: theme.contentSize.mobilePageWidth,
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      width: theme.contentSize.pageWidth,
      maxWidth: theme.contentSize.maxPageWidth,
    },
  },
  splitContent: {
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      flexDirection: "row",
    },
  },
  basket: {
    width: "100%",
    marginBottom: "20px",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      marginLeft: "20px",
      width: "280px",
      flexShrink: 0,
    },
  },
  detailsContainer: {
    width: "100%",
    display: "grid",
    gridGap: "1rem",
    gridTemplateColumns: "3fr 4fr",
    alignItems: "start",
  },
  summarySection: {
    backgroundColor: theme.palette.grey[100],
    minHeight: "40rem",
  },
  dateLabel: {
    color: theme.palette.grey["a400"],
  },
  singleLine: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    lineClamp: 1,
    "-webkit-box-orient": "vertical",
  },
  cardDetails: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  checkInSummary: {
    display: "grid",
    gridTemplateColumns: "auto auto 1fr",
    alignItems: "center",
    justifyContent: "center",
    gridGap: ".75rem",
    padding: ".75rem 0",
  },
  twoElementGrid: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridGap: ".3rem",
  },
  checkInActions: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    textAlign: "center",
  },
  contactDetail: {
    padding: ".3rem 0",
  },
  contactDetailIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(0.5),
  },
  requestToCancel: {
    color: theme.palette.error.darkText,
    paddingLeft: "0",
  },
  gridGap: {
    gridGap: "1rem",
  },
  verticalLine: {
    position: "relative",
    paddingLeft: "0",
    listStyleType: "none",
    margin: "0",
    "&:before": {
      content: "''",
      width: "1px",
      position: "absolute",
      top: "14px",
      bottom: "0",
      backgroundColor: theme.palette.other.border,
      height: "calc(100% - 22px)",
    },
  },
  verticalLineItem: {
    position: "relative",
    margin: `0 0 0 ${theme.spacing(2)}px`,
    "&:before": {
      content: "''",
      width: "12px",
      height: "12px",
      position: "absolute",
      top: "12px",
      left: `calc(-${theme.spacing(2)}px - 6px)`,
      backgroundColor: "white",
      borderRadius: "100%",
      border: `solid 3px ${theme.palette.success.main}`,
    },
    "&:last-child": {
      marginBottom: theme.spacing(5),

      "&:after": {
        content: "''",
        width: "6px",
        height: "6px",
        position: "absolute",
        bottom: "6px",
        left: `calc(-${theme.spacing(2)}px - 3px)`,
        backgroundColor: "black",
        borderRadius: "100%",
        border: `solid 2px ${theme.palette.grey[800]}`,
      },
    },
  },
  itineraryImage: {
    height: "10rem",
    width: "100%",
    objectFit: "cover",
  },
}));

const formatCheckInDate = (date) =>
  moment(date, CHECK_INOUT_IN).format(CHECK_INOUT_OUT);

const formatItineraryDate = (date) =>
  moment(date, ARRIVAL_IN).format(ITINERARY_OUT);

const formatArrivalDate = (date) =>
  moment(date, ARRIVAL_IN).format(ARRIVAL_OUT);

// AJD: TODO - Refactor into a 'BookingStep' component, which contains all the common elements
const Summary = (props) => {
  const classes = useStyles();

  const [cancelReservationModalOpen, setCancelReservationModalOpen] = useState(
    false
  );

  const selectedProperty = useSelector((state) =>
    find({ code: state.property.selectedPropertyId }, state.property.properties)
  );
  const reservation = useSelector(
    compose(first, getOr([], ["booking", "roomReservations"]))
  );
  const diningSelections = useSelector(selectDiningSelections);
  const entertainmentSelections = useSelector(selectEntertainmentSelections);
  const afternoonTeaSelections = useSelector(selectAfternoonTeaSelections);
  const confirmationId = useSelector(
    getOr([], ["booking", "bookingConfirmationId"])
  );

  const loading = false;

  const handleCancelReservationModalClose = () =>
    setCancelReservationModalOpen(false);

  // TODO
  const handleReservationCancelled = () => {};

  const collateReservationsToItinerary = () => {
    // Determine the number of guests
    const totalGuests = guestCountForReservation(reservation);

    // Collate all reservations into a single array
    const collatedArray = [
      ...diningSelectionsToItinerary(diningSelections, totalGuests),
      ...entertainmentSelectionsToItinerary(
        entertainmentSelections,
        totalGuests,
        selectedProperty?.timezone || defaultTimezone
      ),
      ...afternoonTeaSelectionsToItinerary(afternoonTeaSelections, totalGuests),
    ];

    // Now sort by date/time - date and time components are separate, so we need to combine for the purposes of sorting
    return collatedArray.sort((a, b) => {
      return compareAsc(
        new Date(`${a.date}T${a.time}`),
        new Date(`${b.date}T${b.time}`)
      );
    });
  };

  const websiteUrl = getOr(
    "",
    ["contact_info", "website_url"],
    selectedProperty
  );

  const phoneNumber = getOr(
    "",
    ["contact_info", "phone_number"],
    selectedProperty
  );

  const email = getOr("", ["contact_info", "email_address"], selectedProperty);
  const propertyImageUrl = getOr("", ["hero_image", "url"], selectedProperty);
  const propertyName = getOr("", ["name"], selectedProperty);
  const arrivalDate = getOr("", ["arrival"], reservation).substring(0, 10);
  const departureDate = getOr("", ["departure"], reservation).substring(0, 10);
  const checkInTime = getOr("15:00:00", "check_in_time", selectedProperty);
  const checkOutTime = getOr("10:30:00", "check_out_time", selectedProperty);

  const itinerary = [
    {
      imageUrl: propertyImageUrl,
      title: propertyName,
      eventName: "Arrival Check-in",
      time: checkInTime,
      attendeesCount: 2,
      imageAlt: propertyName,
      date: arrivalDate,
    },
    ...collateReservationsToItinerary(),
    {
      title: propertyName,
      eventName: "Check-out",
      time: checkOutTime,
      attendeesCount: 2,
      date: departureDate,
    },
  ];

  const itineraryByDate = groupBy("date", itinerary);
  const getVenueName = (eventName) =>
    includes(DELIMITER, eventName)
      ? compose(last, split(DELIMITER))(eventName)
      : eventName;

  useEffect(() => {
    // Check we have the data we need for this step
    if (!reservation) {
      props.history.push(PLAN_STAY);
    }
  }, []);

  return (
    <>
      <Navbar withProfile onNotifOpen={props.onNotifOpen} />
      <Grid container className={classes.mainContent}>
        <StepBreadcrumb currentStep={STEPS.SUMMARY.INDEX} />
        <Heading titleText={STEPS.SUMMARY.PAGE_TITLE} />
        <LoadingSpinner loading={loading} />
        <Grid
          container
          className={classNames(
            classes.splitContent,
            loading ? classes.hidden : null
          )}
        >
          <Grid className={classes.detailsContainer}>
            <Grid>
              {compose(
                map((key) => (
                  <React.Fragment key={key}>
                    <Typography
                      variant="overline"
                      className={classes.dateLabel}
                    >
                      {formatItineraryDate(key)}
                    </Typography>
                    <ul className={classes.verticalLine}>
                      {map(
                        ({
                          imageUrl,
                          title,
                          eventName,
                          time,
                          attendeesCount,
                          imageAlt,
                        }) => (
                          <li
                            key={`${title}:${eventName}:${time}`}
                            className={classes.verticalLineItem}
                          >
                            <Box py={1}>
                              <Card>
                                {Boolean(imageUrl) && (
                                  <img
                                    className={classes.itineraryImage}
                                    loading="lazy"
                                    alt={imageAlt}
                                    src={imageUrl}
                                  />
                                )}
                                <Box p={2}>
                                  <Typography
                                    variant="overline"
                                    className={classes.singleLine}
                                  >
                                    {title}
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    className={classes.singleLine}
                                  >
                                    {getVenueName(eventName)}
                                  </Typography>
                                  <div
                                    className={classNames(
                                      classes.cardDetails,
                                      classes.twoElementGrid,
                                      classes.gridGap
                                    )}
                                  >
                                    <div className={classes.twoElementGrid}>
                                      <Schedule fontSize="small" />
                                      <Typography variant="body2">
                                        {formatCheckInDate(time)}
                                      </Typography>
                                    </div>
                                    <div className={classes.twoElementGrid}>
                                      <Group fontSize="small" />
                                      <Typography variant="body2">
                                        {attendeesCount}
                                      </Typography>
                                    </div>
                                  </div>
                                </Box>
                              </Card>
                            </Box>
                          </li>
                        ),
                        itineraryByDate[key]
                      )}
                    </ul>
                  </React.Fragment>
                )),
                keys
              )(itineraryByDate)}
            </Grid>
            <Card className={classes.summarySection}>
              <CardContent>
                <Grid container direction="column">
                  <Typography variant="overline" color="textSecondary">
                    {getOr("", ["name"], selectedProperty)}
                  </Typography>
                  <Typography variant="h5" color="textPrimary">
                    Arrival Check-In
                  </Typography>
                  <Box mb={2} className={classes.checkInSummary}>
                    <div className={classes.twoElementGrid}>
                      <DateRange fontSize="small" />
                      <Typography variant="body2">
                        {formatArrivalDate(arrivalDate)}
                      </Typography>
                    </div>
                    <div className={classes.twoElementGrid}>
                      <Schedule fontSize="small" />
                      <Typography variant="body2">
                        {formatCheckInDate(checkInTime)}
                      </Typography>
                    </div>
                    <div className={classes.twoElementGrid}>
                      <Group fontSize="small" />
                      <Typography variant="body2">{2}</Typography>
                    </div>
                  </Box>
                  <Divider />
                  <Box py={2} className={classes.checkInActions}>
                    <CheckInAction label="Call" icon={Phone} />
                    <CheckInAction label="Chat Online" icon={Chat} />
                    <CheckInAction label="Add to Calendar" icon={Event} />
                  </Box>
                  <Divider />
                  <Box mt={1} mb={2}>
                    <Box pb={1}>
                      <Typography variant="overline">
                        Contact Details
                      </Typography>
                    </Box>
                    <div
                      className={classNames(
                        classes.twoElementGrid,
                        classes.contactDetail
                      )}
                    >
                      <Public
                        fontSize="small"
                        className={classes.contactDetailIcon}
                      />
                      <Link rel="noopener" href={websiteUrl}>
                        {websiteUrl}
                      </Link>
                    </div>
                    <div
                      className={classNames(
                        classes.twoElementGrid,
                        classes.contactDetail
                      )}
                    >
                      <Phone
                        fontSize="small"
                        className={classes.contactDetailIcon}
                      />
                      <Link href={`tel:${phoneNumber}`}>{phoneNumber}</Link>
                    </div>
                    <div
                      className={classNames(
                        classes.twoElementGrid,
                        classes.contactDetail
                      )}
                    >
                      <Email
                        fontSize="small"
                        className={classes.contactDetailIcon}
                      />
                      <Link href={`mailto:${email}`}>{email}</Link>
                    </div>
                  </Box>
                  <Divider />
                  <Box mt={1} mb={2}>
                    <Box pb={1}>
                      <Typography variant="overline">
                        Cancellation Policy
                      </Typography>
                    </Box>
                    <Typography variant="body2" color="textSecondary">
                      {getOr(
                        "",
                        ["cancellationFee", "description"],
                        reservation
                      )}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box my={1.5}>
                    <Button className={classNames(classes.requestToCancel)}>
                      Request to Cancel
                    </Button>
                  </Box>
                  <Divider />
                  <Box mt={2}>
                    <Typography variant="body2">
                      {`Reservation confirmation: ${confirmationId}`}
                    </Typography>
                  </Box>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Basket
            onNotifOpen={props.onNotifOpen}
            className={classes.basket}
            onServiceRemoved={() => {}}
            preventServiceRemoval={true}
          />
        </Grid>
        <CancelReservationModal
          open={cancelReservationModalOpen}
          reservation={reservation}
          onClose={handleCancelReservationModalClose}
          onReservationCancelled={handleReservationCancelled}
          onNotifOpen={props.onNotifOpen}
        />
      </Grid>
    </>
  );
};

Summary.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object,
  onNotifOpen: PropTypes.func,
};

export default Summary;
