import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import compose from "lodash/fp/compose";
import merge from "lodash/fp/merge";
import add from "date-fns/add";

// Material UI
import { Box, Grid, Typography, makeStyles } from "@material-ui/core";

// Components
import Navbar from "components/Navbar";
import SelectBookingForm from "components/SelectBookingForm";
import ExclusiveBreak from "components/ExclusiveBreak";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Footer from "./Footer";

// Utils
import { dateToStr, getDefaultCheckInDate } from "utils/timekeep";
import {
  getChooseRoomQueryString,
  getCommonSearchParams,
  isKioskFromSearchParams,
  isSingleRoomModeFromSearchParams,
} from "utils/helpers";
import api from "utils/api";
import { CHOOSE_ROOM, PLAN_STAY } from "config/routes";
import { BREAK_LENGTHS } from "utils/constants";

// Hooks
import { useSignInWithFirebaseToken } from "hooks";
import { useSearchParams } from "hooks";

// Slices
import {
  changeAdultsInRoom,
  changeChildrenInRoom,
  decrementRoomsOccupancy,
  incrementRoomsOccupancy,
  resetBooking,
  setCommonSearchParams,
} from "features/booking/bookingSlice";
import { setSelectedPropertyId } from "features/property/propertySlice";
import {
  resetPrefetchAvailability,
  removeDiningSelections,
} from "features/dining/diningSlice";
import {
  setUpcomingEntertainmentHighlights,
  removeSelectedEntertainment,
} from "features/entertainment/entertainmentSlice";
import { removeSelectedAfternoonTea } from "features/afternoonTea/afternoonTeaSlice";

// Selectors
import { selectCommonSearchParams } from "selectors/booking";

const useStyles = makeStyles((theme) => ({
  planStayRoot: {
    display: "flex",
    flexDirection: "column",
  },
  availCheckerForm: {
    marginTop: theme.spacing(5),
    width: "max-content",
  },
  heroContainer: {
    backgroundImage: "url('/images/hero.jpg')",
    backgroundSize: "cover",
    width: "100%",
    minHeight: "fit-content",
    maxHeight: "65vh",
    height: "620px",
    backgroundPositionY: "90%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(4),
  },
  heroContentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    flexBasis: "min-content",
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
  },
  heroText: {
    background: theme.palette.primary.dark,
    color: theme.palette.grey["a100"],
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    textAlign: "center",
  },
  loadingContainer: {
    display: "flex",
    textAlign: "center",
    height: "150px",
  },
  pageContentContainer: {
    display: "flex",
    justifyContent: "center",
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
  pageContent: {
    maxWidth: theme.contentSize.maxPageWidth,
  },
  entertainmentContainer: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    justifyContent: "center",
  },
}));

const PlanStay = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const properties = useSelector((state) => state.property.properties);
  const selectedPropertyId = useSelector(
    (state) => state.property.selectedPropertyId
  );
  const roomsOccupancy = useSelector((state) => state.booking.roomsOccupancy);
  const entertainmentOptions = useSelector(
    (state) => state.entertainment.upcomingEntertainmentHighlights
  );
  const searchParams = useSearchParams();
  useSignInWithFirebaseToken(searchParams.fbauthtoken);

  const { arrival, departure } = useSelector(selectCommonSearchParams);

  // Check availability button disabled until ready
  const [loaded, setLoaded] = useState(false);

  // Keep track of entertainment data loading
  const [entertainmentLoaded, setEntertainmentLoaded] = useState(false);

  // Defaults to the next mon, wed or fri
  const [checkInDate, setCheckInDate] = useState(
    arrival ? moment(arrival).toDate() : getDefaultCheckInDate()
  );

  // Defaults to first available check in date + 3 days
  const [checkOutDate, setCheckOutDate] = useState(
    departure
      ? moment(departure).toDate()
      : moment(getDefaultCheckInDate()).add(3, "days").toDate()
  );

  // Flag to toggle between promo code and corporate code
  const [promoCode, setPromoCode] = useState("");
  const [corporateCode, setCorporateCode] = useState("");

  const [numNights, setNumNights] = useState(3);

  const isSameDay = (d1, d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getDate() === d2.getDate() &&
      d1.getMonth() === d2.getMonth()
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isSameDay(checkInDate, checkOutDate)) {
      props.onNotifOpen(
        "You must stay at least one night to reserve. Please select a date range greater than one day.",
        { variant: "error" }
      );
    } else {
      goToChooseRoom(checkInDate, checkOutDate);
    }
  };

  const goToChooseRoom = (checkInDate, checkOutDate) => {
    const availabilitySearchParams = {
      propertyId: selectedPropertyId,
      arrival: dateToStr(checkInDate),
      departure: dateToStr(checkOutDate),
      promoCode,
      corporateCode,
    };
    const generatedSearchParams = getChooseRoomQueryString(
      availabilitySearchParams,
      roomsOccupancy,
      0
    );

    dispatch(resetBooking());
    dispatch(resetPrefetchAvailability());
    dispatch(removeDiningSelections());
    dispatch(removeSelectedEntertainment());
    dispatch(removeSelectedAfternoonTea());
    dispatch(
      compose(
        setCommonSearchParams,
        getCommonSearchParams,
        merge(searchParams)
      )(availabilitySearchParams)
    );

    props.history.push({
      pathname: CHOOSE_ROOM,
      search: generatedSearchParams,
    });
  };

  const handlePropertyChange = (event) => {
    dispatch(setSelectedPropertyId(event.target.value));

    updateStayDatesForProperty(event.target.value);
  };

  const handlePromoCodeChange = (event) => {
    setPromoCode(event.target.value);
  };

  const handleCorporateCodeChange = (event) => {
    setCorporateCode(event.target.value);
  };

  const handleCheckInDateChanged = (checkInDate) => {
    setCheckInDate(checkInDate);

    // Calculate the check out date from the number of nights
    setCheckOutDate(moment(checkInDate).add(numNights, "days").toDate());
  };

  const handleNumNightsChange = (event) => {
    const nights = parseInt(event.target.value);
    setNumNights(nights);

    // Update check out date
    setCheckOutDate(moment(checkInDate).add(nights, "days").toDate());
  };

  const handleAddRoom = () => {
    dispatch(incrementRoomsOccupancy());
  };

  const handleRemoveRoom = () => {
    dispatch(decrementRoomsOccupancy());
  };

  const handleAdultsChange = (stepperName, count) => {
    dispatch(
      changeAdultsInRoom({
        roomIndex: stepperName.split("-")[1],
        count,
      })
    );
  };

  const handleChildrenChange = (stepperName, count) => {
    dispatch(
      changeChildrenInRoom({
        roomIndex: stepperName.split("-")[1],
        count,
      })
    );
  };

  const getAllowFromDate = (propertyCode = selectedPropertyId) => {
    const selectedProperty = properties.find((p) => p.code === propertyCode);

    if (!selectedProperty) {
      return moment();
    }

    const settings = selectedProperty.booking_flow_settings;
    if (settings && settings.allow_bookings_from) {
      return moment(settings.allow_bookings_from);
    }

    return moment();
  };

  const updateStayDatesForProperty = (propertyId) => {
    // Update check in and out dates if no longer in range
    // Pass the new property code here as state doesnt immediately update
    const minDate = getAllowFromDate(propertyId);
    if (moment(checkInDate) < minDate) {
      const checkInDate = getDefaultCheckInDate(minDate.toDate());

      setCheckInDate(checkInDate);
      setCheckOutDate(moment(checkInDate).add(numNights, "days").toDate());
    }
  };

  useEffect(() => {
    if (!selectedPropertyId) {
      return;
    }

    // Enable check availability button
    setLoaded(true);
  }, [selectedPropertyId]);

  useEffect(() => {
    if (
      searchParams.propertyId &&
      (!selectedPropertyId || selectedPropertyId !== searchParams.propertyId)
    ) {
      dispatch(setSelectedPropertyId(searchParams.propertyId));
    }
  }, [searchParams.propertyId]);

  useEffect(() => {
    updateStayDatesForProperty(selectedPropertyId);
  }, [properties, selectedPropertyId]);

  // Fetch entertainment highlights for the next two months
  useEffect(() => {
    let entertainmentRequest = api.getEntertainment({
      startDate: new Date().toISOString(),
      endDate: add(new Date(), {
        months: 2,
      }).toISOString(),
      performanceValue: "High",
    });

    Promise.all([entertainmentRequest])
      .then((responses) => {
        const entertainmentRes = responses[0];

        if (entertainmentRes.status === 200) {
          dispatch(setUpcomingEntertainmentHighlights(entertainmentRes.data));
          setEntertainmentLoaded(true);
        }
      })
      .catch((error) => {
        props.onNotifOpen(error.message, { variant: "error" });
        props.history.push(PLAN_STAY);
      });
  }, []);

  return (
    <div className={classes.planStayRoot}>
      {!isKioskFromSearchParams(searchParams) && (
        <Navbar withProfile centerLogo onNotifOpen={props.onNotifOpen} />
      )}
      <div className={classes.heroContainer}>
        <div className={classes.heroContentContainer}>
          <Typography variant="h2" className={classes.heroText}>
            Welcome to Heythrop Hotel, your adventure starts here
          </Typography>
          <SelectBookingForm
            className={classes.availCheckerForm}
            onSubmit={handleSubmit}
            showPropertiesDropdown
            properties={properties}
            selectedPropertyId={selectedPropertyId}
            onPropertyChange={handlePropertyChange}
            allowDatesFrom={getAllowFromDate()}
            checkInDate={checkInDate}
            checkOutDate={checkOutDate}
            onCheckInDateChange={handleCheckInDateChanged}
            numberOfNights={numNights}
            onNumberOfNightsChange={handleNumNightsChange}
            singleRoomMode={isSingleRoomModeFromSearchParams(searchParams)}
            roomsOccupancy={roomsOccupancy}
            onAddRoom={handleAddRoom}
            onRemoveRoom={handleRemoveRoom}
            onAdultsChanged={handleAdultsChange}
            onChildrenChanged={handleChildrenChange}
            promoCode={promoCode}
            corporateCode={corporateCode}
            onPromoCodeChanged={handlePromoCodeChange}
            onCorporateCodeChanged={handleCorporateCodeChange}
            enabled={loaded}
          />
        </div>
      </div>
      <div className={classes.pageContentContainer}>
        <div className={classes.pageContent}>
          <Box my={11}>
            <Typography variant="h4" align="center">
              Exclusive breaks
            </Typography>
            <Typography variant="body1" align="center">
              Don’t miss out on our upcoming events at Heythrop Hotel
            </Typography>

            {entertainmentLoaded ? (
              <Grid
                container
                spacing={1}
                className={classes.entertainmentContainer}
              >
                {/* Show first 3 options */}
                {entertainmentOptions.slice(0, 3).map((event, idx) => (
                  <Grid item xs={4} key={idx}>
                    <ExclusiveBreak
                      event={event}
                      breakLength={BREAK_LENGTHS[idx]}
                      onBook={goToChooseRoom}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <div className={classes.loadingContainer}>
                <LoadingSpinner loading={true} />
              </div>
            )}
          </Box>

          <Footer />
        </div>
      </div>
    </div>
  );
};

PlanStay.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  onNotifOpen: PropTypes.func,
};

export default PlanStay;
