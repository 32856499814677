import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, Grid, Typography } from "@material-ui/core";
import { Remarkable } from "remarkable";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: "95%",
    padding: theme.spacing(2),
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      width: "530px",
    },
  },
  closeButton: {
    position: "absolute",
    right: "1rem",
    top: "1rem",
    cursor: "pointer",
    color: theme.palette.textSecondary,
  },
}));

const MarkdownPopover = (props) => {
  const classes = useStyles();
  const md = new Remarkable();

  const createMarkup = () => {
    return {
      __html: md.render(props.markdown),
    };
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      PaperProps={{ className: classes.dialogPaper }}
    >
      <Grid>
        <Typography variant="h6" color="primary" className={classes.title}>
          {props.title}
        </Typography>
        <CloseIcon className={classes.closeButton} onClick={props.onClose} />
      </Grid>
      <div dangerouslySetInnerHTML={createMarkup()}></div>
    </Dialog>
  );
};

MarkdownPopover.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  markdown: PropTypes.string,
};

export default MarkdownPopover;
