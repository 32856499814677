import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Modal, Button, Grid, Typography } from "@material-ui/core";

import { getChooseRoomQueryString } from "utils/helpers";

const useStyles = makeStyles((theme) => ({
  noAvailModalContent: {
    boxShadow: theme.shadows[5],
    backgroundColor: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    padding: "20px",
  },
  modalHeader: {
    justifyContent: "center",
  },
  contentRow: {
    marginBottom: "40px",
  },
  modalActions: {
    justifyContent: "flex-end",
  },
}));

const NoAvailModal = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const hasMoreRoomsToChoose = () => {
    return props.roomsOccupancy.length > props.roomOfRoomsCounter;
  };

  const handleContinueClick = () => {
    if (hasMoreRoomsToChoose()) {
      history.push({
        pathname: "/choose-room",
        search: getChooseRoomQueryString(
          props.availCheckParams,
          props.roomsOccupancy,
          props.roomOfRoomsCounter
        ),
        state: {
          roomOfRooms: props.roomOfRoomsCounter + 1,
        },
      });
    } else {
      // continue to checkout
      history.push("/checkout");
    }
  };

  const handleNewAvailCheckClick = () => {
    history.push("/plan-stay");
  };

  return Object.keys(props.availCheckParams).length ? (
    <Modal open={props.open} onClose={props.onClose}>
      <Grid container className={classes.noAvailModalContent}>
        <Grid
          container
          className={`${classes.modalHeader} ${classes.contentRow}`}
        >
          <Typography variant="h6">No Availability</Typography>
        </Grid>
        <Grid container className={classes.contentRow}>
          <Typography variant="body1">
            For room {props.roomOfRoomsCounter} of {props.roomsOccupancy.length}{" "}
            of this booking, there are no more rooms available for{" "}
            {parseInt(props.availCheckParams.adults) +
              parseInt(
                props.availCheckParams.childrenAges.split(",")[0] === ""
                  ? 0
                  : props.availCheckParams.childrenAges.split(",").length
              )}{" "}
            guests on {props.availCheckParams.arrival} -{" "}
            {props.availCheckParams.departure}
          </Typography>
        </Grid>
        <Grid container className={classes.modalActions}>
          <Button variant="outlined" onClick={handleNewAvailCheckClick}>
            Start over
          </Button>
          &nbsp;
          <Button
            variant="contained"
            color="primary"
            onClick={handleContinueClick}
          >
            {hasMoreRoomsToChoose()
              ? "Continue to next room"
              : "Continue to checkout"}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  ) : null;
};

NoAvailModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  availCheckParams: PropTypes.shape({
    adults: PropTypes.string,
    childrenAges: PropTypes.string,
    arrival: PropTypes.string,
    departure: PropTypes.string,
  }).isRequired,
  roomsOccupancy: PropTypes.arrayOf(PropTypes.object),
  roomOfRoomsCounter: PropTypes.number,
  onIncrementRoomOfRooms: PropTypes.func,
};

export default NoAvailModal;
