import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dayHeader: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    "&::before": {
      content: "''",
      flex: "1",
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
    "&::after": {
      content: "''",
      flex: "1",
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
    "&:not(:empty)::before": {
      marginRight: ".5em",
    },
    "&:not(:empty)::after": {
      marginLeft: ".5em",
    },
  },
  chip: {
    color: theme.palette.text.primary,
    borderColor: theme.palette.grey[100],
  },
}));

const DayHeader = ({ title }) => {
  const classes = useStyles();

  return (
    <div className={classes.dayHeader}>
      <Chip className={classes.chip} label={title} variant="outlined" />
    </div>
  );
};

DayHeader.propTypes = {
  title: PropTypes.string,
};

export default DayHeader;
