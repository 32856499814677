import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Modal, Paper, Typography, Box, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: "2rem",
    borderRadius: "5px",
    width: "90%",
    maxWidth: "700px",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      width: "50%",
    },
  },
}));

const MolliesModal = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Modal open={props.open} className={classes.modal}>
        <Paper className={classes.paper}>
          {props.loading ? (
            props.loadingContent
          ) : (
            <>
              <Box mb={3}>
                <Typography variant="h6" color="textPrimary" align="center">
                  {props.title}
                </Typography>
              </Box>

              {props.content ? (
                <Box mb={6}>
                  <Typography variant="body1" color="textPrimary">
                    {props.content}
                  </Typography>
                </Box>
              ) : null}

              <Grid spacing={2} container justify="flex-end">
                {props.buttons.map((button, i) => (
                  <Grid item key={i}>
                    {button}
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </Paper>
      </Modal>
    </div>
  );
};

MolliesModal.propTypes = {
  loading: PropTypes.bool,
  loadingContent: PropTypes.node,
  open: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.object),
};

export default MolliesModal;
