import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

import { selectAfternoonTeaSelections } from "selectors/booking";
import { formatCurrency } from "utils/helpers";
import { AFTERNOON_TEA_CODE, STEPS } from "utils/constants";
import { formatDateMedium } from "utils/timekeep";

const useStyles = makeStyles((theme) => ({
  calendarIcon: {
    marginRight: "4px",
    fontSize: "16px",
    color: theme.palette.text.secondary,
  },
  timeIcon: {
    marginLeft: "8px",
    marginRight: "4px",
    fontSize: "12px",
    color: theme.palette.text.secondary,
  },
  accordionSummary: {
    padding: "0px",
  },
  accordionDetails: {
    display: "block",
    padding: "0px",
  },
  serviceName: {
    marginLeft: theme.spacing(1),
  },
  serviceListItem: {
    marginBottom: theme.spacing(1),
  },
}));

const Services = (props) => {
  const classes = useStyles();
  const afternoonTeaSelections = useSelector(selectAfternoonTeaSelections);

  const getServiceName = (service) => {
    /***
     * Special case for afternoon tea
     * AJD: TODO - Reconsider how we do this
     ***/
    if (service.code === AFTERNOON_TEA_CODE) {
      const selectedDate =
        afternoonTeaSelections[
          Object.keys(afternoonTeaSelections)[0] // Will only be one reservation for now
        ]?.date;
      const selectedTime =
        afternoonTeaSelections[
          Object.keys(afternoonTeaSelections)[0] // Will only be one reservation for now
        ]?.time;

      if (selectedDate && selectedTime) {
        return (
          <Box mt={1}>
            {service.name}
            <br />
            <Box>
              <CalendarTodayIcon className={classes.calendarIcon} />
              <Typography variant="caption" color="textSecondary">
                {formatDateMedium(selectedDate)}
              </Typography>
              <AccessTimeIcon className={classes.timeIcon} />
              <Typography variant="caption" color="textSecondary">
                {selectedTime}
              </Typography>
            </Box>
          </Box>
        );
      }
    }

    return service.name;
  };

  return (
    <Box>
      <Divider />
      <Accordion elevation={0} defaultExpanded={true}>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="body2" color="textPrimary">
            {STEPS.SELECT_ADDONS.STEPPER_TITLE}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {props.services.length > 0 ? (
            props.services.map((service, serviceIdx) => (
              <Grid
                container
                justify="space-between"
                wrap="nowrap"
                alignItems="center"
                key={`service-${props.reservationIdx}-${serviceIdx}`}
                className={classes.serviceListItem}
              >
                <Grid container wrap="nowrap" alignItems="center">
                  {!props.preventServiceRemoval &&
                    props.quantityDropdownForService(
                      service,
                      props.reservationIdx
                    )}

                  <Typography
                    className={classes.serviceName}
                    variant="caption"
                    color="textSecondary"
                  >
                    {getServiceName(service.service)}
                  </Typography>
                </Grid>
                <Typography variant="caption" color="textSecondary">
                  {formatCurrency(
                    service.totalAmount.currency,
                    service.totalAmount.grossAmount
                  )}
                </Typography>
              </Grid>
            ))
          ) : (
            <Typography variant="caption" color="textSecondary">
              {" "}
              No enhancements selected{" "}
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

Services.propTypes = {
  services: PropTypes.array,
  reservationIdx: PropTypes.number.isRequired,
  quantityDropdownForService: PropTypes.func.isRequired,
  preventServiceRemoval: PropTypes.bool,
};

export default Services;
