import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch, connect } from "react-redux";
import classNames from "classnames";
import moment from "moment";
import validator from "validator";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  TextField,
  Paper,
  Typography,
  Checkbox,
  Link,
  Box,
  FormControlLabel,
  useTheme,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  InputAdornment,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

import Heading from "components/Heading";
import Basket from "components/Basket";
import RoomUpgrade from "components/RoomUpgrade";
import Navbar from "components/Navbar";
import PaymentWidget from "components/PaymentWidget";
import LoadingDialog from "components/LoadingDialog";
import TerminalPaymentWidget from "components/TerminalPaymentWidget";
import TerminalPaymentInProgressDialog from "components/TerminalPaymentInProgressDialog";
import StepBreadcrumb from "components/StepBreadcrumb";
import PhoneInput from "components/PhoneInput";
import "./Checkout.css";

import { useSearchParams } from "hooks";
import { setPaymentError } from "features/payment/paymentSlice";
import {
  setFirebaseToken,
  signInUser,
  signOutUser,
} from "features/user/userSlice";
import { bookingSuccess } from "features/booking/bookingSlice";
import { getSelectedProperty } from "selectors/selectedProperty";
import {
  selectDiningSelections,
  selectEntertainmentSelections,
  selectAfternoonTeaSelections,
} from "selectors/booking";
import api from "utils/api";
import {
  generateFirstName,
  generateLastName,
  isKioskFromSearchParams,
  serializeFirebaseUser,
} from "utils/helpers";
import generateBookingData from "utils/generateBookingData";
import {
  performEmailSignIn,
  performEmailSignUp,
} from "utils/firebase/auth/email";
import { calculateTotalToPay } from "utils/bookingTotals";
import { trackBeginCheckoutEvent, trackPurchaseEvent } from "utils/analytics";
import { STEPS } from "utils/constants";
import { defaultTimezone } from "utils/timekeep";
import { PLAN_STAY, SUMMARY } from "config/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  mainContent: {
    flexDirection: "column",
    margin: "0 auto 1rem auto",
    width: theme.contentSize.mobilePageWidth,
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      width: theme.contentSize.pageWidth,
      maxWidth: theme.contentSize.maxPageWidth,
    },
  },
  splitContent: {
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      flexDirection: "row",
    },
  },
  basket: {
    marginLeft: theme.spacing(8),
    width: "280px",
    flexShrink: 0,
    flexDirection: "column",
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      display: "none",
    },
  },
  justifyCenter: {
    justifyContent: "center",
  },
  checkoutDetailsCard: {
    width: "100%",
    marginBottom: "20px",
  },
  paymentWidget: {
    paddingLeft: "0px",
    marginLeft: "0px",
  },
  mobileStep: {
    paddingRight: "0px",
    marginRight: "0px",
  },
  authContainer: {
    border: theme.palette.divider,
    borderRadius: "5px",
    background: "#f5f5f5",
  },
  termsBox: {
    margin: "0px",
    padding: "10px 0px",
    width: "100%",
  },
  validationFailed: {
    backgroundColor: theme.palette.error.lightBg,
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: "4px",
  },
  makeBookingContainer: {
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.primary.border}`,
    borderRadius: "16px",
  },
  makeBookingButton: {
    width: "100%",
  },
  stepper: {
    padding: "0px",
  },
  hiddenIcon: {
    display: "none",
  },
}));

// AJD: TODO - Refactor into a 'BookingStep' component, which contains all the common elements
const Checkout = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const desktopUpScreen = useMediaQuery(theme.breakpoints.up("desktop"));
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));
  const dispatch = useDispatch();
  const roomReservations = useSelector(
    (state) => state.booking.roomReservations
  );
  const parkingReservations = useSelector(
    (state) => state.booking.parkingReservations
  );
  const propertyId = useSelector(
    (state) => state.booking.commonSearchParams.propertyId
  );
  const selectedProperty = useSelector(getSelectedProperty);
  const propertyTimezone = selectedProperty?.timezone || defaultTimezone;
  const promoCode = useSelector(
    (state) => state.booking.commonSearchParams.promoCode
  );
  const bookingConfirmationId = useSelector(
    (state) => state.booking.bookingConfirmationId
  );
  const diningSelections = useSelector(selectDiningSelections);
  const entertainmentSelections = useSelector(selectEntertainmentSelections);
  const afternoonTeaSelections = useSelector(selectAfternoonTeaSelections);
  const authUser = useSelector((state) => state.user.authUser);
  const profile = useSelector((state) => state.user.profile);

  const searchParams = useSearchParams();
  const isKiosk = isKioskFromSearchParams(searchParams);

  const password = "";
  const [guestDetails, setGuestDetails] = useState({
    firstName: "",
    middleInitial: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    comments: "",
  });
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [firstNameInputError, setFirstNameInputError] = useState(false);
  const [lastNameInputError, setLastNameInputError] = useState(false);
  const [emailInputError, setEmailInputError] = useState(false);
  const [phoneInputError, setPhoneInputError] = useState(false);
  const [termsValidationError, setTermsValidationError] = useState(false);

  const [bookingInProgress, setBookingInProgress] = useState(false);

  // Stores the booking id, payment timeout and payment data needed to check status
  const [terminalPaymentData, setTerminalPaymentData] = useState(null);

  const validateCheckoutForm = () => {
    if (!validateInputNotBlank()) {
      props.onNotifOpen("Please fill all guest details", {
        variant: "error",
      });

      return false;
    } else if (!validateTermsAccepted()) {
      return false;
    }

    return true;
  };

  const validateInputNotBlank = () => {
    setFirstNameInputError(guestDetails.firstName === "");
    setLastNameInputError(guestDetails.lastName === "");
    setEmailInputError(guestDetails.email === "");
    setPhoneInputError(!validator.isMobilePhone(guestDetails.phoneNumber));
    return (
      guestDetails.firstName &&
      guestDetails.lastName &&
      guestDetails.email &&
      validator.isMobilePhone(guestDetails.phoneNumber)
    );
  };

  const validateTermsAccepted = () => {
    if (!termsAccepted) {
      props.onNotifOpen("Please accept terms and conditions to continue", {
        variant: "error",
      });

      setTermsValidationError(true);
    }
    return termsAccepted;
  };

  const handleAuthUserChange = (userData) => {
    dispatch(signInUser(serializeFirebaseUser(userData)));
  };

  const handleFirstNameChange = (event) => {
    const { value } = event.currentTarget;
    setGuestDetails((prevState) => ({
      ...prevState,
      firstName: value,
    }));
  };

  const handleLastNameChange = (event) => {
    const { value } = event.currentTarget;
    setGuestDetails((prevState) => ({
      ...prevState,
      lastName: value,
    }));
  };

  const handlePhoneNumberChange = (value) => {
    setGuestDetails((prevState) => ({
      ...prevState,
      phoneNumber: value,
    }));
  };
  const handleEmailChange = (e) => {
    const { value } = e.currentTarget;
    setGuestDetails((prevState) => ({
      ...prevState,
      email: value,
    }));
  };
  const handleCommentsChange = (e) => {
    const { value } = e.currentTarget;
    setGuestDetails((prevState) => ({
      ...prevState,
      comments: value,
    }));
  };

  const onBookButtonClicked = async () => {
    if (!validateCheckoutForm()) {
      console.log("Checkout form validation failed");
      return;
    }

    await handleCheckout(null);
  };

  const triggerTerminalPayment = async () => {
    if (!validateCheckoutForm()) {
      console.log("Checkout form validation failed");
      return;
    }

    // Show the booking on progress dialog
    setBookingInProgress(true);

    // We first need to make the booking, and then trigger a terminal payment
    let bookingId = null;
    try {
      bookingId = await makeBooking(null);

      console.log(`Booking created - ${bookingId}`);

      // Now trigger a payment via the terminal
      const payByTerminalResponse = await api.payByTerminal(
        bookingId,
        searchParams["paymentTerminalId"]
      );

      // Save the trigger payment response - the terminal dialog will poll status
      setTerminalPaymentData({
        propertyId: propertyId,
        bookingId: bookingId,
        paymentTimeout: moment().add(60, "seconds"),
        paymentId: payByTerminalResponse.data.payment_id,
        folioId: payByTerminalResponse.data.folio_id,
      });
    } catch (error) {
      console.log(`Error making booking: ${error}`);

      await handleTerminalPaymentFailed(
        propertyId,
        bookingId,
        `Your booking could not be completed. You will not be charged.`
      );
    }
  };

  const handleTerminalPaymentFailed = async (
    propertyId,
    bookingId,
    errorMessage
  ) => {
    console.log(`Cancelling booking with id: ${bookingId}`);

    const paymentId = terminalPaymentData.paymentId;
    const folioId = terminalPaymentData.folioId;

    setTerminalPaymentData(null);

    // Cancel any payments made
    if (paymentId && folioId) {
      await api.cancelTerminalPayment(paymentId, folioId).catch((e) => {
        // Catch any errors & continue with canceling booking
        console.log(`Failed to cancel payment: ${e}`);
      });
    }

    // Cancel the booking
    if (bookingId) {
      await api.cancelBooking(propertyId, bookingId);
      setBookingInProgress(false);
    }

    if (errorMessage) {
      props.onNotifOpen(errorMessage, { variant: "error" });
    }
  };

  const makeBooking = async (paymentResponse) => {
    // Combine all reservations for booking
    const bookingReservations = [...roomReservations, ...parkingReservations];
    const data = generateBookingData({
      bookingReservations,
      guestDetails,
      paymentResponse,
      giftCard: props.giftCard,
      propertyId,
      propertyTimezone,
      diningSelections,
      entertainmentSelections,
      afternoonTeaSelections,
    });

    const response = await api.makeBooking(data);

    trackPurchaseEvent(
      bookingReservations,
      promoCode,
      propertyId,
      response.data.id,
      paymentResponse
        ? paymentResponse.additionalData.paymentMethod
        : "giftcard"
    );

    if (password) {
      handleSignUp();
    }

    return response.data.id;
  };

  const handleCheckout = async (paymentResponse) => {
    // Show the booking on progress dialog
    setBookingInProgress(true);

    try {
      const bookingId = await makeBooking(paymentResponse);
      handleBookingSuccess(bookingId);
    } catch (error) {
      console.log(`Error making booking: ${error.response.data.messages}`);
      props.setPaymentError(
        `Your booking could not be completed. You will not be charged.`
      );

      setBookingInProgress(false);
    }
  };

  const handleBookingSuccess = async (bookingId) => {
    // on success:
    // clear booked reservations State,
    // and redirect to confirmation page
    dispatch(bookingSuccess(bookingId));
    props.history.push(SUMMARY);
  };

  const handleReservationRemoved = () => {
    props.onNotifOpen("Reservation successfully removed", {
      variant: "success",
    });
  };

  const signIntoFirebase = async () => {
    try {
      const { firebaseToken, authUser } = await performEmailSignIn(
        guestDetails.email,
        password
      );

      handleAuthUserChange(authUser);

      return firebaseToken;
    } catch (e) {
      console.error(e);
      props.onNotifOpen(e.message, { variant: "error" });
    }
  };

  const fetchProfile = (firebaseToken) => {
    api
      .getProfile(firebaseToken)
      .then((res) => {
        props.onProfileChange(res.data);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleSignIn = async () => {
    const firebaseToken = await signIntoFirebase();

    if (firebaseToken) {
      dispatch(setFirebaseToken(firebaseToken));
      fetchProfile(firebaseToken);
    }
  };

  const handleSignUp = async () => {
    try {
      await performEmailSignUp(
        guestDetails.email,
        password,
        guestDetails.firstName,
        guestDetails.lastName
      );

      handleSignIn();
    } catch (e) {
      console.error(e);

      if (e.code === "auth/email-already-in-use") {
        props.onNotifOpen(
          "Your email address is already associated to an existing profile. Please sign in to manage your reservation.",
          { variant: "warning" }
        );
      }
    }
  };

  const getFirstName = () => {
    // Attempts to use profile's first name. Falls back to
    // auth first name if profile unavailable (e.g unverified & not loaded)
    if (profile && profile.firstName) {
      return profile.firstName;
    } else if (authUser) {
      return generateFirstName(authUser.displayName);
    } else {
      return "";
    }
  };

  const getLastName = () => {
    // Attempts to use profile's first name. Falls back to
    // auth first name if profile unavailable (e.g unverified & not loaded)
    if (profile && profile.lastName) {
      return profile.lastName;
    } else if (authUser) {
      return generateLastName(authUser.displayName);
    } else {
      return "";
    }
  };

  useEffect(() => {
    // Redirect to home if user has no reservations left in the cart,
    // Skip if user is in middle of booking where the cart is emptied
    if (roomReservations.length === 0 && bookingConfirmationId == null) {
      props.onNotifOpen("You dont have any rooms in your basket.", {
        variant: "error",
      });
      props.history.push(PLAN_STAY);
    } else if (bookingConfirmationId == null) {
      const bookingReservations = [...roomReservations, ...parkingReservations];

      trackBeginCheckoutEvent(bookingReservations, promoCode);
    }
  }, [
    props.onNotifOpen,
    roomReservations,
    parkingReservations,
    bookingConfirmationId,
  ]);

  useEffect(() => {
    if (!authUser) return;

    setGuestDetails((prevState) => ({
      ...prevState,
      firstName: getFirstName(),
      lastName: getLastName(),
      email: authUser ? authUser.email : "",
      phoneNumber: profile ? profile.phone : "",
    }));
  }, [authUser, profile]);

  const toggleTermsAcceptance = (event) => {
    setTermsAccepted(event.target.checked);
    setTermsValidationError(false);
  };

  const handleSignOut = () => {
    dispatch(signOutUser());
  };

  const checkoutSteps = () => {
    let steps = [
      {
        label: "Guest Details",
        content: (
          <Box my={3}>
            {authUser ? (
              <Box mb={2}>
                <Grid container alignItems="baseline">
                  <Typography variant="body2" color="textPrimary">
                    Booking for some else?{" "}
                  </Typography>
                  <Button onClick={handleSignOut}>Log out</Button>
                </Grid>
              </Box>
            ) : null}
            <Box mb={2}>
              <Typography variant="caption" color="textPrimary">
                * Required fields
              </Typography>
            </Box>
            <Grid container justify="space-between">
              <Grid item xs={desktopUpScreen ? 6 : 12}>
                <Box mb={2} pr={1}>
                  <TextField
                    fullWidth
                    name="given-name"
                    autoComplete="given-name"
                    label="First name"
                    type="text"
                    required
                    variant="outlined"
                    disabled={!!(authUser && guestDetails.firstName)}
                    value={guestDetails.firstName}
                    onChange={handleFirstNameChange}
                    InputProps={
                      !firstNameInputError &&
                      guestDetails.firstName && {
                        endAdornment: (
                          <InputAdornment>
                            <CheckIcon color="success" />
                          </InputAdornment>
                        ),
                      }
                    }
                    error={firstNameInputError}
                    helperText={
                      firstNameInputError ? "This field is required." : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={desktopUpScreen ? 6 : 12}>
                <Box mb={2} pl={1}>
                  <TextField
                    fullWidth
                    name="family-name"
                    autoComplete="family-name"
                    label="Last name"
                    type="text"
                    required
                    variant="outlined"
                    disabled={authUser && guestDetails.lastName}
                    value={guestDetails.lastName}
                    onChange={handleLastNameChange}
                    InputProps={
                      !lastNameInputError &&
                      guestDetails.lastName && {
                        endAdornment: (
                          <InputAdornment>
                            <CheckIcon color="success" />
                          </InputAdornment>
                        ),
                      }
                    }
                    error={lastNameInputError}
                    helperText={
                      lastNameInputError ? "This field is required." : ""
                    }
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={desktopUpScreen ? 6 : 12}>
              <Box mb={2} pr={1}>
                <TextField
                  fullWidth
                  name="email"
                  autoComplete="email"
                  label="Email"
                  type="text"
                  required
                  variant="outlined"
                  disabled={authUser}
                  value={guestDetails.email}
                  InputProps={
                    !emailInputError &&
                    guestDetails.email && {
                      endAdornment: (
                        <InputAdornment>
                          <CheckIcon color="success" />
                        </InputAdornment>
                      ),
                    }
                  }
                  onChange={handleEmailChange}
                  error={emailInputError}
                  helperText={emailInputError ? "This field is required." : ""}
                />
              </Box>
            </Grid>
            <Grid item xs={desktopUpScreen ? 6 : 12}>
              <Box mb={2} px={1}>
                <PhoneInput
                  defaultCountry="GB"
                  placeholder="e.g +44 7911 123456"
                  countryOptionsOrder={["GB", "|"]}
                  value={guestDetails.phoneNumber}
                  onChange={handlePhoneNumberChange}
                  error={phoneInputError}
                  errorHelperText="Please enter a valid phone number."
                />
              </Box>
            </Grid>
            <Box mb={3} mt={3}>
              <Typography variant="subtitle1" color="textPrimary">
                Special Requests
              </Typography>
              <Typography variant="caption">
                E.g. Near to a fire exit, ground floor room, etc
              </Typography>
            </Box>
            <Box mb={3}>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                rows={2}
                value={guestDetails.comments}
                onChange={handleCommentsChange}
              />
            </Box>
          </Box>
        ),
      },
      {
        label: "Payment",
        content: (
          <Box my={3}>
            <Box my={3}>
              <Typography variant="subtitle1" color="textPrimary">
                Terms and Conditions
              </Typography>
              <FormControlLabel
                className={classNames(
                  classes.termsBox,
                  termsValidationError ? classes.validationFailed : null
                )}
                control={
                  <Checkbox
                    checked={termsAccepted}
                    onChange={toggleTermsAcceptance}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body2" color="textPrimary">
                    I have read and confirmed the&#8287;
                    <Link href="https://www.warnerleisurehotels.co.uk/company/terms-and-conditions">
                      Terms and Conditions.
                    </Link>
                  </Typography>
                }
              />
            </Box>
            <Box my={3}>
              {props.totalToPay > 0 ? (
                searchParams["paymentTerminalId"] ? (
                  // Payment terminal has been passed through so replace the
                  // payment widget with the terminal payment flow
                  <TerminalPaymentWidget
                    onBookButtonClicked={triggerTerminalPayment}
                    currency={"GBP"}
                    amount={props.totalToPay}
                  />
                ) : (
                  <PaymentWidget
                    className={classes.justifyCenter}
                    makeBooking={handleCheckout}
                    setLoading={setBookingInProgress}
                    validateCheckoutForm={validateCheckoutForm}
                    onNotifOpen={props.onNotifOpen}
                  />
                )
              ) : (
                // Gift card value covered the total so we can make the booking directly
                <Grid className={classes.makeBookingContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onBookButtonClicked}
                    className={classes.makeBookingButton}
                  >
                    Make Booking
                  </Button>
                </Grid>
              )}
            </Box>
          </Box>
        ),
      },
    ];

    // Add basket step for mobile only
    if (!desktopUpScreen) {
      steps.unshift({
        label: "Booking Summary",
        content: (
          <Box my={3}>
            <Basket
              onNotifOpen={props.onNotifOpen}
              canRemoveReservations
              onReservationRemoved={handleReservationRemoved}
              showGiftCard={!!searchParams["paymentTerminalId"]}
            />
          </Box>
        ),
      });
    }

    return steps;
  };

  function getStepsLabels() {
    return checkoutSteps().map((step) => step.label);
  }

  function getStepContent(step) {
    return checkoutSteps().map((step) => step.content)[step];
  }

  const steps = getStepsLabels();

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    // Before advancing validate
    if (activeStep === 1) {
      // Guest Details step
      if (!validateInputNotBlank()) return false;
    } else if (activeStep === 2) {
      // Payment step
      if (!validateTermsAccepted()) return false;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const stepTo = (step) => {
    // Before advancing validate
    if (activeStep <= 0 && step > 0) {
      if (!validateInputNotBlank()) return false;
    } else if (activeStep <= 1 && step > 1) {
      if (!validateTermsAccepted()) return false;
    }

    setActiveStep(step);
  };

  return (
    <div>
      {!isKiosk && <Navbar withProfile onNotifOpen={props.onNotifOpen} />}
      <Grid container className={classes.mainContent}>
        <StepBreadcrumb currentStep={STEPS.CHECKOUT.INDEX} />
        <Heading titleText={STEPS.CHECKOUT.PAGE_TITLE} />
        <Grid container className={classes.splitContent}>
          <Paper elevation={0} className={classes.checkoutDetailsCard}>
            <Stepper
              connector={false}
              classes={classes.stepper}
              activeStep={activeStep}
              orientation="vertical"
            >
              {steps.map((label, index) => (
                <Step
                  key={label}
                  expanded={true}
                  active={activeStep === index || desktopUpScreen}
                >
                  <StepLabel
                    StepIconProps={{
                      classes: { root: classes.hiddenIcon },
                    }}
                    onClick={() => {
                      stepTo(index);
                    }}
                  >
                    <Typography variant="h6" color="primary">
                      {label}
                    </Typography>
                  </StepLabel>
                  <StepContent
                    hidden={activeStep !== index && !desktopUpScreen}
                    className={classNames(
                      tabletUpScreen ? null : classes.mobileStep,
                      activeStep === steps.length - 1 && !tabletUpScreen
                        ? classes.paymentWidget
                        : null
                    )}
                  >
                    {getStepContent(index)}
                    <Box hidden={desktopUpScreen}>
                      <Grid container justify="flex-end">
                        <Button
                          variant="contained"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                        <Box ml={1} hidden={activeStep === steps.length - 1}>
                          {" "}
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                          >
                            Next
                          </Button>
                        </Box>
                      </Grid>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Paper>
          <Grid container className={classes.basket}>
            <Basket
              onNotifOpen={props.onNotifOpen}
              canRemoveReservations
              onReservationRemoved={handleReservationRemoved}
              showGiftCard={!searchParams["paymentTerminalId"]}
            />
            <RoomUpgrade />
          </Grid>
        </Grid>
      </Grid>
      <LoadingDialog
        open={bookingInProgress}
        message={"Connecting to the booking system. Please wait..."}
      />
      <TerminalPaymentInProgressDialog
        paymentData={terminalPaymentData}
        onPaymentSuccess={handleBookingSuccess}
        onPaymentFailed={handleTerminalPaymentFailed}
      />
    </div>
  );
};

Checkout.propTypes = {
  onProfileChange: PropTypes.func,
  authUser: PropTypes.object,
  onAuthUserChange: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  onNotifOpen: PropTypes.func,
  // Mappped props
  setPaymentError: PropTypes.func,
  giftCard: PropTypes.object,
  totalToPay: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    giftCard: state.booking.giftCard,
    totalToPay: calculateTotalToPay(
      [...state.booking.roomReservations, ...state.booking.parkingReservations],
      state.booking.giftCard
    ),
  };
};

const mapDispatchToProps = { setPaymentError };

const ConnectedCheckout = connect(
  mapStateToProps,
  mapDispatchToProps
)(Checkout);
export default ConnectedCheckout;
