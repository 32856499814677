import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { formatCurrency } from "../../utils/helpers";

import placeholderImage from "images/placeholder-image.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    padding: theme.spacing(2),
    margin: "auto",
    borderRadius: "5px",
  },
  cardContent: {
    flexWrap: "nowrap",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("desktop")]: {
      flexDirection: "row",
    },
  },
  imgContainer: {
    height: "240px",
    position: "relative",
    overflow: "hidden",
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("desktop")]: {
      width: "85%",
    },
  },
  img: {
    maxWidth: "100%",
    maxHeight: "240px",
    height: "240px",
    width: "100%",
    objectFit: "cover",
    top: 0,
    bottom: 0,
  },
  titleRateRow: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px",
  },
  roomFeaturesList: {
    marginLeft: "10px",
    marginBottom: "10px",
  },
  roomFeaturesListItem: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  actionsRow: {
    marginBottom: "20px",
  },
  moreDetails: {
    padding: "4px 0px",
  },
  priceLabelContainer: {
    position: "absolute",
    backgroundColor: "#FFFFFF",
    top: 0,
    left: theme.spacing(1),
    padding: `0px ${theme.spacing(1)}px`,
  },
  priceLabel: {
    fontWeight: 700,
  },
  bookButton: {
    marginLeft: "10px",
    borderRadius: "5px",
  },
}));

const RoomCard = (props) => {
  const { availability, roomDetails, onReservationSelect, stayNights } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container className={classes.cardContent}>
        <Grid item className={classes.imgContainer}>
          <img
            className={classes.img}
            alt="room"
            src={roomDetails?.hero_image?.url || placeholderImage}
          />
          <div className={classes.priceLabelContainer}>
            <Typography variant="overline" className={classes.priceLabel}>
              {formatCurrency(
                availability.totalGrossAmount.currency,
                Math.round(availability.totalGrossAmount.amount / stayNights),
                false
              )}
            </Typography>
            <Typography variant="overline" color="textSecondary">
              {" "}
              /night
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column">
            <Grid item className={classes.titleRateRow}>
              <Typography variant="subtitle1">{roomDetails?.name}</Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {availability.ratePlan.description}
              </Typography>
            </Grid>
            <Grid item className={classes.roomFeaturesList}>
              {roomDetails.features?.map((feature, index) => {
                // Limit to the first six features
                if (index < 6) {
                  return (
                    <li key={index} className={classes.roomFeaturesListItem}>
                      {feature.title}
                    </li>
                  );
                }
              })}
            </Grid>
            <Grid item>
              <Button
                className={classes.bookButton}
                variant="contained"
                color="primary"
                onClick={(e) => onReservationSelect(availability, e)}
              >
                Book
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

RoomCard.propTypes = {
  availability: PropTypes.object,
  roomDetails: PropTypes.object,
  onReservationSelect: PropTypes.func,
  stayNights: PropTypes.number,
  selectable: PropTypes.bool,
};

export default RoomCard;
