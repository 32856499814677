import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import getOr from "lodash/fp/getOr";
import compose from "lodash/fp/compose";
import merge from "lodash/fp/merge";
import classNames from "classnames";
import queryString from "query-string";
import {
  Box,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import StepBreadcrumb from "components/StepBreadcrumb";
import RoomCard from "components/RoomCard";
import Heading from "components/Heading";
import NoAvailModal from "components/NoAvailModal";
import EditAvailCheckModal from "components/EditAvailCheckModal";
import Navbar from "components/Navbar";
import LoadingSpinner from "components/LoadingSpinner";
import Basket from "components/Basket";
import {
  addRoomReservationToBooking,
  removeRoomReservationFromBooking,
  setAvailCheckParams,
  setCommonSearchParams,
} from "features/booking/bookingSlice";
import { setRoomDetails } from "features/room/roomSlice";
import { setSelectedPropertyId } from "features/property/propertySlice";
import {
  setPrefetchAvailabilityLoading,
  setPrefetchAvailabilityData,
} from "features/dining/diningSlice";
import { removeSelectedAfternoonTea } from "features/afternoonTea/afternoonTeaSlice";
import {
  selectAvailCheckParams,
  selectPrefetchAvailability,
} from "selectors/booking";
import { useSearchParams } from "hooks";
import { getCommonSearchParams, isKioskFromSearchParams } from "utils/helpers";
import { STEPS, DINING_START_TIME, DINING_END_TIME } from "utils/constants";
import { trackAddReservationToCartEvent } from "utils/analytics";
import api from "utils/api";
import { CHOOSE_ROOM_NUMBER, PLAN_STAY } from "config/routes";

const useStyles = makeStyles((theme) => ({
  root: {},
  mainContent: {
    flexDirection: "column",
    margin: "0 auto 1rem auto",
    width: theme.contentSize.mobilePageWidth,
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      width: theme.contentSize.pageWidth,
      maxWidth: theme.contentSize.maxPageWidth,
    },
  },
  roomCardContainer: {
    marginBottom: "0px",
  },
  splitContent: {
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      flexDirection: "row",
    },
  },
  detailsContainer: {
    flexGrow: 1,
  },
  basket: {
    width: "100%",
    marginBottom: "20px",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      marginLeft: theme.spacing(8),
      width: "280px",
      flexShrink: 0,
    },
  },
  hidden: {
    display: "none",
  },
  roomSort: {
    width: "auto",
    paddingBottom: theme.spacing(2),
  },
  roomSortDropdown: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));

// AJD: TODO - Refactor into a 'BookingStep' component, which contains all the common elements
const ChooseRoom = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedPropertyId = useSelector(
    (state) => state.property.selectedPropertyId
  );
  const roomReservations = useSelector(
    (state) => state.booking.roomReservations
  );
  const roomsOccupancy = useSelector((state) => state.booking.roomsOccupancy);
  const roomOfRooms = getOr(1, ["state", "roomOfRooms"], location);
  const roomDetails = useSelector((state) => state.room.roomDetails);
  const availCheckParams = useSelector(selectAvailCheckParams(roomOfRooms));
  const searchParams = useSearchParams();
  const diningPrefetchAvailability = useSelector(selectPrefetchAvailability);

  const [availabilities, setAvailabilities] = useState([]);
  const [nights, setNights] = useState(null);
  const [noAvailModalOpen, setNoAvailModalOpen] = useState(false);
  const [editAvailCheckModalOpen, setEditAvailCheckModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [roomSort, setRoomSort] = useState("price-high-low");

  const handleEditAvailCheckModalClose = () => {
    setEditAvailCheckModalOpen(false);
  };

  const handleNoAvailModalOpen = () => {
    setNoAvailModalOpen(true);
  };

  const handleSelectReservation = (availability) => {
    // Add the room reservation
    dispatch(
      addRoomReservationToBooking({ reservation: availability, roomOfRooms })
    );
    trackAddReservationToCartEvent(availability);

    // Pass the room id to the addons page for selecting services
    let addOnsParams = { ...availCheckParams };
    addOnsParams["ratePlanId"] = availability.ratePlan.id;

    dispatch(setAvailCheckParams({ roomIndex: roomOfRooms, ...addOnsParams }));

    props.history.push({
      pathname: CHOOSE_ROOM_NUMBER,
      data: {},
      state: {},
    });
  };

  const findRoomDetailsFromUnitGroupCode = (availabilityUnitGroupCode) => {
    return (
      roomDetails.find((room) => {
        return room.pms_id === availabilityUnitGroupCode;
      }) || {}
    );
  };

  const adjustAvailabilitiesWithReservationsInCart = (availabilities) => {
    // Subtract units found in cart from availableUnits
    roomReservations.forEach((reservationInCart) => {
      availabilities.forEach((availability) => {
        // Decrease the number of available units for each of the rates for this unit group
        if (availability.unitGroup.id === reservationInCart.unitGroup.id) {
          availability.availableUnits -= 1;
        }
      });
    });

    // Remove all availabilities that have no available units after
    // subtracting items in cart
    return availabilities.filter((availability) => {
      return availability.availableUnits > 0;
    });
  };

  const applyRoomSort = (availabilities) => {
    return availabilities.sort((a, b) => {
      switch (roomSort) {
        case "price-low-high":
          return a.totalGrossAmount.amount - b.totalGrossAmount.amount;
        case "price-high-low":
          return b.totalGrossAmount.amount - a.totalGrossAmount.amount;
        default:
          return 0;
      }
    });
  };

  useEffect(() => {
    if (roomOfRooms <= roomReservations.length) {
      dispatch(removeRoomReservationFromBooking(roomOfRooms - 1));

      /***
       * Special case, to allow removal of reservations for afternoon tea
       * AJD: TODO - Reconsider how we do this
       ***/
      dispatch(removeSelectedAfternoonTea());
    }
  }, [roomOfRooms, roomReservations]);

  useEffect(() => {
    const queryParams = queryString.parse(location.search);

    // Check we have the data we need for this step
    if (!Object.keys(queryParams)?.length) {
      props.history.push(PLAN_STAY);
      return;
    }

    dispatch(
      setAvailCheckParams({
        roomIndex: roomOfRooms,
        ...queryParams,
      })
    );

    // Also merge to common params here in case user came straight to step 2
    dispatch(
      compose(
        setCommonSearchParams,
        getCommonSearchParams,
        merge(searchParams)
      )(queryParams)
    );

    // Save the property code if we dont have one (eg arrived direct)
    if (!selectedPropertyId) {
      dispatch(setSelectedPropertyId(queryParams.propertyId));
    }

    let cmsRoomDetailsRequest = api.getRoomDetails();
    let pmsAvailRequest = api.getAvailabilities({
      timeSliceTemplate: "OverNight", // Hardcoded
      unitGroupTypes: "BedRoom", // Hardcoded
      propertyId: queryParams.propertyId,
      arrival: queryParams.arrival,
      departure: queryParams.departure,
      adults: queryParams.adults,
      childrenAges: queryParams.childrenAges,
      promoCode: queryParams.promoCode,
      corporateCode: queryParams.corporateCode,
    });

    Promise.all([pmsAvailRequest, cmsRoomDetailsRequest])
      .then((responses) => {
        const pmsAvailsRes = responses[0];
        const cmsRoomsRes = responses[1];

        if (pmsAvailsRes.status === 204) {
          if (roomOfRooms === 1) {
            // handle no avail first room
            props.onNotifOpen("No availability found, please try again", {
              variant: "error",
            });
            props.history.push(PLAN_STAY);
          } else {
            // subsequent rooms gives the option to checkout or start over
            handleNoAvailModalOpen();
          }
        } else {
          // Set Room Details from CMS
          dispatch(setRoomDetails(cmsRoomsRes.data));

          // Set Availabilities from PMS
          let availabilities = pmsAvailsRes.data.offers;
          availabilities = adjustAvailabilitiesWithReservationsInCart(
            availabilities
          );
          if (Object.keys(availabilities).length) {
            setAvailabilities(availabilities);
            setNights(availabilities[0].timeSlices.length);
          } else {
            // handles cart items fills up available rooms from pms
            handleNoAvailModalOpen();
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        props.onNotifOpen(error.message, { variant: "error" });
        props.history.push(PLAN_STAY);
      });

    /***
     * Given that we have the dates of the stay at this point, we can pre-fetch the dining availability, as this is a slow step
     * This solution isn't designed to be permanent, but should speed things up for now
     * AJD: TODO - Refactor the fetching logic so it can live outside the component
     ***/
    // First check a fetch isn't already in progress, and that the user hasn't already viewed the availability
    if (
      !diningPrefetchAvailability.loading &&
      !diningPrefetchAvailability.viewed
    ) {
      // Update to a loading state
      dispatch(setPrefetchAvailabilityLoading(true));

      // Fetch the list of venues
      api
        .getVenuesImages()
        .then((response) => {
          const venues = response?.data || [];

          // As long as we have some venues, extract the venue IDs
          if (venues.length) {
            const venueIds = venues.map((venue) => venue.external_id);

            // Now fetch the availability
            api
              .getVenuesAvailabilities({
                party_size: queryParams.adults,
                start_date: queryParams.arrival,
                end_date: queryParams.departure,
                start_time: DINING_START_TIME,
                end_time: DINING_END_TIME,
                venues: venueIds,
              })
              .then((response) => {
                if (response.data) {
                  // Store the data for the SelectDiningTimes component to use
                  dispatch(setPrefetchAvailabilityData(response.data));
                  // Update to a non-loading state
                  dispatch(setPrefetchAvailabilityLoading(false));
                } else {
                  throw new Error("No availability data");
                }
              })
              .catch(() => {
                // Update to a non-loading state
                dispatch(setPrefetchAvailabilityLoading(false));
              });
          } else {
            throw new Error("No venue data");
          }
        })
        .catch(() => {
          // Update to a non-loading state
          dispatch(setPrefetchAvailabilityLoading(false));
        });
    }
  }, []);

  return (
    <div className={classes.root}>
      {!isKioskFromSearchParams(searchParams) && (
        <Navbar withProfile onNotifOpen={props.onNotifOpen} />
      )}
      <Grid container className={classes.mainContent}>
        <StepBreadcrumb currentStep={STEPS.CHOOSE_ROOM.INDEX} />
        <Heading titleText={STEPS.CHOOSE_ROOM.PAGE_TITLE} />
        <LoadingSpinner loading={loading} />
        <Grid
          container
          className={classNames(
            classes.splitContent,
            loading ? classes.hidden : null
          )}
        >
          <Grid item className={classes.detailsContainer}>
            <Grid container direction="row" justify="space-between">
              <Typography variant="body1" color="textSecondary">
                Results ({availabilities.length})
              </Typography>
              <Grid
                container
                className={classes.roomSort}
                direction="row"
                alignItems="baseline"
                justify="flex-end"
              >
                <Typography variant="body1">Sort by:</Typography>
                <TextField
                  select
                  value={roomSort}
                  onChange={(event) => {
                    setRoomSort(event.target.value);
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.roomSortDropdown,
                  }}
                >
                  <MenuItem value={"price-low-high"}>Price: Low-High</MenuItem>
                  <MenuItem value={"price-high-low"}>Price: High-Low</MenuItem>
                </TextField>
              </Grid>
            </Grid>
            {applyRoomSort(availabilities).map((offer, i) => (
              <Grid item className={classes.roomCardContainer} key={i}>
                <RoomCard
                  availability={offer}
                  roomDetails={findRoomDetailsFromUnitGroupCode(
                    offer.unitGroup.code
                  )}
                  onReservationSelect={handleSelectReservation}
                  stayNights={nights}
                  selectable
                />
                <Box my={2}>
                  <Divider />
                </Box>
              </Grid>
            ))}
          </Grid>
          <Basket className={classes.basket} onNotifOpen={props.onNotifOpen} />
        </Grid>
      </Grid>
      <NoAvailModal
        open={noAvailModalOpen}
        availCheckParams={availCheckParams}
        roomsOccupancy={roomsOccupancy}
        roomOfRoomsCounter={roomOfRooms}
      />
      <EditAvailCheckModal
        open={editAvailCheckModalOpen}
        onClose={handleEditAvailCheckModalClose}
      />
    </div>
  );
};

ChooseRoom.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  onNotifOpen: PropTypes.func,
};

export default ChooseRoom;
