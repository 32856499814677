// Sums the total of all room bookings in a reservation array
export const calculateRoomsTotal = (bookingReservations) => {
  // Rooms total
  return bookingReservations.reduce(
    (accumulator, reservation) =>
      // Use timeslices rather than totalGrossAmount, as totalGrossAmount on
      // booked reservations includes service amounts
      accumulator +
      reservation.timeSlices.reduce(
        (acc, timeSlice) => acc + timeSlice.totalGrossAmount.amount,
        0
      ),
    0.0
  );
};

// Sums the total of all services in a booking
export const calculateServicesTotal = (bookingReservations) => {
  var servicesTotalGrossAmount = 0.0;
  bookingReservations.forEach((reservation) => {
    if (reservation.services) {
      reservation.services.forEach((service) => {
        servicesTotalGrossAmount += service.totalAmount.grossAmount;
      });
    }
  });

  return servicesTotalGrossAmount;
};

// The booking total, including room prices, service prices (and VAT)
export const calculateBookingTotal = (bookingReservations) => {
  return (
    calculateRoomsTotal(bookingReservations) +
    calculateServicesTotal(bookingReservations)
  );
};

// The VAT portion of a single reservation
/**
 * AJD: TODO - This function could do with revisiting
 * I initially updated this to use the 'taxDetail' property - however, whilst this is available from the availability check, it isn't returned in the booking confirmation
 * We therefore have problems once we get to the booking confirmation, as it uses the same function
 * The current solution doesn't feel ideal, as it does a lot of calculations on the front-end, and is subject to how Apaleo is configured
 * It would be better if we could retrieve this data directly from Apaleo instead
 **/
export const calculateReservationTotalVat = (bookingReservation) => {
  let vatTotal = bookingReservation.timeSlices.reduce((acc, timeSlice) => {
    let runningTotal = acc;
    runningTotal +=
      timeSlice.baseAmount.grossAmount - timeSlice.baseAmount.netAmount;

    // Check for included services
    if (timeSlice.includedServices) {
      runningTotal += timeSlice.includedServices.reduce(
        (acc, includedService) =>
          acc +
          (includedService.amount.grossAmount -
            includedService.amount.netAmount),
        0
      );
    }

    return runningTotal;
  }, 0);

  // Add services
  if (bookingReservation.services) {
    vatTotal += bookingReservation.services.reduce(
      (acc, service) =>
        acc + (service.totalAmount.grossAmount - service.totalAmount.netAmount),
      0
    );
  }

  return vatTotal;
};

// The VAT portion of an array of reservations
export const calculateBookingTotalVat = (bookingReservations) => {
  return bookingReservations.reduce(
    (acc, reservation) => acc + calculateReservationTotalVat(reservation),
    0
  );
};

// Calculates the total for a service, based on type and number of nights
export const calculateServicePrice = (service, count) => {
  const basePrice = service.service.defaultGrossPrice.amount;
  const numberOfNights = service.dates.length;

  return basePrice * numberOfNights * count;
};

// Gift cards

// Returns the value of gift card that can be redeemed
// ie the total gift card balance, or booking total (the smallest number)
export const calculateGiftCardRedemption = (bookingReservations, giftCard) => {
  const bookingTotal = calculateBookingTotal(bookingReservations);
  return giftCard ? Math.min(bookingTotal, giftCard.balance / 100.0) : 0;
};

// Returns the remaining balance to pay, minus any gift card value
export const calculateTotalToPay = (bookingReservations, giftCard) => {
  // Need to round this to 2 decimals - if we do 75 - 59.99, chrome gives 15.0099
  // Convert to pennies to avoid floating point rounding errors
  const bookingTotal_100 = calculateBookingTotal(bookingReservations) * 100;
  const giftCardTotal_100 =
    calculateGiftCardRedemption(bookingReservations, giftCard) * 100;

  return (bookingTotal_100 - giftCardTotal_100) / 100.0;
};
