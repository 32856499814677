import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import isEmpty from "lodash/fp/isEmpty";
import getOr from "lodash/fp/getOr";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Divider, Grid } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";

import Navbar from "components/Navbar";
import Heading from "components/Heading";
import AddOnCard from "components/AddOnCard";
import LoadingSpinner from "components/LoadingSpinner";
import StepBreadcrumb from "components/StepBreadcrumb";
import MarkdownPopover from "components/MarkdownPopover";
import Basket from "components/Basket";
import FooterNavBar from "components/FooterNavBar";
import { useSearchParams } from "hooks";
import {
  addParkingReservationToBooking,
  addServiceToRoomReservation,
  changeServiceCountForRoomReservation,
  removeParkingReservationFromBooking,
  removeServiceFromRoomReservation,
} from "features/booking/bookingSlice";
import { removeSelectedAfternoonTea } from "features/afternoonTea/afternoonTeaSlice";
import api from "utils/api";
import {
  childrenCount,
  GUEST_TYPE,
  isKioskFromSearchParams,
  nightCount,
  PRICING_UNIT,
} from "utils/helpers";
import {
  trackAddReservationToCartEvent,
  trackRemoveReservationFromCartEvent,
  trackAddServiceToCartEvent,
  trackRemoveServiceFromCartEvent,
} from "utils/analytics";
import { selectAvailCheckParams } from "selectors/booking";
import {
  AFTERNOON_TEA_CODE,
  EVPARKING_SERVICE_CODE,
  STEPS,
} from "utils/constants";
import { CHECKOUT, PLAN_STAY } from "config/routes";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    flexDirection: "column",
    margin: "0 auto 5rem auto",
    width: theme.contentSize.mobilePageWidth,
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      width: theme.contentSize.pageWidth,
      maxWidth: theme.contentSize.maxPageWidth,
    },
  },
  addOnItem: {
    marginBottom: theme.spacing(2),
  },
  footerContainer: {
    display: "flex",
    justifyContent: "center",
    position: "fixed",
    bottom: "0",
    width: "100%",
    padding: "1rem 0",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  footer: {
    width: "80%",
    maxWidth: "1300px",
    display: "flex",
  },
  footerPriceSummary: {
    display: "flex",
    paddingBottom: ".5rem",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      display: "none",
    },
  },
  footerTotal: {
    marginLeft: theme.spacing(1),
  },
  changeSearchButton: {
    padding: "3px 8px",
  },
  continueButton: {
    height: "40px",
    marginLeft: "auto",
  },
  splitContent: {
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      flexDirection: "row",
    },
  },
  detailsContainer: {
    flexGrow: 1,
  },
  basket: {
    width: "100%",
    marginBottom: "20px",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      marginLeft: theme.spacing(8),
      width: "280px",
      flexShrink: 0,
    },
  },
  hidden: {
    display: "none",
  },
}));

// AJD: TODO - Refactor into a 'BookingStep' component, which contains all the common elements
const SelectAddOns = (props) => {
  const classes = useStyles();
  const { data } = props.location;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedPropertyId = useSelector(
    (state) => state.property.selectedPropertyId
  );
  const roomReservations = useSelector(
    (state) => state.booking.roomReservations
  );
  const parkingReservations = useSelector(
    (state) => state.booking.parkingReservations
  );
  const roomOfRooms = getOr(1, ["state", "roomOfRooms"], location);
  const reservationIdx = roomOfRooms - 1;
  const availCheckParams = useSelector(selectAvailCheckParams(roomOfRooms));
  const searchParams = useSearchParams();
  const queryParams = isEmpty(availCheckParams)
    ? data?.queryParams
    : availCheckParams;

  const [pmsServices, setPmsServices] = useState([]);
  const [mappedAddons, setMappedAddons] = useState([]);
  const [parkingAvailability, setParkingAvailability] = useState({});
  const [addParking, setAddParking] = useState(false);
  const [selectedServicesIds, setSelectedServicesIds] = useState(
    roomReservations[reservationIdx]?.services?.map(
      (service) => service?.service?.id
    ) || []
  );
  const [loading, setLoading] = useState(true);

  const [markdownTitle, setMarkdownTitle] = useState("");
  const [markdownText, setMarkdownText] = useState("");
  const [markdownPopoverOpen, setMarkdownPopoverOpen] = useState(false);

  const handleAddServices = (serviceSummaries) => {
    const serviceIds = serviceSummaries.map((s) => s.serviceId);

    // Special case for EV parking
    if (serviceIds.some((id) => id === EVPARKING_SERVICE_CODE)) {
      handleToggleAddParkingReservation();
      return;
    }

    setSelectedServicesIds(selectedServicesIds.concat(serviceIds));

    // Add the services to the basket for this reservation
    serviceSummaries.forEach((s) => {
      const serviceToAdd = {
        ...getServiceFromId(s.serviceId),
        maxCount: s.maxCount, // Pass the max count through so basket knows how many to allow
      };

      dispatch(
        addServiceToRoomReservation({
          reservationIdx: reservationIdx,
          service: serviceToAdd,
          count: s.count,
        })
      );

      trackAddServiceToCartEvent(serviceToAdd, s.count);
    });
  };

  const handleChangeServiceCount = (serviceId, count, maxCount) => {
    const service = roomReservations[reservationIdx].services.find(
      (s) => s.service.id === serviceId
    );

    if (service === undefined) {
      // Service no longer in basket - add again
      handleAddServices([
        {
          serviceId,
          count,
          maxCount,
        },
      ]);
      return;
    }

    if (count === 0) {
      // Remove from basket
      handleRemoveServices([serviceId]);
      return;
    }

    dispatch(
      changeServiceCountForRoomReservation({
        reservationIdx,
        serviceId,
        count,
      })
    );

    const serviceCopy = Object.assign({}, service);
    if (serviceCopy) {
      if (serviceCopy.count > count) {
        trackRemoveServiceFromCartEvent(serviceCopy, serviceCopy.count - count);
      } else {
        trackAddServiceToCartEvent(serviceCopy, count - serviceCopy.count);
      }
    }
  };

  const handleRemoveServices = (serviceIds) => {
    // Special case for EV parking
    if (serviceIds.some((id) => id === EVPARKING_SERVICE_CODE)) {
      handleToggleAddParkingReservation();
      return;
    }

    setSelectedServicesIds(
      selectedServicesIds.filter((id) => !serviceIds.includes(id))
    );

    // Remove the service from the basket for this reservation
    serviceIds.forEach((serviceId) => {
      const serviceToRemove = roomReservations[reservationIdx].services.find(
        (s) => s.service.id === serviceId
      );

      if (serviceToRemove) {
        trackRemoveServiceFromCartEvent(serviceToRemove, serviceToRemove.count);
      }

      dispatch(
        removeServiceFromRoomReservation({
          reservationIdx: reservationIdx,
          serviceId: serviceId,
        })
      );

      /***
       * Special case, to allow removal of reservations for afternoon tea
       * AJD: TODO - Reconsider how we do this
       ***/
      if (serviceToRemove?.service?.code === AFTERNOON_TEA_CODE) {
        dispatch(removeSelectedAfternoonTea());
      }
    });
  };

  const handleServiceRemoved = (serviceId) => {
    setSelectedServicesIds(
      selectedServicesIds.filter((id) => id !== serviceId)
    );
  };

  const handleOpenMarkdownModal = (title, text) => {
    setMarkdownTitle(title);
    setMarkdownText(text);
    setMarkdownPopoverOpen(true);
  };

  const skipToNextStep = () => {
    // Clear any existing selections from redux
    handleRemoveServices(selectedServicesIds);

    // Move to next step
    props.history.push({
      pathname: CHECKOUT,
      data: {},
      state: {},
    });
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleContinue = () => {
    // We write any selected addons, so don't need to dispatch any actions here

    // Move to the add-ons step
    props.history.push({
      pathname: CHECKOUT,
      data: {},
      state: {},
    });
  };

  const getServiceFromId = (serviceId) => {
    return pmsServices.find((e) => e.service.id === serviceId);
  };

  const handleToggleAddParkingReservation = () => {
    const shouldAdd = !addParking;

    if (shouldAdd) {
      // Need to add EV Parking reservation separately since it's a separate reservation from the room
      dispatch(
        addParkingReservationToBooking({
          reservation: parkingAvailability,
          roomOfRooms,
        })
      );

      trackAddReservationToCartEvent(parkingAvailability);
    } else {
      // EV parking will be last reservation in basket at this point
      const reservationIdx = parkingReservations.length - 1;
      dispatch(removeParkingReservationFromBooking(reservationIdx));

      trackRemoveReservationFromCartEvent(parkingAvailability);
    }

    setAddParking(shouldAdd);
  };

  // The defaut number of services to add, if not provided by props
  // This is also the max amount they can select
  const defaultCountForService = (pricingUnit, guestType) => {
    const adultCount = parseInt(availCheckParams.adults);
    const childCount = childrenCount(availCheckParams);

    if (pricingUnit === PRICING_UNIT.PERSON) {
      switch (guestType) {
        case GUEST_TYPE.ADULTS:
          return adultCount;
        case GUEST_TYPE.CHILDREN:
          return childCount;
        default:
          return adultCount + childCount;
      }
    } else {
      // room or stay
      return 1;
    }
  };

  // Maps the pms services to an addon
  const mapServicesForAddon = (addon, pmsServices, parkingAvailability) => {
    // Map pms services specified in cms with the actual services from pms
    let mappedServices = addon.pms_services.map((addonService) => {
      if (addonService.pms_code === EVPARKING_SERVICE_CODE) {
        if (parkingAvailability === undefined) return null;

        return {
          ...addonService,
          pmsId: EVPARKING_SERVICE_CODE,
          name: parkingAvailability.unitGroup.name,
          price: parkingAvailability.totalGrossAmount,
          pricingUnit: "Stay", // EV is charged daily, by the stay
          numberOfNights: nightCount(availCheckParams),
          defaultCount: 1,
          maxCount: 1,
        };
      } else {
        // Lookup and associate with the service from pms
        const pmsService = pmsServices.find(
          (pms) => addonService.pms_code === pms.service.code
        );

        if (!pmsService) return null;

        const defaultServiceCount = defaultCountForService(
          pmsService.service.pricingUnit,
          addonService.guest_type
        );

        return {
          ...addonService,
          pmsId: pmsService.service.id,
          name: pmsService.service.name,
          price: pmsService.service.defaultGrossPrice,
          pricingUnit: pmsService.service.pricingUnit,
          numberOfNights: pmsService.dates.length,
          defaultCount: defaultServiceCount,
          maxCount: defaultServiceCount, // Currently can only add upto the default
        };
      }
    });

    // Filter out services that aren't relevant for this search, or are null
    // Currenty, this filters out children only sevices if no children in the search
    const childCount = childrenCount(availCheckParams);
    mappedServices = mappedServices.filter(
      (s) => s && (s.guest_type === "Children" ? childCount > 0 : true)
    );

    // Make sure this addon is available - ie it has at least 1 sellable service
    if (mappedServices.length === 0) return null;

    return mappedServices;
  };

  useEffect(() => {
    // Check we have the data we need for this step
    if (!queryParams) {
      props.history.push(PLAN_STAY);
      return;
    }

    if (queryParams.ratePlanId) {
      // Get Services of room based on avail check queryParams
      const pmsAddonsRequest = api.getServices(queryParams);

      // Get booking addons from cms
      const cmsAddonsRequest = api.getBookingAddons(selectedPropertyId);

      // Lookup EV parking availability
      const evParkingRequest = api.getAvailabilities({
        propertyId: queryParams.propertyId,
        arrival: queryParams.arrival,
        departure: queryParams.departure,
        timeSliceTemplate: "OverNight",
        unitGroupTypes: "ParkingLot",
        adults: 1,
      });

      Promise.all([pmsAddonsRequest, cmsAddonsRequest, evParkingRequest])
        .then((responses) => {
          const pmsServices = responses[0].data.services;
          const cmsAddons = responses[1].data;
          const evParking = responses[2].data.offers || [];

          // Iterate the addons and associate the related pms service
          // Also adds ev charging as a special case
          const mappedAddons = cmsAddons
            .map((addon) => {
              return {
                ...addon,
                services: mapServicesForAddon(addon, pmsServices, evParking[0]),
              };
            })
            .filter((a) => a.services !== null); // remove null values

          setPmsServices(pmsServices);
          setParkingAvailability(evParking[0]);
          setMappedAddons(mappedAddons);

          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          props.onNotifOpen(error.message, { variant: "error" });
        });
    }
  }, [roomOfRooms]);

  const newAddonCard = (addon, idx) => {
    // Add current state to the service
    const services = addon.services.map((service) => {
      if (service.pmsId === EVPARKING_SERVICE_CODE) {
        return {
          ...service,
          isSelected: addParking,
          count: addParking ? 1 : 0,
        };
      } else {
        const basketService = roomReservations[reservationIdx].services.find(
          (s) => s.service.id === service.pmsId
        );

        return {
          ...service,
          isSelected: basketService !== undefined,
          count: basketService ? basketService.count : 0,
        };
      }
    });

    // Show addon as selected if any of the services are in basket
    const isSelected = services.some(getOr(false, ["isSelected"]));

    return (
      <React.Fragment key={idx}>
        <AddOnCard
          className={classes.addOnItem}
          onAddServices={handleAddServices}
          onRemoveServices={handleRemoveServices}
          onUpdateServiceCount={handleChangeServiceCount}
          onOpenMarkdownModel={handleOpenMarkdownModal}
          title={addon.title}
          description={addon.description}
          image={addon.image}
          links={addon.links}
          pricingUnit={services[0].pricingUnit}
          numberOfNights={services[0].numberOfNights}
          isSelected={isSelected}
          services={services}
          adultCount={parseInt(availCheckParams.adults)}
          childCount={childrenCount(availCheckParams)}
          arrival={roomReservations[0].arrival}
          stayNights={roomReservations[0].timeSlices.length}
        />
        <Box my={2}>
          <Divider />
        </Box>
      </React.Fragment>
    );
  };

  return (
    <div>
      {!isKioskFromSearchParams(searchParams) && (
        <Navbar withProfile onNotifOpen={props.onNotifOpen} />
      )}
      <Grid container className={classes.mainContent}>
        <StepBreadcrumb currentStep={STEPS.SELECT_ADDONS.INDEX} />
        <Heading titleText={STEPS.SELECT_ADDONS.PAGE_TITLE}>
          <Button
            className={classes.changeSearchButton}
            endIcon={<ArrowForward />}
            onClick={skipToNextStep}
          >
            {`skip (do this later)`}
          </Button>
        </Heading>
        <LoadingSpinner loading={loading} />
        <Grid
          container
          className={classNames(
            classes.splitContent,
            loading ? classes.hidden : null
          )}
        >
          <Grid className={classes.detailsContainer}>
            {mappedAddons.map(newAddonCard)}
          </Grid>
          <Basket
            onNotifOpen={props.onNotifOpen}
            className={classes.basket}
            onServiceRemoved={handleServiceRemoved}
          />
        </Grid>
      </Grid>
      <FooterNavBar onBack={handleBack} onContinue={handleContinue} />
      <MarkdownPopover
        open={markdownPopoverOpen}
        onClose={() => setMarkdownPopoverOpen(false)}
        title={markdownTitle}
        markdown={markdownText}
      />
    </div>
  );
};

SelectAddOns.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object,
  onNotifOpen: PropTypes.func,
};

export default SelectAddOns;
