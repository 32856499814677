import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Paper, Typography, Button, Link } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteIcon from "@material-ui/icons/Delete";
import { format, add } from "date-fns";

import { setSelectedAfternoonTea } from "features/afternoonTea/afternoonTeaSlice";
import { selectAfternoonTeaSelections } from "selectors/booking";
import { getSelectedProperty } from "selectors/selectedProperty";
import Stepper from "components/Stepper";
import ReservationSelection from "components/ReservationSelection";
import {
  formatCurrency,
  formatQuantity,
  PRICING_UNIT,
  GUEST_TYPE,
} from "utils/helpers";
import { AFTERNOON_TEA_CODE } from "utils/constants";
import { defaultTimezone } from "utils/timekeep";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    width: "100%",
  },
  detailsContainer: {
    flexDirection: "column",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      flexDirection: "row",
      flexWrap: "nowrap",
    },
  },
  priceLabelContainer: {
    position: "absolute",
    backgroundColor: "#FFFFFF",
    top: 0,
    left: theme.spacing(1),
    padding: `0px ${theme.spacing(1)}px`,
  },
  priceLabel: {
    fontWeight: 700,
  },
  imgContainer: {
    height: "250px",
    position: "relative",
    overflow: "hidden",
    flexShrink: 0,
    marginBottom: theme.spacing(1),

    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      maxWidth: "415px",
      width: "50%",
      marginRight: theme.spacing(2),
      marginBottom: "0px",
    },
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
    height: "100%",
    width: "100%",
    objectFit: "cover",
    top: 0,
    bottom: 0,
  },
  contentContainer: {
    minWidth: 0,
  },
  linkIcon: {
    fontSize: "1.1rem",
    marginRight: "10px",
  },
  addButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    alignSelf: "flex-start",
  },
  removeButton: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey[300],
    "&:hover": {
      backgroundColor: theme.palette.action.main,
    },
  },
  serviceListItemPrice: {
    marginLeft: "5px",
  },
  servicesSummary: {
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  disabledText: {
    color: theme.palette.text.disabled,
  },
  addAndMakeReservation: {
    minWidth: 0,
    [theme.breakpoints.up(theme.breakpoints.values.desktop)]: {
      flexWrap: "nowrap",
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexWrap: "nowrap",
    },
  },
  reservationSelection: {
    minWidth: 0,
    maxWidth: "100%",
    [theme.breakpoints.up(theme.breakpoints.values.desktop)]: {
      paddingLeft: "20px",
      marginLeft: "20px",
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      paddingLeft: "20px",
      marginLeft: "20px",
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
  },
}));

const AddOnCard = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const afternoonTeaSelections = useSelector(selectAfternoonTeaSelections);
  const selectedProperty = useSelector(getSelectedProperty);
  const propertyTimezone = selectedProperty?.timezone || defaultTimezone;

  // View Logic

  const priceSummary = () => {
    // Use the first service to define logic
    const price = props.services[0].price;

    // per person, per night, etc
    let perString = "";
    switch (props.pricingUnit) {
      case PRICING_UNIT.PERSON:
        perString = "/person";
        break;
      case PRICING_UNIT.ROOM:
        perString = props.numberOfNights > 1 ? "/night" : "";
        break;
      case PRICING_UNIT.STAY:
      default:
        perString = "";
        break;
    }

    return price.amount === 0 ? (
      <Typography variant="overline" className={classes.priceLabel}>
        Free
      </Typography>
    ) : perString ? (
      <>
        <Typography variant="overline" className={classes.priceLabel}>
          {formatCurrency(price.currency, price.amount)}
        </Typography>
        <Typography variant="overline" color="textSecondary">
          {" "}
          {perString}
        </Typography>
      </>
    ) : (
      <>
        <Typography variant="overline" color="textSecondary">
          {"Only "}
        </Typography>
        <Typography variant="overline" className={classes.priceLabel}>
          {formatCurrency(price.currency, price.amount)}
        </Typography>
      </>
    );
  };

  // The count in basket, or falls back to the defaults
  const countForService = (service) => {
    return props.isSelected ? service.count : service.defaultCount;
  };

  // Calculates the total for a service, based on type and number of nights
  const calculateServicePrice = (service, count) => {
    const basePrice = service.price.amount;

    if (props.pricingUnit === PRICING_UNIT.STAY) {
      // Stay based services (eg ev charging) gives total amount - not nightly
      return basePrice * count;
    } else {
      const numberOfNights = props.numberOfNights;
      return basePrice * numberOfNights * count;
    }
  };

  const getTotalPriceStr = () => {
    // Returns price string e.g £10 or Free
    const sum = props.services.reduce((acc, s) => {
      const count = countForService(s);
      return acc + calculateServicePrice(s, count);
    }, 0);

    // Use currency from first service - they should all be the same
    const service = props.services[0];
    return sum === 0 ? "Free" : formatCurrency(service.price.currency, sum);
  };

  // Array of guest type string e.g [1 Adult,1 Child]
  const getGuestTypes = () => {
    const guestTypes = props.services.map((s) => {
      const count = countForService(s);
      switch (s.guest_type) {
        case GUEST_TYPE.ADULTS:
          return formatQuantity(count, "Adult");

        case GUEST_TYPE.CHILDREN:
          return count === 1 ? "1 Child" : `${count} Children`;

        default:
          return formatQuantity(count, "guest");
      }
    });
    return guestTypes.filter((t) => t); // remove null values
  };

  const addButtonText = () => {
    if (props.pricingUnit === PRICING_UNIT.PERSON) {
      return `Add | ${getGuestTypes().join(", ")} (${getTotalPriceStr()})`;
    } else {
      // pricing unit will be "room" or "stay"
      return `Add | ${getTotalPriceStr()}`;
    }
  };

  const servicesSummary = () => {
    const pricingUnit = props.pricingUnit;
    const nightsStr =
      props.numberOfNights > 1 ? ` for ${props.numberOfNights} nights` : "";

    let detailsStr = "Total";

    switch (pricingUnit) {
      case PRICING_UNIT.PERSON: {
        const guestStr = getGuestTypes().join(", ");
        detailsStr = `${detailsStr}${nightsStr} (${guestStr})`;
        break;
      }

      case PRICING_UNIT.ROOM:
        detailsStr = `${detailsStr}${nightsStr}`;
        break;

      case PRICING_UNIT.STAY:
        break;

      default:
        break;
    }

    return `${detailsStr}: ${getTotalPriceStr()}`;
  };

  const formatIcon = (iconName) => {
    let [collection, icon] = iconName.split(" ");
    icon = icon.replace("fa-", "", 1);
    return [collection, icon];
  };

  // Button handlers

  const handleLinkButtonPressed = (link) => {
    if (link.url && link.url.length > 0) {
      window.open(link.url, "_blank");
    } else {
      props.onOpenMarkdownModel(link.title, link.text);
    }
  };

  const handleAddButtonPressed = (e) => {
    if (props.isSelected) {
      // Remove the services
      const serviceIds = props.services.map((s) => s.pmsId);
      props.onRemoveServices(serviceIds);
    } else {
      // Add the services
      const serviceSummaries = props.services.map((s) => {
        return {
          serviceId: s.pmsId,
          count: countForService(s),
          maxCount: s.maxCount,
        };
      });

      props.onAddServices(serviceSummaries);
    }
  };

  const handleServiceCountChanged = (service, count) => {
    props.onUpdateServiceCount(service.pmsId, count, service.maxCount);
  };

  // AJD: TODO - This should to be fetched from Seven Rooms rather than being hard-coded
  const generateAfternoonTeaAvailability = () => {
    const availability = {};

    for (let a = 0; a <= props.stayNights - 1; a += 1) {
      const date = format(
        add(new Date(props.arrival), { days: a }),
        "yyyy-MM-dd"
      );
      availability[date] = [
        "15:15",
        "15:30",
        "15:45",
        "16:00",
        "16:15",
        "16:30",
        "16:45",
        "17:00",
      ];
    }

    return availability;
  };

  const createServiceRows = () => {
    return props.services.map((service, i) => (
      <Grid
        container
        justify="space-between"
        wrap="nowrap"
        alignItems="center"
        key={i}
      >
        <Grid container alignItems="baseline">
          <Typography
            variant="subtitle1"
            text="textPrimary"
            className={props.isSelected ? null : classes.disabledText}
          >
            {service.name}
          </Typography>
          <Typography
            variant="body1"
            color="primary"
            className={classNames(
              classes.serviceListItemPrice,
              props.isSelected ? null : classes.disabledText
            )}
          >
            {formatCurrency(service.price.currency, service.price.amount)}
            <Typography
              variant="caption"
              component="span"
              color="textSecondary"
              className={props.isSelected ? null : classes.disabledText}
            >
              {" /"}
              {props.pricingUnit.toLowerCase()}
              {props.numberOfNights > 1 ? "/night" : ""}
            </Typography>
          </Typography>
        </Grid>
        <Stepper
          name={`service_stepper-${i}`}
          ariaHint={`number of ${service.name} services`}
          variant="primary"
          count={countForService(service)}
          disabled={!props.isSelected}
          decrementDisabled={service.count <= 0}
          incrementDisabled={countForService(service) >= service.defaultCount}
          onValueChanged={(name, count) =>
            handleServiceCountChanged(service, count)
          }
        />
      </Grid>
    ));
  };

  return (
    <Grid container className={props.className}>
      <Paper elevation={0} className={classes.card}>
        <Grid container className={classes.detailsContainer}>
          {props.image ? (
            <Grid item className={classes.imgContainer}>
              <img className={classes.img} alt="addon" src={props.image.url} />
              <div className={classes.priceLabelContainer}>
                {priceSummary()}
              </div>
            </Grid>
          ) : null}
          <Grid item className={classes.contentContainer}>
            <Grid container direction="column">
              <Typography variant="subtitle1" gutterBottom>
                {props.title}
              </Typography>
              <Typography variant="body2" text="textPrimary">
                {props.description}
              </Typography>
              {props.links && props.links.length > 0 ? (
                <Box marginTop={2}>
                  <Grid container direction="column" alignItems="flex-start">
                    {props.links.map((link, i) => (
                      <Link
                        onClick={() => handleLinkButtonPressed(link)}
                        key={i}
                      >
                        {link.icon && link.icon.length > 0 ? (
                          <FontAwesomeIcon
                            className={classes.linkIcon}
                            icon={formatIcon(link.icon)}
                          />
                        ) : null}
                        {link.title}
                      </Link>
                    ))}
                  </Grid>
                </Box>
              ) : null}

              <Grid
                container
                direction="row"
                className={classes.addAndMakeReservation}
              >
                <Grid item>
                  <Button
                    className={classNames(
                      classes.addButton,
                      props.isSelected ? classes.removeButton : null
                    )}
                    color="primary"
                    variant="contained"
                    onClick={handleAddButtonPressed}
                    endIcon={props.isSelected ? <DeleteIcon /> : null}
                  >
                    {props.isSelected
                      ? `Remove${props.services.length > 1 ? " all" : ""}`
                      : addButtonText()}
                  </Button>
                </Grid>
                {
                  /***
                   * Special case, to allow reservations for afternoon tea
                   * AJD: TODO - Reconsider how we do this
                   ***/
                  props.isSelected &&
                    props.services[0]?.pms_code === AFTERNOON_TEA_CODE && (
                      <Grid item className={classes.reservationSelection}>
                        <ReservationSelection
                          availability={generateAfternoonTeaAvailability()}
                          currentDate={
                            afternoonTeaSelections[
                              Object.keys(afternoonTeaSelections)[0] // Will only be one reservation for now
                            ]?.date
                          }
                          currentTime={
                            afternoonTeaSelections[
                              Object.keys(afternoonTeaSelections)[0] // Will only be one reservation for now
                            ]?.time
                          }
                          makeSelection={(date, time) => {
                            // Only accommodating one afternoon tea booking for now
                            dispatch(
                              setSelectedAfternoonTea({
                                [date]: {
                                  venueId:
                                    props.services[0].booking_external_id,
                                  date,
                                  time,
                                },
                              })
                            );
                          }}
                          timezone={propertyTimezone}
                        />
                      </Grid>
                    )
                }
              </Grid>

              {
                /* Only show services row for people based addons */
                props.pricingUnit === PRICING_UNIT.PERSON
                  ? createServiceRows()
                  : null
              }

              {props.isSelected ? (
                <Grid className={classes.servicesSummary}>
                  <Typography variant="caption" color="textSecondary">
                    {servicesSummary()}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

AddOnCard.propTypes = {
  className: PropTypes.string,
  onAddServices: PropTypes.func,
  onRemoveServices: PropTypes.func,
  onUpdateServiceCount: PropTypes.func,
  onOpenMarkdownModel: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  links: PropTypes.arrayOf(PropTypes.object),
  pricingUnit: PropTypes.string,
  numberOfNights: PropTypes.number,
  isSelected: PropTypes.bool,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      pmsId: PropTypes.string.isRequired,
      pms_code: PropTypes.string.isRequired,
      booking_external_id: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
      }),
      isSelected: PropTypes.bool,
      count: PropTypes.number,
      defaultCount: PropTypes.number,
      maxCount: PropTypes.number,
    })
  ).isRequired,
  arrival: PropTypes.string.isRequired,
  stayNights: PropTypes.number.isRequired,
};

export default AddOnCard;
