import React from "react";

// Material UI
import {
  Box,
  Divider,
  Grid,
  Link,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  linkSection: {
    display: "flex",
    flexDirection: "column",
  },
  footerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const Footer = (props) => {
  const classes = useStyles();

  // Just hardcoded list of link names for now, for the demo
  // Sectioned
  const LINK_SECTIONS = [
    {
      name: "Policies",
      links: ["Privacy Policy", "Cookie Policy", "Terms & Conditions"],
    },
    {
      name: "About",
      links: ["Company", "Contact", "News"],
    },
    {
      name: "Book your room",
      links: ["Exclusive Breaks", "Search Breaks"],
    },
    {
      name: "Discovery",
      links: ["Instagram", "Facebook", "Twitter", "YouTube"],
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        {LINK_SECTIONS.map((section, sectionIdx) => (
          <Grid
            item
            key={`section-${sectionIdx}`}
            className={classes.linkSection}
            xs={3}
          >
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {section.name}
            </Typography>
            {section.links.map((link, linkIdx) => (
              <Link
                key={`link-${linkIdx}`}
                href="/"
                variant="body2"
                gutterBottom
                underline="always"
              >
                {link}
              </Link>
            ))}
          </Grid>
        ))}
      </Grid>

      <Box my={6}>
        <Divider />
      </Box>

      <Box my={6} className={classes.footerContainer}>
        <Typography variant="body2" color="textSecondary">
          © Copyright 2021 Warner Leisure Hotels
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Designed by <Link to="https://alliants.com">Alliants</Link>
        </Typography>
      </Box>
    </>
  );
};

export default Footer;
