import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import moment from "moment";

// Material UI
import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Popover,
  Select,
  TextField,
  //Link,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import DateRangeIcon from "@material-ui/icons/DateRange";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

// Components
import RoomPicker from "components/RoomPicker";
import CheckInDateActionSheet from "components/CheckInDateActionSheet";
import CheckInDatePicker from "components/CheckInDatePicker";
import ActionSheet from "components/ActionSheet";

// Utils
import { formatDateShort } from "utils/timekeep";
import { formatQuantity } from "utils/helpers";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
  },
  inputField: {
    minWidth: "150px",
    marginRight: "2px",
    maxWidth: "100%",
    flexGrow: 1,
    flexBasis: 0,
  },
  hidden: {
    display: "none",
  },
  submitButton: {
    color: "white !important", // Fix safari issue with button text color not updating
  },
  datePopover: {
    padding: "5px 30px",
  },
  togglePromocodeButton: {
    marginTop: "6px",
    marginLeft: "4px",
    color: theme.palette.common.black,
    display: "none", // disable for demo
  },
}));

const SelectBookingForm = (props) => {
  const classes = useStyles();

  const theme = useTheme();
  const tabletUpScreen = useMediaQuery(theme.breakpoints.up("tablet"));

  const [showCorporateCode] = useState(false);

  const [roomsPickerAnchorEl, setRoomsPickerAnchorEl] = useState(null);
  const roomsPickerOpen = Boolean(roomsPickerAnchorEl);
  const roomsPickerId = roomsPickerOpen ? "room-picker-popover" : undefined;

  const [datePopoverAnchorEl, setDatePopoverAnchorEl] = useState(null);
  const datePopoverOpen = Boolean(datePopoverAnchorEl);
  const datePopoverId = datePopoverOpen ? "date-picker-popover" : undefined;

  const [calendarDrawerOpen, setCalendarDrawerOpen] = useState(false);
  const [roomsDrawerOpen, setRoomsDrawerOpen] = useState(false);

  const handleRoomsPickerOpen = (e) => {
    tabletUpScreen
      ? setRoomsPickerAnchorEl(e.currentTarget)
      : setRoomsDrawerOpen(true);
  };
  const handleRoomsPickerClose = () => {
    tabletUpScreen ? setRoomsPickerAnchorEl(null) : setRoomsDrawerOpen(false);
  };

  const handleDatePickerOpen = (e) => {
    tabletUpScreen
      ? setDatePopoverAnchorEl(e.currentTarget)
      : setCalendarDrawerOpen(true);
  };
  const handleDatePickerClose = () => {
    tabletUpScreen
      ? setDatePopoverAnchorEl(null)
      : setCalendarDrawerOpen(false);
  };

  // const togglePromoCode = (event) => {
  //   event.preventDefault();
  //   setShowCorporateCode(!showCorporateCode);
  // };

  const onCheckInDateChange = (checkInDate) => {
    props.onCheckInDateChange(checkInDate);
    handleDatePickerClose();
  };

  const formattedCheckInDate = () => {
    const checkInDateStr = props.checkInDate
      ? formatDateShort(props.checkInDate)
      : "";

    return `Check-in: ${checkInDateStr}`;
  };

  const roomsAndGuestsTotal = () => {
    const totalAdults = props.roomsOccupancy.reduce((sum, room) => {
      return sum + parseInt(room.adults);
    }, 0);
    const totalChildren = props.roomsOccupancy.reduce((sum, room) => {
      return sum + parseInt(room.children);
    }, 0);
    const totalGuests = parseInt(totalAdults) + parseInt(totalChildren);

    if (props.singleRoomMode) {
      // e.g 2 Guests
      return formatQuantity(totalGuests, "Guest");
    } else {
      // e.g 2 Rooms, 4 Guests
      const totalRooms = props.roomsOccupancy.length;
      return `${formatQuantity(totalRooms, "Room")}, ${formatQuantity(
        totalGuests,
        "Guest"
      )}`;
    }
  };

  return (
    <form
      className={classNames(classes.form, props.className)}
      onSubmit={props.onSubmit}
    >
      <Grid container alignItems="flex-start">
        {/* Property Picker - usually hidden */}
        <Select
          className={classNames(
            classes.inputField,
            !props.showPropertiesDropdown && classes.hidden
          )}
          value={props.selectedPropertyId || ""}
          onChange={props.onPropertyChange}
          variant="outlined"
        >
          {props.properties.map((property) => (
            <MenuItem key={property.code} value={property.code}>
              {property.name}
            </MenuItem>
          ))}
        </Select>

        {/* Check In Date Picker */}
        {tabletUpScreen ? (
          <>
            <TextField
              className={classes.inputField}
              value={formattedCheckInDate()}
              onClick={handleDatePickerOpen}
              inputProps={{
                readOnly: true,
                style: { cursor: "pointer" },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <DateRangeIcon color="action" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
            <Popover
              id={datePopoverId}
              open={datePopoverOpen}
              onClose={handleDatePickerClose}
              anchorReference="anchorEl"
              anchorEl={datePopoverAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                className: classes.datePopover,
              }}
            >
              <CheckInDatePicker
                date={props.checkInDate}
                dateUpdated={onCheckInDateChange}
                allowDatesFrom={props.allowDatesFrom}
              />
            </Popover>
          </>
        ) : (
          <>
            <TextField
              className={classes.inputField}
              value={formattedCheckInDate()}
              onClick={handleDatePickerOpen}
              inputProps={{
                readOnly: true,
                style: { cursor: "pointer" },
              }}
              variant="outlined"
            />
            <CheckInDateActionSheet
              open={calendarDrawerOpen}
              onCancel={handleDatePickerClose}
              onOK={onCheckInDateChange}
              date={props.checkInDate}
              allowDatesFrom={props.allowDatesFrom}
            />
          </>
        )}

        {/* Number of nights */}
        <Select
          className={classes.inputField}
          value={props.numberOfNights}
          onChange={props.onNumberOfNightsChange}
          variant="outlined"
        >
          <MenuItem value="3">3 Nights</MenuItem>
          <MenuItem value="1">1 Night</MenuItem>
          <MenuItem value="5">5 Nights</MenuItem>
          <MenuItem value="7">7 Nights</MenuItem>
        </Select>

        {/* Guest count */}
        <TextField
          className={classes.inputField}
          value={roomsAndGuestsTotal()}
          onClick={handleRoomsPickerOpen}
          inputProps={{
            readOnly: true,
            style: { cursor: "pointer" },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDownIcon color="action" />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
        {tabletUpScreen ? (
          <Popover
            id={roomsPickerId}
            open={roomsPickerOpen}
            onClose={handleRoomsPickerClose}
            anchorReference={"anchorEl"}
            anchorEl={roomsPickerAnchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <RoomPicker
              roomOccupancies={props.roomsOccupancy}
              onAddRoom={props.onAddRoom}
              onRemoveRoom={props.onRemoveRoom}
              onAdultsChanged={props.onAdultsChanged}
              onChildrenChanged={props.onChildrenChanged}
              singleRoom={props.singleRoomMode}
            />
          </Popover>
        ) : (
          <ActionSheet
            title="Guests"
            open={roomsDrawerOpen}
            onOK={handleRoomsPickerClose}
          >
            <RoomPicker
              roomOccupancies={props.roomsOccupancy}
              onAddRoom={props.onAddRoom}
              onRemoveRoom={props.onRemoveRoom}
              onAdultsChanged={props.onAdultsChanged}
              onChildrenChanged={props.onChildrenChanged}
              singleRoom={props.singleRoomMode}
            />
          </ActionSheet>
        )}

        {/* Promo Code */}
        <Grid className={classes.promoCodeContainer}>
          <Grid>
            {showCorporateCode ? (
              <>
                <TextField
                  className={classes.inputField}
                  value={props.corporateCode}
                  onChange={props.onCorporateCodeChanged}
                  variant="outlined"
                  inputProps={{ placeholder: "Corporate Code" }}
                />
              </>
            ) : (
              <>
                <TextField
                  className={classes.inputField}
                  value={props.promoCode}
                  onChange={props.onPromoCodeChanged}
                  variant="outlined"
                  inputProps={{ placeholder: "Promo Code" }}
                />
              </>
            )}
          </Grid>
          {/* Hitting enter in the promo code box triggers this link, so removing completely
            <Link
              component="button"
              variant="body2"
              onClick={togglePromoCode}
              className={classes.togglePromocodeButton}
            >
              I have a {showCorporateCode ? "promo code" : "partner code"}
            </Link>
            */}
        </Grid>
      </Grid>

      <Button
        variant="contained"
        type="submit"
        color="primary"
        size="large"
        className={classes.submitButton}
        disabled={!props.enabled}
      >
        Search
      </Button>
    </form>
  );
};

SelectBookingForm.propTypes = {
  className: PropTypes.string,

  // Properties drop down
  showPropertiesDropdown: PropTypes.bool,
  properties: PropTypes.arrayOf(PropTypes.object),
  selectedPropertyId: PropTypes.string,
  onPropertyChange: PropTypes.func,

  // Check in date picker
  allowDatesFrom: PropTypes.instanceOf(moment),
  checkInDate: PropTypes.instanceOf(Date),
  onCheckInDateChange: PropTypes.func,

  // Number of nights
  numberOfNights: PropTypes.number,
  onNumberOfNightsChange: PropTypes.func,

  // Room picker
  singleRoomMode: PropTypes.bool,
  roomsOccupancy: PropTypes.arrayOf(PropTypes.object),
  onAddRoom: PropTypes.func,
  onRemoveRoom: PropTypes.func,
  onAdultsChanged: PropTypes.func,
  onChildrenChanged: PropTypes.func,

  // Promo code
  promoCode: PropTypes.string,
  corporateCode: PropTypes.string,
  onPromoCodeChanged: PropTypes.func,
  onCorporateCodeChanged: PropTypes.func,

  // Enables the submit button of the form
  enabled: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default SelectBookingForm;
