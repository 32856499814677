import React from "react";
import { useInterval } from "hooks";
import PropTypes from "prop-types";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import api from "utils/api";
import { Grid, Typography, Dialog } from "@material-ui/core";
import PayAtMachineImage from "images/pay_at_machine.png";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxWidth: "575px",
  },
  heading: {
    textAlign: "center",
    margin: "24px 32px",
  },
  payAtMachineImage: {
    width: "100%",
  },
  messageContainer: {
    padding: theme.spacing(3),
  },
  loadingContainer: {
    margin: theme.spacing(2),
  },
}));

const TerminalPaymentInProgressDialog = (props) => {
  const classes = useStyles();

  const checkTerminalPaymentStatus = async (
    propertyId,
    bookingId,
    paymentTimeout,
    paymentId,
    folioId
  ) => {
    console.log(`Checking payment status ${moment()}`);
    const paymentResponse = await api.getPaymentStatus(paymentId, folioId);

    switch (paymentResponse.data.status) {
      case "Success":
        // Payment is complete - send user to the confirmation page
        props.onPaymentSuccess(bookingId);
        break;

      case "Failure":
        await props.onPaymentFailed(
          propertyId,
          bookingId,
          paymentResponse.data.failureReason
        );
        break;

      case "Canceled":
        await props.onPaymentFailed(
          bookingId,
          "Your payment was cancelled. Please try again."
        );
        break;

      case "Pending":
      default:
        // Payment still pending - do nothing - we'll check again in 5s
        break;
    }
  };

  useInterval(
    async () => {
      const {
        propertyId,
        bookingId,
        paymentTimeout,
        paymentId,
        folioId,
      } = props.paymentData;

      if (moment().isBefore(paymentTimeout)) {
        await checkTerminalPaymentStatus(
          propertyId,
          bookingId,
          paymentTimeout,
          paymentId,
          folioId
        );
      } else {
        console.log("Payment timed out");
        await props.onPaymentFailed(
          propertyId,
          bookingId,
          "Your payment timed out. Please try again."
        );
      }
    },
    props.paymentData ? 5000 : null
  );

  return (
    <Dialog
      open={props.paymentData != null}
      PaperProps={{ className: classes.dialogPaper }}
    >
      <Typography variant="h6" color="textPrimary" className={classes.heading}>
        Please follow the instructions at the payment machine.
      </Typography>
      <img src={PayAtMachineImage} alt="Pay at machine" width="100%" />
      <Grid container justify="center" className={classes.buttonContainer}>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.heading}
        >
          To cancel this payment, press the cancel button on the payment
          machine.
        </Typography>
      </Grid>
    </Dialog>
  );
};

TerminalPaymentInProgressDialog.propTypes = {
  paymentData: PropTypes.shape({
    propertyId: PropTypes.string,
    bookingId: PropTypes.string,
    paymentTimeout: PropTypes.instanceOf(moment),
    paymentId: PropTypes.string,
    folioId: PropTypes.string,
  }),
  onPaymentSuccess: PropTypes.func,
  onPaymentFailed: PropTypes.func,
};

export default TerminalPaymentInProgressDialog;
