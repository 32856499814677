import { createSlice } from "@reduxjs/toolkit";
import api from "utils/api";

export const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    error: "",
    paymentMethodsRes: null,
    paymentRes: null,
    grossAmount: 0,
    config: {
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          name: "Credit or Debit Card",
          amount: {
            value: 0,
            currency: "GBP",
          },
        },
        paywithgoogle: {
          environment:
            process.env.REACT_APP_ADYEN_ENV === "live" ? "PRODUCTION" : "TEST", // "TEST" or "PRODUCTION"
          amount: {
            currency: "GBP",
            value: 0,
          },
          configuration: {
            merchantName: "Warner Leisure", // The name that appears in the payment sheet
          },
          billingAddressRequired: true,
        },
        applepay: {
          amount: 0,
          currencyCode: "GBP",
          countryCode: "GB",
          buttonType: "book",
        },
      },
      locale: "en-GB",
      showPayButton: true,
      clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
      environment: process.env.REACT_APP_ADYEN_ENV, // "live" or "test"
    },
  },
  reducers: {
    grossAmount: (state, action) => {
      // Update the gross amount in the state
      const grossAmount = action.payload;
      const adyenAmount = grossAmount * 100; // Adyen works in minor units

      state.grossAmount = grossAmount;
      state.config.paymentMethodsConfiguration.card.amount.value = adyenAmount;
      state.config.paymentMethodsConfiguration.paywithgoogle.amount.value = adyenAmount;
      state.config.paymentMethodsConfiguration.applepay.amount = adyenAmount;
    },
    paymentError: (state, action) => {
      state.error = action.payload;
    },
    paymentMethods: (state, action) => {
      const res = action.payload;

      const enabledPaymentMethodsStr =
        process.env.REACT_APP_ADYEN_PAYMENT_METHODS;
      const enabledPaymentMethods = enabledPaymentMethodsStr
        ? enabledPaymentMethodsStr.split(",")
        : ["scheme", "paywithgoogle", "applepay"];

      res.paymentMethods = res.paymentMethods.filter(
        (it) => enabledPaymentMethods.includes(it.type) // Only show supported payment methods
      );
      state.paymentMethodsRes = res;
    },
    paymentResponse: (state, action) => {
      const res = action.payload;
      state.paymentRes = res;
    },
  },
});

export const setPaymentError = (error) => (dispatch) => {
  console.log(`Setting payment error - ${error}`);
  dispatch(paymentError(error));
};

export const getPaymentMethods = (propertyId, amount) => async (dispatch) => {
  // Save the gross amount
  dispatch(grossAmount(amount));

  const data = {
    grossAmount: amount,
    propertyId: propertyId,
  };

  try {
    const response = await api.getPaymentMethods(data);
    dispatch(paymentMethods(response.data));
  } catch (error) {
    console.log(
      `Error fetching payment methods: ${JSON.stringify(error.response)}`
    );
    dispatch(
      paymentError(
        "Our payment system is currently down. Please try again later."
      )
    );
  }
};

export const initiatePayment = (data) => async (dispatch) => {
  try {
    const response = await api.makePayment(data);
    dispatch(paymentResponse(response.data));
  } catch (error) {
    console.log(`Error making payment: ${JSON.stringify(error.response)}`);
    const message = error.response.data.message || "An unknown error occurred";
    dispatch(paymentError(message));
  }
};

export const {
  grossAmount,
  paymentError,
  paymentMethods,
  paymentResponse,
} = paymentSlice.actions;
export default paymentSlice.reducer;
