// AJD: TODO - Standardise to use date-fns rather than moment

import moment from "moment";
import { startOfDay, endOfDay, format } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

export const today = () => {
  return new Date();
};

export const tomorrow = () => {
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

// Takes a date object and returns format YYYY-MM-DD
export const dateToStr = (dateObj) => {
  return moment(dateObj).format("YYYY-MM-DD");
};

// Takes a date object and returns format "1 Mar"
// or "1 Mar, 2021" if includeYear = true
export const formatDateShort = (dateObj, includeYear = false) => {
  return moment(dateObj).format(includeYear ? "D MMM, YYYY" : "D MMM");
};

// Takes a date object and returns format "Thu, 1 Mar"
export const formatDateMedium = (dateObj) => {
  return moment(dateObj).format("ddd, D MMM");
};

// Takes a date object and returns format "HH:MM"
export const formatDateHoursMins = (dateObj) => {
  return moment(dateObj).format("HH:mm");
};

// Returns the defalt check in date - ie the next Monday, Wednesday or Friday from today
export const getDefaultCheckInDate = (fromDate = new Date()) => {
  // The next date array defines for each week day (sun=0) what the next valid day is
  const nextDate = [1, 1, 3, 3, 5, 8, 8];
  const fromDay = moment(fromDate).day();
  return moment(fromDate).day(nextDate[fromDay]).toDate();
};

// Fallback timezone if not specified in property
export const defaultTimezone = "Europe/London";

// Calculates the start of the day in a given timezone, and returns an ISO timestamp
// example invocation: startOfDayInTimezone("2021-11-03T02:00:00Z", "Europe/London")
export const startOfDayInTimezone = (date, timezone) => {
  const dateZoned = utcToZonedTime(date, timezone);
  const dateZonedStartOfDay = startOfDay(dateZoned);
  const dateUtcStartOfDay = zonedTimeToUtc(dateZonedStartOfDay, timezone);
  return dateUtcStartOfDay.toISOString();
};

// Calculates the end of the day in a given timezone, and returns an ISO timestamp
// example invocation: endOfDayInTimezone("2021-11-03T02:00:00Z", "Europe/London")
export const endOfDayInTimezone = (date, timezone) => {
  const dateZoned = utcToZonedTime(date, timezone);
  const dateZonedStartOfDay = endOfDay(dateZoned);
  const dateUtcStartOfDay = zonedTimeToUtc(dateZonedStartOfDay, timezone);
  return dateUtcStartOfDay.toISOString();
};

// Formats a date string in a given timezone
// example invocation: formatInTimezone("2021-11-03T02:00:00Z", "Europe/London", "yyyy-MM-dd")
export const formatInTimezone = (date, timezone, formatString) => {
  const dateZoned = utcToZonedTime(date, timezone);
  return format(dateZoned, formatString);
};
