import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, connect, useDispatch } from "react-redux";
import has from "lodash/fp/has";
import getOr from "lodash/fp/getOr";
import toNumber from "lodash/fp/toNumber";

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  IconButton,
  Typography,
  Paper,
  Select,
  MenuItem,
  TextField,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import RedeemIcon from "@material-ui/icons/Redeem";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Cancel";

import { getSelectedProperty } from "selectors/selectedProperty";
import {
  selectCommonSearchParams,
  selectRoomReservations,
  selectParkingReservations,
  selectDiningSelections,
  selectAvailCheckParams,
  selectEntertainmentSelections,
} from "selectors/booking";
import {
  removeParkingReservationFromBooking,
  changeServiceCountForRoomReservation,
  setGiftCard,
} from "features/booking/bookingSlice";
import { removeSelectedAfternoonTea } from "features/afternoonTea/afternoonTeaSlice";
import {
  formatCurrency,
  formatQuantity,
  guestCount,
  nightCount,
} from "utils/helpers";
import api from "utils/api";
import {
  calculateBookingTotal,
  calculateBookingTotalVat,
  calculateGiftCardRedemption,
} from "utils/bookingTotals";
import { formatDateShort } from "utils/timekeep";
import {
  trackRemoveReservationFromCartEvent,
  trackAddServiceToCartEvent,
  trackRemoveServiceFromCartEvent,
} from "utils/analytics";
import { AFTERNOON_TEA_CODE } from "utils/constants";

import DiningSelections from "components/DiningSelections";
import EntertainmentSelections from "components/EntertainmentSelections";
import Services from "components/Services";

const useStyles = makeStyles((theme) => ({
  bookingSummaryCard: {
    overflow: "hidden",
    width: "100%",

    // Stick to top of the screen on scroll
    position: "sticky",
    top: "20px",
  },
  bookingSummary: {
    flexDirection: "column",
  },
  summaryItemAttribute: {
    flexGrow: 5,
  },
  editAvailBtn: {
    height: "40px",
  },
  giftCardContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  giftCard: {
    border: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    padding: "0px 0px 0px 6px",
    marginLeft: "5px",
    minWidth: "0px", // needed to allow text to ellipsis on small screens
    background: theme.palette.grey[300],
  },
  giftCardField: {
    marginRight: "10px",
  },
  giftCardFieldInput: {
    padding: "8px",
  },
  removeGiftCardBtn: {
    color: theme.palette.grey[900],
  },
  locationIcon: {
    color: theme.palette.primary.main,
    marginRight: "8px",
  },
  roomImage: {
    objectFit: "cover",
    height: "87px",
    width: "87px",
    marginRight: "10px",
  },
  roomHeader: {
    marginBottom: "0rem",
  },
  right: {
    textAlign: "right",
  },
  stepper: {
    marginLeft: "5px",
    marginRight: "5px",
    width: "auto",
  },
  dropdownRoot: {
    ...theme.typography.caption,
    padding: "8px",
    textAlign: "center",
  },
  accordionSummary: {
    padding: "0px",
  },
  accordionDetails: {
    display: "block",
    padding: "0px",
  },
}));

const Basket = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { arrival, departure } = useSelector(selectCommonSearchParams);

  const roomOfRooms = getOr(1, ["state", "roomOfRooms"], location);
  const availCheckParams = useSelector(selectAvailCheckParams(roomOfRooms));

  const roomReservations = useSelector(selectRoomReservations);
  const parkingReservations = useSelector(selectParkingReservations);
  const diningSelections = useSelector(selectDiningSelections);
  const entertainmentSelections = useSelector(selectEntertainmentSelections);

  const [loadingGiftCard, setLoadingGiftCard] = useState(false);
  const giftCardFieldRef = useRef();

  const guestsStringForReservation = (roomIndex) => {
    const reservation = roomReservations[roomIndex];
    const adults = reservation.adults;
    const children = (reservation.childrenAges || []).length;

    const adultsStr = formatQuantity(adults, "Adult");
    let childrenStr = null;

    if (children === 1) {
      childrenStr = "1 Child";
    } else if (children >= 1) {
      childrenStr = `${children} Children`;
    }

    return [adultsStr, childrenStr].filter((s) => s).join(", ");
  };

  /*
  const removeRoomReservation = (e) => {
    const deletedReservation = roomReservations[e.currentTarget.value];
    trackRemoveReservationFromCartEvent(deletedReservation);

    dispatch(removeRoomReservationFromBooking(toNumber(e.currentTarget.value)));

    if (props.onReservationRemoved) {
      props.onReservationRemoved();
    }
  };
  */

  const removeParkingReservation = (e) => {
    const deletedReservation = parkingReservations[e.currentTarget.value];
    trackRemoveReservationFromCartEvent(deletedReservation);

    dispatch(
      removeParkingReservationFromBooking(toNumber(e.currentTarget.value))
    );

    if (props.onReservationRemoved) {
      props.onReservationRemoved();
    }
  };

  const serviceCountChanged = (reservationIdx, serviceId, count) => {
    const oldService = Object.assign(
      {},
      roomReservations[reservationIdx].services.find(
        (s) => s.service.id === serviceId
      )
    );

    dispatch(
      changeServiceCountForRoomReservation({
        reservationIdx,
        serviceId,
        count,
      })
    );

    if (oldService) {
      if (oldService.count > count) {
        trackRemoveServiceFromCartEvent(oldService, oldService.count - count);
      } else {
        trackAddServiceToCartEvent(oldService, count - oldService.count);
      }
    }

    if (count === 0 && props.onServiceRemoved) {
      props.onServiceRemoved(serviceId);
    }

    /***
     * Special case, to allow removal of reservations for afternoon tea
     * AJD: TODO - Reconsider how we do this
     ***/
    if (count === 0 && oldService?.service?.code === AFTERNOON_TEA_CODE) {
      dispatch(removeSelectedAfternoonTea());
    }
  };

  const applyGiftCard = async () => {
    const giftCardNumber = giftCardFieldRef.current.value;

    // Dont do anything if no text
    if (!giftCardNumber) {
      return;
    }

    // Check gift card balance
    setLoadingGiftCard(true);

    try {
      const response = await api.getGiftCardBalance(giftCardNumber);

      dispatch(setGiftCard(response.data));

      props.onNotifOpen("Gift card added");
    } catch (e) {
      console.error(e.message);

      if (e.response.data && e.response.data.error) {
        props.onNotifOpen(e.response.data.error, { variant: "error" });
      } else {
        props.onNotifOpen(e.message, { variant: "error" });
      }
    } finally {
      setLoadingGiftCard(false);
    }
  };

  const quantityDropdownForService = (service, reservationIdx) => {
    const values = Array.from(Array(service.maxCount + 1).keys());

    return (
      <Select
        value={service.count}
        onChange={(e) => {
          serviceCountChanged(
            reservationIdx,
            service.service.id,
            e.target.value
          );
        }}
        variant="outlined"
        classes={{
          root: classes.dropdownRoot,
        }}
        autoWidth={true}
      >
        {values.map((val) => (
          <MenuItem value={val} key={val}>
            {val}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const giftCardField = () => {
    return (
      <Grid className={classes.giftCardContainer}>
        <Typography variant="body2" gutterBottom>
          Gift Card
        </Typography>
        {props.giftCard ? (
          <Grid container wrap="nowrap" alignItems="center">
            <RedeemIcon />
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              justify="space-between"
              className={classes.giftCard}
            >
              <Typography variant="body2" color="textPrimary">
                {props.giftCard.card_reference}
              </Typography>
              <IconButton
                color="secondary"
                aria-label="Remove gift card"
                component="span"
                size="small"
                disableRipple
                className={classes.removeGiftCardBtn}
                onClick={() => dispatch(setGiftCard(null))}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="row" wrap="nowrap" alignItems="center">
            <TextField
              inputRef={giftCardFieldRef}
              type="text"
              variant="outlined"
              name="giftcard"
              className={classes.giftCardField}
              inputProps={{ className: classes.giftCardFieldInput }}
              placeholder="Gift card number"
              margin="none"
            />
            {loadingGiftCard ? (
              <LoadingSpinner loading={loadingGiftCard} size={20} />
            ) : (
              <Button
                variant="contained"
                type="submit"
                color="primary"
                onClick={applyGiftCard}
              >
                Apply
              </Button>
            )}
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <Grid className={props.className}>
      <Paper elevation={0} className={classes.bookingSummaryCard}>
        <Grid container className={classes.bookingSummary}>
          <Box mb={1.5}>
            <Typography variant="overline">Summary</Typography>
          </Box>
          <Typography variant="subtitle1" color="textPrimary" gutterBottom>
            {props.selectedProperty.name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {guestCount(availCheckParams)} Guests
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {formatDateShort(arrival)} - {formatDateShort(departure)} (
            {nightCount(availCheckParams)} Nights)
          </Typography>
          <Box mt={2}>
            <Divider />
          </Box>
          <Box>
            {roomReservations.map((reservation, reservationIdx) => (
              <Grid key={`reservation-${reservationIdx}`} container>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  className={classes.roomHeader}
                >
                  {/*}
                  <Typography variant="subtitle2" color="textPrimary">
                    {`Room ${reservationIdx + 1}`}
                  </Typography>
                  {props.canRemoveReservations ? (
                    <Button
                      variant="outlined"
                      size="small"
                      value={reservationIdx}
                      onClick={removeRoomReservation}
                    >
                      Remove
                    </Button>
                  ) : null}
                  */}
                </Grid>
                <Grid container className={classes.section}>
                  <Grid item className={classes.summaryItemAttribute}>
                    <Accordion elevation={0} defaultExpanded={true}>
                      <AccordionSummary
                        className={classes.accordionSummary}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography variant="body2" color="textPrimary">
                          Room
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionDetails}>
                        <Grid container justify="space-between">
                          <Typography variant="body2" color="textSecondary">
                            {formatQuantity(
                              reservation.timeSlices.length,
                              "night"
                            )}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {formatCurrency(
                              reservation.totalGrossAmount.currency,
                              reservation.totalGrossAmount.amount
                            )}
                          </Typography>
                        </Grid>
                        <Box marginY={0.5}>
                          <Grid>
                            <Typography variant="body2" color="textSecondary">
                              {reservation.unitGroup.name}{" "}
                              {reservation.roomName &&
                                `(${reservation.roomName})`}
                            </Typography>
                          </Grid>
                        </Box>
                        <Box marginY={0.5}>
                          <Typography variant="body2" color="textSecondary">
                            {guestsStringForReservation(reservationIdx)}
                          </Typography>
                        </Box>
                        {has(["ratePlan", "description"], reservation) && (
                          <Box marginY={0.5}>
                            <Typography variant="body2" color="textSecondary">
                              {reservation.ratePlan.description}
                            </Typography>
                          </Box>
                        )}
                      </AccordionDetails>
                    </Accordion>
                    <DiningSelections diningSelections={diningSelections} />
                    <EntertainmentSelections
                      entertainmentSelections={entertainmentSelections}
                    />
                    <Services
                      services={reservation.services}
                      reservationIdx={reservationIdx}
                      quantityDropdownForService={quantityDropdownForService}
                      preventServiceRemoval={props.preventServiceRemoval}
                    />
                    <Divider />
                  </Grid>
                </Grid>
              </Grid>
            ))}

            {parkingReservations.map((reservation, reservationIdx) => (
              <Grid key={`parking-reservation-${reservationIdx}`} container>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  className={classes.roomHeader}
                >
                  <Typography variant="subtitle2" color="textPrimary">
                    {`Parking Reservation ${reservationIdx + 1}`}
                  </Typography>
                  {props.canRemoveReservations ? (
                    <Button
                      variant="outlined"
                      size="small"
                      value={reservationIdx}
                      onClick={removeParkingReservation}
                    >
                      Remove
                    </Button>
                  ) : null}
                </Grid>
                <Grid container>
                  <Grid item className={classes.summaryItemAttribute}>
                    <Grid container justify="space-between">
                      <Typography variant="body2" color="textSecondary">
                        1 space
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {formatCurrency(
                          reservation.totalGrossAmount.currency,
                          reservation.totalGrossAmount.amount
                        )}
                      </Typography>
                    </Grid>
                    <Box marginY={1}>
                      <Grid>
                        <Typography variant="body2" color="textSecondary">
                          {reservation.unitGroup.name}
                        </Typography>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Box>

          {props.showGiftCard ? giftCardField() : null}

          <Box mt={2}>
            <Grid container direction="column">
              <Grid container justify="space-between">
                <Typography variant="body2" color="textSecondary">
                  Booking cost
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {formatCurrency("GBP", props.bookingTotal)}
                </Typography>
              </Grid>

              {props.giftCard ? (
                <Grid container justify="space-between">
                  <Typography variant="body2">Gift Card</Typography>
                  <Typography variant="body2">
                    {formatCurrency("GBP", -1 * props.giftCardValue)}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Box>

          <Box my={2}>
            <Divider />
          </Box>

          <Grid container justify="space-between">
            <Grid item>
              <Grid>
                <Typography variant="subtitle1">Total to pay:</Typography>
              </Grid>
              {props.bookingTotalVat > 0 ? (
                <Grid>
                  <Typography variant="caption" color="textSecondary">
                    Including VAT of{" "}
                    {formatCurrency("GBP", props.bookingTotalVat)}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
            <Typography variant="subtitle1">
              {formatCurrency("GBP", props.bookingTotal - props.giftCardValue)}
            </Typography>
          </Grid>

          <Box my={2}>
            <Divider />
          </Box>
        </Grid>
      </Paper>
    </Grid>
  );
};

Basket.propTypes = {
  className: PropTypes.string,
  roomReservations: PropTypes.array,
  parkingReservations: PropTypes.array,
  giftCard: PropTypes.object,
  selectedProperty: PropTypes.object,
  bookingTotal: PropTypes.number,
  bookingTotalVat: PropTypes.number,
  giftCardValue: PropTypes.number,
  canRemoveReservations: PropTypes.bool,
  onReservationRemoved: PropTypes.func,
  onServiceRemoved: PropTypes.func,
  showGiftCard: PropTypes.bool,
  onNotifOpen: PropTypes.func,
  preventServiceRemoval: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    giftCard: state.booking.giftCard,
    selectedProperty: getSelectedProperty(state),
    bookingTotal: calculateBookingTotal([
      ...state.booking.roomReservations,
      ...state.booking.parkingReservations,
    ]),
    bookingTotalVat: calculateBookingTotalVat([
      ...state.booking.roomReservations,
      ...state.booking.parkingReservations,
    ]),
    giftCardValue: calculateGiftCardRedemption(
      [...state.booking.roomReservations, ...state.booking.parkingReservations],
      state.booking.giftCard
    ),
  };
};

const ConnectedBasket = connect(mapStateToProps)(Basket);
export default ConnectedBasket;
