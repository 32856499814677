import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    type: "light",
    background: {
      default: "#FFFFFF",
    },
    primary: {
      main: "#3F7D91",
      light: "#71ACC1",
      dark: "#015163",
      contrastText: "#FFFFFF",
      background: "rgba(85, 156, 182, 0.08)",
      border: "rgba(85, 156, 182, 0.5)",
    },
    secondary: {
      main: "#D7225E",
      light: "#FF5F8B",
      dark: "#9F0035",
      contrastText: "#FFFFFF",
      background: "rgba(215, 34, 94, 0.08)",
      border: "rgba(215, 34, 94, 0.5)",
    },
    action: {
      main: "#559CB6",
      contrastText: "#FFFFFF",
      hover: "rgba(18, 32, 36, 0.04)",
      selected: "rgba(18, 32, 36, 0.08)",
      disabledBackground: "rgba(18, 32, 36, 0.12)",
      disabledText: "rgba(0, 0, 0, 0.26)",
    },
    info: {
      main: "#29599E",
      dark: "#00316F",
      light: "#6085D0",
      contrastText: "#FFFFFF",
      darkText: "#092441",
      lightBg: "#E9EEF6",
    },
    success: {
      main: "#008A40",
      dark: "#005A16",
      light: "#4CBA6C",
      contrastText: "#FFFFFF",
      darkText: "#003816",
      lightBg: "#E2F4EB",
    },
    error: {
      main: "#E32438",
      dark: "#A90012",
      light: "#FF6162",
      contrastText: "#FFFFFF",
      darkText: "#640113",
      lightBg: "#FFE8EB",
    },
    warning: {
      main: "#EFAE23",
      dark: "#B87F00",
      light: "#FFE05A",
      contrastText: "rgba(0, 0, 0, 0.87)",
      darkText: "#6C3600",
      lightBg: "#FFF3E8",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "#6F7072",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    grey: {
      50: "#F4F8FA",
      100: "#E8F2F5",
      200: "#DDEBF0",
      300: "#BCD8E1",
      400: "#8DBDCB",
      500: "#589EB2",
      600: "#568796",
      700: "#54707A",
      800: "#384B51",
      900: "#1C2529",
      a100: "#FAFAFA",
      a200: "#B7BCBE",
      a400: "#58595B",
      a700: "#373037",
    },

    // other.divider in designs but putting here as thats where mui reads from
    divider: "rgba(0, 0, 0, 0.12)",

    other: {
      border: "rgba(18, 32, 36, 0.23)",
      backdrop: "rgba(18, 32, 36, 0.5)",
      snackbar: "#589EB2",
    },

    warner: {
      warnerBlue: "#559CB6",
      orange: "#E3672A",
      purple: "#3E338D",
      gold: "#A78C5B",
      red: "#910A18",
      countryGreen: "#5EC094",
      countryGreenLight: "#ADD9B1",
      costalBlue: "#00AAAD",
      costalBlueLight: "#95D6D8",
    },
  },
  typography: {
    fontFamily: `"Open Sans", sans-serif`,
    h1: {
      fontFamily: "Georgia, serif",
      fontSize: "96px",
      lineHeight: "112px",
      letterSpacing: "-1.5px",
      fontWeight: 400,
    },
    h2: {
      fontFamily: "Georgia, serif",
      fontSize: "60px",
      lineHeight: "72px",
      letterSpacing: "-0.5px",
      fontWeight: 400,
    },
    h3: {
      fontFamily: "Georgia, serif",
      fontSize: "48px",
      lineHeight: "56px",
      letterSpacing: "0px",
      fontWeight: 400,
    },
    h4: {
      fontFamily: "Georgia, serif",
      fontSize: "34px",
      lineHeight: "42px",
      letterSpacing: "0.25px",
      fontWeight: 400,
    },
    h5: {
      fontFamily: "Georgia, serif",
      fontSize: "24px",
      lineHeight: "32px",
      letterSpacing: "0px",
      fontWeight: 400,
    },
    h6: {
      fontFamily: "Georgia, serif",
      fontSize: "20px",
      lineHeight: "32px",
      letterSpacing: "0.15px",
      fontWeight: 400,
    },
    subtitle1: {
      fontFamily: `"Open Sans", sans-serif`,
      fontSize: "16px",
      lineHeight: "28px",
      letterSpacing: "0.15px",
      fontWeight: 600,
    },
    subtitle2: {
      fontFamily: `"Open Sans", sans-serif`,
      fontSize: "14px",
      lineHeight: "22px",
      letterSpacing: "0.1px",
      fontWeight: 600,
    },
    body1: {
      fontFamily: `"Open Sans", sans-serif`,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      textTransform: "none",
      fontWeight: 400,
    },
    body2: {
      fontFamily: `"Open Sans", sans-serif`,
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.15px",
      height: "max-content",
      fontWeight: 400,
    },
    button: {
      fontFamily: `"Open Sans", sans-serif`,
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0.4px",
      textTransform: "uppercase",
      fontWeight: 600,
    },
    caption: {
      fontFamily: `"Open Sans", sans-serif`,
      fontSize: "12px",
      lineHeight: "20px",
      letterSpacing: "0.4px",
      fontWeight: 400,
    },
    overline: {
      fontFamily: `"Open Sans", sans-serif`,
      fontSize: "12px",
      lineHeight: "32px",
      letterSpacing: "1px",
      textTransform: "uppercase",
      fontWeight: 400,
    },
    overlineBold: {
      fontFamily: `"Open Sans", sans-serif`,
      fontSize: "12px",
      lineHeight: "32px",
      letterSpacing: "1px",
      textTransform: "uppercase",
      fontWeight: 700,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 114,
    },
  },
  breakpoints: {
    values: {
      tablet: 770,
      desktop: 1110,
    },
  },
  contentSize: {
    pageWidth: "80%",
    maxPageWidth: "1300px",
    mobilePageWidth: "90%",
  },
  shape: {
    borderRadius: "4px",
  },
});

theme.overrides.MuiPaper = {
  elevation1: {
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 10px rgba(0, 0, 0, 0.01)",
    border: "none",
  },
};

theme.overrides.MuiButton = {
  root: {
    borderRadius: "6px",
  },
};

theme.overrides.MuiOutlinedInput = {
  root: {
    backgroundColor: "#FFFFFF",
    "& $notchedOutline": {
      borderColor: theme.palette.other.border,
    },
    "&:hover $notchedOutline": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
};

theme.overrides.MuiFormControl = {
  root: {
    minWidth: "80px",
  },
};

theme.overrides.MuiStepper = {
  root: {
    padding: "0px 24px 0px 0px",
  },
};

theme.overrides.MuiStepContent = {
  root: {
    borderLeft: "none",
    paddingLeft: "0px",
    marginRight: "96px",
  },
};

theme.overrides.MuiStepConnector = {
  line: {
    borderColor: theme.palette.divider,
  },
};

theme.overrides.MuiLink = {
  underlineHover: {
    // All links should be underlined
    textDecoration: "underline",
  },
};

theme.overrides.MuiAccordion = {
  root: {
    "&$expanded": {
      margin: `${theme.spacing(2)}px 0px 16px 0px`,

      "&:last-child": {
        marginBottom: theme.spacing(2),
      },
    },
  },
};

theme.overrides.MuiAccordionSummary = {
  root: {
    "&$expanded": {
      minHeight: "fit-content",
    },
  },
  expanded: {
    minHeight: "fit-content",
  },
  content: {
    margin: `${theme.spacing(1)}px 0px`,
    "&$expanded": {
      margin: "0px",
    },
  },
};

export default theme;
