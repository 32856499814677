import React, { useEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Alert } from "@material-ui/lab";
import { SnackbarProvider, useSnackbar } from "notistack";
import { createBrowserHistory } from "history";

import "./App.css";
import CookieConsent from "components/CookieConsent";
import PlanStay from "components/PlanStay";
import ChooseRoom from "components/ChooseRoom";
import ChooseRoomNumber from "components/ChooseRoomNumber";
import SelectDiningTimes from "components/SelectDiningTimes";
import SelectEntertainment from "components/SelectEntertainment";
import SelectAddOns from "components/SelectAddOns";
import Checkout from "components/Checkout";
import Summary from "components/Summary";
import Error from "components/Error";
import ErrorBoundary from "components/ErrorBoundary";
// import AuthPage from "components/AuthPage";
// import ManageBooking from "components/ManageBooking";
// import Account from "components/Account";
import { fetchProperties } from "features/property/propertySlice";
import { setMobileAppUser } from "features/user/userSlice";
import theme from "theme";
import {
  PLAN_STAY,
  CHOOSE_ROOM,
  CHOOSE_ROOM_NUMBER,
  SELECT_DINING_TIMES,
  SELECT_ENTERTAINMENT,
  SELECT_ADDONS,
  CHECKOUT,
  SUMMARY,
} from "config/routes";

import { initFirebase } from "utils/firebase/firebase/";
import { useSignInWithFirebaseToken } from "hooks";
import {
  giveCookieConsent,
  setAnalyticsSource,
  trackError,
  trackPageView,
} from "utils/analytics";

library.add(fas, far, fab);

const history = createBrowserHistory();

const App = () => {
  const dispatch = useDispatch();

  /* BEGIN NOTISTACK CUSTOMIZATION */
  const { enqueueSnackbar } = useSnackbar();
  const closeOnClick = (event) => {
    event.target.closest(".MuiCollapse-container").remove();
  };
  const handleNotifOpen = (message, options = {}) => {
    options.TransitionProps = { direction: "up" };
    options.anchorOrigin = { horizontal: "center", vertical: "bottom" };
    options.autoHideDuration = 4000;
    options.content = (
      <Alert onClose={closeOnClick} severity={options.variant}>
        {message}
      </Alert>
    );

    enqueueSnackbar(message, options);

    if (options.variant === "error") {
      trackError(message, true);
    }
  };
  /* END NOTISTACK CUSTOMIZATION */

  useEffect(() => {
    dispatch(fetchProperties());
  }, []);

  useEffect(() => {
    // Track app users
    const searchParams = queryString.parse(history.location.search);
    if (searchParams.source) {
      setAnalyticsSource(searchParams.source);
    }

    dispatch(setMobileAppUser(searchParams.source === "mobileapp"));

    // Track the first page view, then listen for history changes
    trackPageView(history.location.pathname);
    history.listen((location) => {
      trackPageView(location.pathname);
    });
  }, [history]);

  // const redirectHome = () => {
  //   history.push(PLAN_STAY);
  // };
  // const redirectAuth = () => {
  //   history.push("/login");
  // };

  // Setup firebase (auth and analytics)
  initFirebase();

  // Re-authenticate the user if needed. If there's an auth token
  // in the redux store, grab it and try to reauthenticate with it
  const authToken = useSelector((state) => state.user.firebaseToken);
  useSignInWithFirebaseToken(authToken);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router history={history}>
        <div>
          <ErrorBoundary
            fallbackUI={(_hasReactError) => (
              <PlanStay onNotifOpen={handleNotifOpen} />
            )}
          >
            <Switch>
              <Route exact path="/">
                <Redirect to="/plan-stay" />
              </Route>
              <Route
                path={PLAN_STAY}
                render={(props) => (
                  <PlanStay {...props} onNotifOpen={handleNotifOpen} />
                )}
              />
              <Route
                path={CHOOSE_ROOM}
                render={(props) => (
                  <ChooseRoom {...props} onNotifOpen={handleNotifOpen} />
                )}
              />
              <Route
                path={CHOOSE_ROOM_NUMBER}
                render={(props) => (
                  <ChooseRoomNumber {...props} onNotifOpen={handleNotifOpen} />
                )}
              />
              <Route
                path={SELECT_DINING_TIMES}
                render={(props) => (
                  <SelectDiningTimes {...props} onNotifOpen={handleNotifOpen} />
                )}
              />
              <Route
                path={SELECT_ENTERTAINMENT}
                render={(props) => (
                  <SelectEntertainment
                    {...props}
                    onNotifOpen={handleNotifOpen}
                  />
                )}
              />
              <Route
                path={SELECT_ADDONS}
                render={(props) => (
                  <SelectAddOns {...props} onNotifOpen={handleNotifOpen} />
                )}
              />
              <Route
                path={CHECKOUT}
                render={(props) => (
                  <Checkout {...props} onNotifOpen={handleNotifOpen} />
                )}
              />
              <Route
                path={SUMMARY}
                render={(props) => (
                  <Summary {...props} onNotifOpen={handleNotifOpen} />
                )}
              />
              {/*
              <Route
                path="/account/bookings"
                render={(props) => (
                  <Account
                    {...props}
                    onNotifOpen={handleNotifOpen}
                    redirectAuth={redirectAuth}
                    showDetails={false}
                  />
                )}
              />
              <Route
                path="/account"
                render={(props) => (
                  <Account
                    {...props}
                    onNotifOpen={handleNotifOpen}
                    redirectAuth={redirectAuth}
                    showDetails={true}
                  />
                )}
              />
              <Route
                path="/login"
                render={(props) => (
                  <AuthPage
                    {...props}
                    login
                    onNotifOpen={handleNotifOpen}
                    authCompleted={redirectHome}
                  />
                )}
              />
              <Route
                path="/signup"
                render={(props) => (
                  <AuthPage
                    {...props}
                    signUp
                    onNotifOpen={handleNotifOpen}
                    authCompleted={redirectHome}
                  />
                )}
              />
              <Route
                path="/manage-booking"
                render={(props) => (
                  <ManageBooking {...props} onNotifOpen={handleNotifOpen} />
                )}
              />
              */}
              <Route component={Error} />
            </Switch>
          </ErrorBoundary>
        </div>
      </Router>
      <CookieConsent onAgree={giveCookieConsent} />
    </ThemeProvider>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={1}>
      <App />
    </SnackbarProvider>
  );
}
