import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  Button,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  roomFiltersContainer: {
    borderRadius: "5px",
    overflow: "hidden",
    width: "100%",
    marginBottom: "20px",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      marginRight: "20px",
      width: "200px",
      flexShrink: 0,
    },
  },
  roomFiltersContent: {
    padding: "16px",
    width: "100%",
  },
  filterFieldset: {
    width: "100%",
  },
  filterFieldsetBorder: {
    marginTop: "12px",
    paddingTop: "12px",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  filterHeading: {
    paddingBottom: theme.spacing(1),
  },
  radioOptionFormControl: {
    marginLeft: "-6px",
  },
  radioOptionRoot: {
    padding: "4px 8px 4px 4px",
  },
  clearButton: {
    marginTop: theme.spacing(2),
  },
}));

// AJD - This is hard-coded for now
const filtersList = [
  {
    heading: "FLOOR",
    key: "floor",
    values: [
      {
        label: "Lower Ground",
        value: "Floor LG",
      },
      {
        label: "Ground",
        value: "Floor 0",
      },
      {
        label: "First Floor",
        value: "Floor 1",
      },
      {
        label: "Second Floor",
        value: "Floor 2",
      },
      {
        label: "Third Floor",
        value: "Floor 3",
      },
    ],
  },
  {
    heading: "VIEW",
    key: "view",
    values: [
      {
        label: "Sea View",
        value: "V-Sea",
      },
      {
        label: "Open Country View",
        value: "V-Open",
      },
      {
        label: "Garden View",
        value: "V-Garden",
      },
      {
        label: "Street View",
        value: "V-Street",
      },
    ],
  },
  {
    heading: "AMENITY",
    key: "amenity",
    values: [
      {
        label: "Patio",
        value: "Pat-B~Pat-S~Pat-G",
      },
      {
        label: "Balcony",
        value: "Bal-B~Bal-S~Bal-G",
      },
    ],
  },
  {
    heading: "BATHROOM",
    key: "bathroom",
    values: [
      {
        label: "Bath",
        value: "B-B",
      },
      {
        label: "Bath & Shower",
        value: "B-BS",
      },
      {
        label: "Shower Only",
        value: "B-S",
      },
    ],
  },
  {
    heading: "STYLE",
    key: "style",
    values: [
      {
        label: "Historic",
        value: "St-H",
      },
      {
        label: "Contemporary",
        value: "St-C",
      },
      {
        label: "Traditional",
        value: "St-T",
      },
    ],
  },
];

const ChooseRoomFilters = (props) => {
  const { currentFilters, setFilters } = props;
  const classes = useStyles();

  return (
    <Grid className={classes.roomFiltersContainer}>
      <Paper className={classes.roomFiltersContent}>
        {filtersList.map((filter, index) => {
          return (
            <FormControl
              component="fieldset"
              key={`filter-${filter.key}`}
              className={`${classes.filterFieldset}${
                index > 0 ? ` ${classes.filterFieldsetBorder}` : ""
              }`}
            >
              <FormLabel className={classes.filterHeading}>
                <Typography variant="overline" color="textPrimary">
                  {filter.heading}
                </Typography>
              </FormLabel>
              <RadioGroup
                aria-label={filter.heading}
                name={`${filter.key}-filter`}
                value={currentFilters[filter.key] || ""}
                onChange={(event) => {
                  const newFilters = {
                    ...currentFilters,
                    [filter.key]: event.target.value,
                  };
                  setFilters(newFilters);
                }}
              >
                {filter.values.map((filterValue) => {
                  return (
                    <FormControlLabel
                      key={`filter-${filter.key}-radio-${filterValue.value}`}
                      value={filterValue.value}
                      control={
                        <Radio
                          size="small"
                          classes={{ root: classes.radioOptionRoot }}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          {filterValue.label}
                        </Typography>
                      }
                      className={classes.radioOptionFormControl}
                    />
                  );
                })}
              </RadioGroup>
              {!!currentFilters[filter.key] && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    const newFilters = {
                      ...currentFilters,
                    };
                    delete newFilters[filter.key];
                    setFilters(newFilters);
                  }}
                  className={classes.clearButton}
                >
                  Clear
                </Button>
              )}
            </FormControl>
          );
        })}
      </Paper>
    </Grid>
  );
};

ChooseRoomFilters.propTypes = {
  currentFilters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default ChooseRoomFilters;
