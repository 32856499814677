import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Paper, Typography, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  card: {
    margin: "auto",
  },
  cardContent: {
    flexWrap: "nowrap",
    flexDirection: "column",
    [theme.breakpoints.up("desktop")]: {
      flexDirection: "row",
    },
  },
  imgContainer: {
    height: "auto",
    position: "relative",
    overflow: "hidden",
    borderRadius: theme.shape.borderRadius,
  },
  img: {
    maxWidth: "100%",
    maxHeight: "250px",
    height: "auto",
    width: "100%",
    objectFit: "cover",
    objectPosition: "50% 100%",
    borderRadius: theme.shape.borderRadius,
    top: 0,
    bottom: 0,
  },
  roomDescription: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
  },
  actionsRow: {
    marginBottom: "20px",
  },
  moreDetails: {
    padding: "4px 0px",
  },
}));

const RoomUpgrade = (props) => {
  const classes = useStyles();

  const [upgradeRoom, setUpgradeRoom] = useState(null);

  useEffect(() => {
    // Remove selected room from all rooms, then choose last in list
    const room = props.rooms
      .filter((r) => r.pms_id !== props.selectedRoomId)
      .slice(-1)[0];

    setUpgradeRoom(room);
  }, [props.rooms, props.selectedRoomId]);

  return upgradeRoom ? (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.card}>
        <Typography gutterBottom variant={"body1"}>
          Upgrade to a {upgradeRoom.name} for just £20 more a night.
        </Typography>
        {upgradeRoom.hero_image ? (
          <Box mt={2} mb={2}>
            <Grid item className={classes.imgContainer}>
              <img
                className={classes.img}
                alt="room"
                src={upgradeRoom.hero_image.url}
              />
            </Grid>
          </Box>
        ) : null}
        <Grid container spacing={2} className={classes.cardContent}>
          <Grid item>
            <Typography
              gutterBottom
              variant={"caption"}
              className={classes.roomRescription}
            >
              {upgradeRoom.description}
            </Typography>
          </Grid>
        </Grid>
        <Box mt={3} mb={2}>
          <Button variant="contained" color="primary">
            Upgrade Room
          </Button>
        </Box>
      </Paper>
    </div>
  ) : null;
};

RoomUpgrade.propTypes = {
  rooms: PropTypes.arrayOf(PropTypes.object),
  selectedRoomId: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    rooms: state.room.roomDetails,
    selectedRoomId:
      state.booking.roomReservations.length > 0
        ? state.booking.roomReservations[0].unitGroup.code
        : null,
  };
};

const mapDispatchToProps = {};

const ConnectedRoomUpgrade = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoomUpgrade);
export default ConnectedRoomUpgrade;
