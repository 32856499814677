import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Box, Grid, Toolbar, Typography } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";

import warnerLogo from "images/warner_logo.png";

const useStyles = makeStyles((theme) => ({
  logoAppBar: {
    position: "static",
    backgroundColor: theme.palette.common.white,
    padding: "0 !important", // Material UI bug see https://github.com/mui-org/material-ui/issues/17353
    boxShadow: "none",
  },
  logoToolbar: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0px auto",
    width: "80%",
    maxWidth: theme.contentSize.maxPageWidth,
    minHeight: "68px",
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      minHeight: "auto",
      paddingTop: "1rem",
      paddingBottom: "1rem",
      width: "100%",
    },
  },
  logoContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  navbarLogo: {
    width: "156px",
    [theme.breakpoints.up("tablet")]: {
      width: "250px",
    },
  },
  phoneContainer: {
    width: "auto",
  },
}));

const Navbar = (props) => {
  const classes = useStyles();

  return (
    <AppBar className={classes.logoAppBar}>
      <Toolbar className={classes.logoToolbar}>
        <Link className={classes.logoContainer} to="/">
          <img
            className={classes.navbarLogo}
            src={warnerLogo}
            alt="Warner Logo"
          />
        </Link>
        <Grid container className={classes.phoneContainer}>
          <Box mr={1}>
            <PhoneIcon color="primary" />
          </Box>
          <Typography variant="body1" color="primary">
            0330 100 9774
          </Typography>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

Navbar.propTypes = {
  centerLogo: PropTypes.bool,
  withProfile: PropTypes.bool,
  onNotifOpen: PropTypes.func,
};

export default Navbar;
