import {
  compose,
  filter,
  map,
  mapKeys,
  matchesProperty,
  merge,
  pick,
  reduce,
  getOr,
  concat,
  values,
  groupBy,
  update,
  entries,
} from "lodash/fp";

const keyMap = {
  time_iso: "time",
};

const getSanitisedTime = compose(
  mapKeys((key) => (keyMap[key] ? keyMap[key] : key)),
  pick("time_iso")
);

const getPeriod = (name) => (label) =>
  compose(
    map(
      compose(
        merge({ name }),
        update(["times"], map(getSanitisedTime)),
        pick("times")
      )
    ),
    filter(matchesProperty("shift_category", label))
  );

const getBreakfastTimes = getOr([], "breakfastTimes");
const getDinnerTimes = getOr([], "dinnerTimes");

const mergePeriods = compose(
  map(
    reduce(
      (acc, value) => ({
        ...merge(acc, value),
        breakfastTimes: concat(
          getBreakfastTimes(acc),
          getBreakfastTimes(value)
        ),
        dinnerTimes: concat(getDinnerTimes(acc), getDinnerTimes(value)),
      }),
      {}
    )
  ),
  groupBy("date")
);

export const transformAPIAvailabilities = compose(
  values,
  mergePeriods,
  map(([index, a]) => {
    const uniqueName = `${a.venue_id}:${index}`; // a temporary hack until venue ids are unique
    return {
      ...pick(["date"], a),
      breakfastTimes: getPeriod(uniqueName)("BREAKFAST")(a.availability),
      dinnerTimes: getPeriod(uniqueName)("DINNER")(a.availability),
    };
  }),
  entries
);
