import { values, first, compose, map, keys, flatten } from "lodash/fp";
import { formatInTimezone } from "../../utils/timekeep";

export const diningSelectionsToItinerary = (selections, guestCount) =>
  compose(
    flatten,
    map((key) =>
      compose(
        map((period) => {
          const diningSelection = selections[key][period];
          return {
            attendeesCount: guestCount,
            date: key,
            eventName: compose(first, keys)(diningSelection),
            time: compose(first, values)(diningSelection),
            title: period,
          };
        }),
        keys
      )(selections[key])
    ),
    keys
  )(selections);

// AJD: TODO - Could refactor these next two functions into one, just need a bit more consistency in the shape of the state
export const entertainmentSelectionsToItinerary = (
  selections,
  guestCount,
  timezone
) => {
  const entertainment = [];

  for (const date in selections) {
    const event = selections[date];
    entertainment.push({
      attendeesCount: guestCount,
      date,
      eventName: event.title,
      time: formatInTimezone(event.date, timezone, "HH:mm"),
      title: "Entertainment",
    });
  }

  return entertainment;
};

export const afternoonTeaSelectionsToItinerary = (selections, guestCount) => {
  const reservations = [];

  for (const date in selections) {
    const reservation = selections[date];
    reservations.push({
      attendeesCount: guestCount,
      date,
      eventName: "Afternoon Tea",
      time: reservation.time,
      title: "Enhancements",
    });
  }

  return reservations;
};
