import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import add from "date-fns/add";

// Material UI
import { Button, Link, Typography, makeStyles } from "@material-ui/core";

// Utils
import { getDefaultCheckInDate } from "utils/timekeep";

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: "pointer",
  },
  image: {
    width: "100%",
    height: "340px",
    objectFit: "cover",
  },
  bookButton: {
    marginTop: theme.spacing(0.5),
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const ExclusiveBreak = (props) => {
  const classes = useStyles();
  const { event, breakLength = 3, onBook } = props;

  const calculateCheckInDate = () => {
    // For the demo, we will just take the closest valid check in time to the event date
    const eventDate = moment(event.start_time);

    // As this is hardcoded to a 3-night stay minimum, we can use the event date to calculate an appropriate check-in date.
    // The check in date must be a Monday, Wednesday or Friday.
    // Therefore, the earliest you can check in is 2 days before the event.
    const earliestCheckInDate = moment
      .max(eventDate.subtract(2, "days"), moment())
      .toDate();

    return getDefaultCheckInDate(earliestCheckInDate);
  };

  const formattedCheckInDate = () => {
    return moment(calculateCheckInDate()).format("D MMMM");
  };

  const handleBook = () => {
    const checkInDate = calculateCheckInDate();
    const checkOutDate = add(checkInDate, { days: breakLength });

    onBook(checkInDate, checkOutDate);
  };

  return (
    <div>
      <Link className={classes.link} underline="none" onClick={handleBook}>
        <img src={event.image?.url} className={classes.image} />
        <Typography variant="overline" color="textSecondary">
          {`${formattedCheckInDate()} - ${breakLength} nights`}
        </Typography>
        <Typography variant="h6" color="textPrimary">
          {event.title}
        </Typography>
        <Typography
          variant="body2"
          color="textPrimary"
          className={classes.description}
        >
          {event.description}
        </Typography>
        {/* On click is handled by surrounding link */}
        <Button color="secondary" className={classes.bookButton}>
          Book Now
        </Button>
      </Link>
    </div>
  );
};

ExclusiveBreak.propTypes = {
  event: PropTypes.object.isRequired,
  breakLength: PropTypes.number.isRequired,
  onBook: PropTypes.func,
};

export default ExclusiveBreak;
