import React from "react";
import PropTypes from "prop-types";

import "react-phone-number-input/style.css";
import "./PhoneInput.css";
import PhoneInput from "react-phone-number-input";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

// Styled phone input field

const useStyles = makeStyles((theme) => ({
  headingLabel: {
    color: theme.palette.text.primary,
  },
  inputError: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.error.main,
  },
  errorHelperLabel: {
    color: theme.palette.error.main,
    marginLeft: "14px",
    marginRight: "14px",
  },
}));

const StyledPhoneInput = (props) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="caption" className={classes.headingLabel}>
        Phone Number *
      </Typography>
      <PhoneInput
        defaultCountry={props.defaultCountry}
        placeholder={props.placeholder}
        countryOptionsOrder={props.countryOptionsOrder}
        value={props.value}
        onChange={props.onChange}
        error={props.error}
        numberInputProps={{
          className: props.error ? classes.inputError : null,
          class: "MuiInputBase-root PhoneInputInput",
        }}
      />
      {props.error ? (
        <Typography variant="caption" className={classes.errorHelperLabel}>
          {props.errorHelperText}
        </Typography>
      ) : null}
    </>
  );
};

StyledPhoneInput.propTypes = {
  defaultCountry: PropTypes.string,
  placeholder: PropTypes.string,
  countryOptionsOrder: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  errorHelperText: PropTypes.string,
};

export default StyledPhoneInput;
