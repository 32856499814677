import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { IconButton } from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "1px 0px",
  },
  primaryIcon: {
    // Circular
    border: `1px solid ${theme.palette.primary.light}`,
    color: theme.palette.primary.main,
    width: "30px",
    height: "30px",
    borderRadius: "15px",
    padding: "4px",
  },
  secondaryIcon: {
    // Square
    border: `1px solid ${theme.palette.action.main}`,
    color: theme.palette.text.secondary,
    width: "26px",
    height: "26px",
    borderRadius: "4px",
    padding: "4px",
  },
  disabled: {
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.disabled,
  },
}));

const StepperButton = (props) => {
  const classes = useStyles();

  return (
    <IconButton
      name={props.name}
      aria-label={props["aria-label"]}
      onClick={props.onClick}
      disabled={props.disabled}
      size="small"
      className={classes.button}
    >
      {props.add ? (
        <AddIcon
          className={classNames(
            props.disabled ? classes.disabled : null,
            props.variant === "secondary"
              ? classes.secondaryIcon
              : classes.primaryIcon
          )}
        />
      ) : null}

      {props.remove ? (
        <RemoveIcon
          className={classNames(
            props.variant === "secondary"
              ? classes.secondaryIcon
              : classes.primaryIcon,
            props.disabled ? classes.disabled : null
          )}
        />
      ) : null}
    </IconButton>
  );
};

StepperButton.propTypes = {
  name: PropTypes.string.isRequired,
  "aria-label": PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  add: PropTypes.bool,
  remove: PropTypes.bool,
  variant: PropTypes.oneOf(["primary", "secondary"]),
};

export default StepperButton;
