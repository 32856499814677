import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Tab, Tabs, Typography, Button } from "@material-ui/core";
import { Done } from "@material-ui/icons";

import { formatInTimezone } from "utils/timekeep";

const periodLabel = {
  padding: "0px 6px",
  borderRadius: "5px",
  textAlign: "center",
};

const minHeight = "2em";

const useStyles = makeStyles((theme) => ({
  reservationSelection: {
    minHeight: "86px",
    minWidth: "150px",
  },
  selections: {
    minWidth: 0,
    maxWidth: "260px",
  },
  confirmation: {
    minWidth: 0,
    maxWidth: "90px",
  },
  confirmationContent: {
    alignItems: "center",
    height: "100%",
    paddingLeft: "10px",
  },
  tick: {
    color: theme.palette.success.light,
  },
  gridItem: {
    maxWidth: "100%",
    paddingBottom: "2px",
  },
  tabs: {
    minHeight,
  },
  tab: {
    padding: "0 5px",
    width: "auto",
    minWidth: 0,
    minHeight,
    ...theme.typography.body2,
    textTransform: "none",
  },
  selectedTab: {
    ...periodLabel,
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  scrollButtons: {
    color: theme.palette.text.secondary,
    width: "auto",
    "&.Mui-disabled": {
      opacity: 0.3,
    },
  },
  tabsIndicator: {
    display: "none",
  },
}));

const ReservationSelection = (props) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(props.currentDate);
  const [selectedTime, setSelectedTime] = useState(props.currentTime);
  const [hasChanged, setHasChanged] = useState(false);

  return (
    <Grid container direction="row" className={classes.reservationSelection}>
      <Grid item className={classes.selections}>
        <Grid container direction="column">
          <Grid item className={classes.gridItem}>
            <Typography variant="subtitle2">Pre-book</Typography>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Tabs
              value={selectedDate}
              onChange={(_, value) => {
                if (value !== selectedDate) {
                  // Select the date
                  setSelectedDate(value);
                  // Also clear any selected time
                  setSelectedTime(undefined);
                  // Indicate a change has been made
                  setHasChanged(true);
                }
              }}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="select date"
              classes={{
                root: classes.tabs,
                scrollButtons: classes.scrollButtons,
                indicator: classes.tabsIndicator,
              }}
            >
              {Object.keys(props.availability).map((date, index) => {
                return (
                  <Tab
                    key={index}
                    classes={{
                      root: classes.tab,
                      selected: classes.selectedTab,
                    }}
                    value={date}
                    label={formatInTimezone(
                      new Date(date),
                      props.timezone,
                      "do MMM"
                    )}
                  />
                );
              })}
            </Tabs>
          </Grid>
          {
            // Only show the time once a date has been selected
            selectedDate && (
              <Grid item className={classes.gridItem}>
                <Tabs
                  value={selectedTime}
                  onChange={(_, value) => {
                    if (value !== selectedTime) {
                      // Select the time
                      setSelectedTime(value);
                      // Indicate a change has been made
                      setHasChanged(true);
                    }
                  }}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="select time"
                  classes={{
                    root: classes.tabs,
                    scrollButtons: classes.scrollButtons,
                    indicator: classes.tabsIndicator,
                  }}
                >
                  {props.availability[selectedDate].map((time, index) => {
                    return (
                      <Tab
                        key={index}
                        classes={{
                          root: classes.tab,
                          selected: classes.selectedTab,
                        }}
                        value={time}
                        label={time}
                      />
                    );
                  })}
                </Tabs>
              </Grid>
            )
          }
        </Grid>
      </Grid>
      <Grid item className={classes.confirmation}>
        <Grid container className={classes.confirmationContent}>
          {
            // Only show the confirm button if a date and time have been selected, and something has changed
            selectedDate && selectedTime && hasChanged && (
              <Button
                disabled={!hasChanged} // Disable the button unless something has been changed
                onClick={() => {
                  // Store the selection in redux
                  props.makeSelection(selectedDate, selectedTime);
                  // Indicate a change has not been made
                  setHasChanged(false);
                }}
                color="primary"
                variant="contained"
                size="small"
              >
                Confirm
              </Button>
            )
          }
          {
            // If a date and time have been selected, but nothing has changed, show a tick icon
            selectedDate && selectedTime && !hasChanged && (
              <Done className={classes.tick} />
            )
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

ReservationSelection.propTypes = {
  availability: PropTypes.object.isRequired,
  currentDate: PropTypes.string,
  currentTime: PropTypes.string,
  makeSelection: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default ReservationSelection;
