import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

import {
  formatDateMedium,
  formatInTimezone,
  defaultTimezone,
} from "utils/timekeep";
import { STEPS } from "utils/constants";
import { getSelectedProperty } from "selectors/selectedProperty";

const useStyles = makeStyles((theme) => ({
  calendarIcon: {
    marginRight: "8px",
    fontSize: "16px",
    color: theme.palette.text.secondary,
  },
  timeIcon: {
    fontSize: "12px",
  },
  accordionSummary: {
    padding: "0px",
  },
  accordionDetails: {
    display: "block",
    padding: "0px",
  },
  description: {
    textTransform: "capitalize",
  },
}));

const EntertainmentSelections = (props) => {
  const classes = useStyles();
  const entertainmentEntries = Object.entries(
    props.entertainmentSelections
  ).sort(([, a], [, b]) => a - b);

  const selectedProperty = useSelector(getSelectedProperty);
  const propertyTimezone = selectedProperty?.timezone || defaultTimezone;

  return (
    <Box>
      <Divider />
      <Accordion elevation={0} defaultExpanded={true}>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="body2" color="textPrimary">
            {STEPS.SELECT_ENTERTAINMENT.STEPPER_TITLE}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {entertainmentEntries.length > 0 ? (
            entertainmentEntries.map(([date, entertainmentObject], i) => {
              return (
                <Box mt={1} key={`date-${i}`}>
                  <Grid container>
                    <CalendarTodayIcon className={classes.calendarIcon} />
                    <Typography variant="caption" color="textSecondary">
                      {formatDateMedium(date)}
                    </Typography>
                  </Grid>
                  <Box key={`selection-${i}`}>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      className={classes.description}
                    >
                      {entertainmentObject.title}{" "}
                      <AccessTimeIcon className={classes.timeIcon} />{" "}
                      {formatInTimezone(
                        entertainmentObject.date,
                        propertyTimezone,
                        "HH:mm"
                      )}
                    </Typography>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Typography variant="caption" color="textSecondary">
              {" "}
              No entertainment selected{" "}
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

EntertainmentSelections.propTypes = {
  entertainmentSelections: PropTypes.object,
};

export default EntertainmentSelections;
