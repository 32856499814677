import { getCookieConsent } from "utils/consentCookie";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import { EVPARKING_UNITGROUP_CODE } from "utils/constants";

import {
  calculateBookingTotal,
  calculateBookingTotalVat,
} from "utils/bookingTotals";

export const initializeAnalytics = () => {
  // Init facebook pixel
  const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true,
    debug: false, // enable logs
  };
  ReactPixel.init(
    process.env.REACT_APP_FACEBOOK_PIXEL_ID,
    advancedMatching,
    options
  );

  // Revoke consent if we havent asked the user yet
  // For ga, it needs to be done before init.
  // For fb pixel, it needs to be done after init, so put it between.
  if (!getCookieConsent()) {
    revokeCookieConsent();
  }

  // Init google analytics
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
    debug: false,
  });
  ReactGA.plugin.require("ec");
};

export const giveCookieConsent = () => {
  // Allow consent for google analytics and fb pixel id
  ReactPixel.grantConsent();

  // Firebase analytics
  delete window[
    `ga-disable-G-${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`
  ];

  // Google analytics
  delete window[`ga-disable-${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`];

  // Track the first page view in GA
  trackPageView(window.location.pathname);
};

export const revokeCookieConsent = () => {
  // Firebase analytics
  window[
    `ga-disable-G-${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`
  ] = true;

  // Google analytics
  window[`ga-disable-${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`] = true;
  ReactPixel.revokeConsent();
};

export const setAnalyticsUserId = (userId) => {
  ReactGA.set({ userId: userId });
};

export const setAnalyticsSource = (source) => {
  ReactGA.set({ source });
};

// Standard GA / Facebook events

export const trackLoginEvent = (method) => {
  ReactPixel.track("Login", { method });

  ReactGA.event({
    category: "User",
    action: "Login",
    label: `via ${method}`,
    transport: "beacon",
  });
};

export const trackSignUpEvent = (method) => {
  ReactPixel.track("CompleteRegistration", { method });

  ReactGA.event({
    category: "User",
    action: "Sign Up",
    label: `via ${method}`,
    transport: "beacon",
  });
};

const trackAddItemsToCart = (currency, total, cartItems) => {
  ReactPixel.track("AddToCart", {
    currency: currency,
    value: total,
    content_type: "product",
    contents: cartItems,
  });

  for (let i = 0; i < cartItems.length; i += 1) {
    const item = cartItems[i];
    ReactGA.plugin.execute("ec", "addProduct", item);
  }

  ReactGA.plugin.execute("ec", "setAction", "add");
  ReactGA.event({
    category: "UX",
    action: "click",
    label: "addToCart",
  });

  // Track event to 'save' the ec data
  ReactGA.event({
    category: "Ecommerce",
    action: "click",
    label: "addToCart",
  });
};

const trackRemoveItemsFromCart = (currency, total, cartItems) => {
  ReactPixel.track("RemoveFromCart", {
    currency: currency,
    value: total,
    content_type: "product",
    contents: cartItems,
  });

  for (let i = 0; i < cartItems.length; i += 1) {
    const item = cartItems[i];
    ReactGA.plugin.execute("ec", "addProduct", item);
  }

  ReactGA.plugin.execute("ec", "setAction", "remove");

  // Track event to 'save' the ec data
  ReactGA.event({
    category: "Ecommerce",
    action: "click",
    label: "removeFromCart",
  });
};

export const trackAddReservationToCartEvent = (reservation) => {
  const currency = reservation.totalGrossAmount.currency;
  const total = calculateBookingTotal([reservation]);
  const cartItems = cartItemsFromReservation(reservation);

  trackAddItemsToCart(currency, total, cartItems);
};

export const trackRemoveReservationFromCartEvent = (reservation) => {
  const currency = reservation.totalGrossAmount.currency;
  const total = calculateBookingTotal([reservation]);
  const cartItems = cartItemsFromReservation(reservation);

  trackRemoveItemsFromCart(currency, total, cartItems);
};

export const trackAddServiceToCartEvent = (service, count) => {
  const currency = service.totalAmount.currency;
  const total = count * service.service.defaultGrossPrice.amount;
  const cartItems = [cartItemForService(service, count)];

  trackAddItemsToCart(currency, total, cartItems);
};

export const trackRemoveServiceFromCartEvent = (service, count) => {
  const currency = service.totalAmount.currency;
  const total = count * service.service.defaultGrossPrice.amount;
  const cartItems = [cartItemForService(service, count)];

  trackRemoveItemsFromCart(currency, total, cartItems);
};

export const trackBeginCheckoutEvent = (reservations, promoCode) => {
  const currency = reservations[0].totalGrossAmount.currency;
  const total = calculateBookingTotal(reservations);
  const cartItems = cartItemsFromReservations(reservations);

  ReactPixel.track("InitiateCheckout", {
    currency: currency,
    value: total,
    contents: cartItems,
  });

  for (let i = 0; i < cartItems.length; i += 1) {
    const item = cartItems[i];
    ReactGA.plugin.execute("ec", "addProduct", item);
  }

  ReactGA.plugin.execute("ec", "setAction", "checkout", {
    step: 1,
  });

  // Track non-interactive event to 'save' the ec data
  ReactGA.event({
    category: "Ecommerce",
    action: "Checkout",
    label: "Checkout",
    nonInteraction: true,
  });
};

export const trackPurchaseEvent = (
  reservations,
  promoCode,
  propertyId,
  transactionId,
  paymentMethod
) => {
  ReactGA.plugin.execute("ec", "setAction", "checkout_option", {
    step: 1,
    option: paymentMethod,
  });

  const currency = reservations[0].totalGrossAmount.currency;
  const total = calculateBookingTotal(reservations);
  const cartItems = cartItemsFromReservations(reservations);

  ReactPixel.track("Purchase", {
    currency: currency,
    value: total,
    content_type: "product",
    contents: cartItems,
  });

  for (let i = 0; i < cartItems.length; i += 1) {
    const item = cartItems[i];
    ReactGA.plugin.execute("ec", "addProduct", item);
  }

  ReactGA.plugin.execute("ec", "setAction", "purchase", {
    id: transactionId,
    affiliation: propertyId,
    revenue: total,
    tax: calculateBookingTotalVat(reservations),
    coupon: promoCode,
    currency: currency,
  });

  // Track non-interactive event to 'save' the ec data
  ReactGA.event({
    category: "Ecommerce",
    action: "Purchase",
    label: "Purchase",
    nonInteraction: true,
  });
};

export const trackRefundEvent = (reservation) => {
  ReactGA.plugin.execute("ec", "setAction", "refund", {
    id: reservation.id,
  });

  // Track event to 'save' the ec data
  ReactGA.event({
    category: "Ecommerce",
    action: "Refund",
    label: "Refund",
  });
};

export const trackError = (description, fatal) => {
  ReactGA.exception({ description, fatal });
};

export const trackPageView = (pageName) => {
  ReactPixel.pageView();

  ReactGA.set({ page: pageName });
  ReactGA.pageview(pageName);
};

//
// Helpers
//

// Takes a reservation and returns an array of cart items for analytics
const cartItemsFromReservation = (reservation) => {
  let items = [];

  // Add the room
  items.push({
    id: reservation.unitGroup.id,
    name: reservation.unitGroup.name,
    category:
      reservation.unitGroup.code === EVPARKING_UNITGROUP_CODE
        ? "Parking"
        : "Room",
    price: reservation.totalGrossAmount.amount,
    quantity: 1,
  });

  // Add any services
  if (reservation.services) {
    items = items.concat(
      reservation.services.map((s) => cartItemForService(s, s.count))
    );
  }

  return items;
};

const cartItemForService = (service, count) => {
  return {
    id: service.service.id,
    name: service.service.name,
    category: "Add-On",
    price: service.service.defaultGrossPrice.amount,
    quantity: count,
  };
};

// Takes an array of reservations and returns cart items for them all
const cartItemsFromReservations = (reservations) => {
  return reservations.reduce(
    (acc, reservation) => [...acc, ...cartItemsFromReservation(reservation)],
    []
  );
};
