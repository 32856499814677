import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  prefetchAvailability: {
    loading: false,
    viewed: false,
    data: {},
  },
  diningSelections: {},
};

const diningSlice = createSlice({
  name: "dining",
  initialState,
  reducers: {
    setPrefetchAvailabilityLoading: (state, action) => {
      state.prefetchAvailability.loading = action.payload;
    },
    setPrefetchAvailabilityViewed: (state, action) => {
      state.prefetchAvailability.viewed = action.payload;
    },
    setPrefetchAvailabilityData: (state, action) => {
      state.prefetchAvailability.data = action.payload;
    },
    resetPrefetchAvailability: (state, action) => {
      state.prefetchAvailability = initialState.prefetchAvailability;
    },
    addDiningSelections: (state, { payload }) => {
      state.diningSelections = payload;
    },
    removeDiningSelections: (state) => {
      state.diningSelections = initialState.diningSelections;
    },
  },
});

export const {
  setPrefetchAvailabilityLoading,
  setPrefetchAvailabilityViewed,
  setPrefetchAvailabilityData,
  resetPrefetchAvailability,
  addDiningSelections,
  removeDiningSelections,
} = diningSlice.actions;
export default diningSlice.reducer;
