// AJD: TODO - Standardise to use date-fns rather than moment

export const DINING_DATES_IN = "YYYY-MM-DD";
export const DINING_DATES_OUT = "ddd D MMM";
export const DINING_TIMES_IN = "YYYY-MM-DD HH:mm:ss";
export const DINING_TIMES_OUT = "HH:mm";

export const ENTERTAINMENT_START = "HH:mm";

export const CHECK_INOUT_IN = "HH:mm:ss";
export const CHECK_INOUT_OUT = "HH:mm a";

export const ARRIVAL_IN = DINING_DATES_IN;
export const ARRIVAL_OUT = "dddd DD MMM";
export const ITINERARY_OUT = "dddd DD MMMM";
