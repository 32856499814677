import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Breadcrumbs,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import { STEPS } from "utils/constants";

const useStyle = makeStyles((theme) => ({
  breadcrumb: {
    marginBottom: "-1px",
  },
  breadcrumbItem: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(2.5),
    color: theme.palette.text.primary,
    "& p": {
      marginLeft: theme.spacing(1),
      margin: "0",
    },
    "& span": {
      background: theme.palette.grey[700],
      color: theme.palette.common.white,
      width: "24px",
      height: "24px",
      textAlign: "center",
      borderRadius: "100%",
    },
  },
  activeBreadcrumbItem: {
    "& span": {
      background: theme.palette.common.black,
    },
  },
  selectedBreadcrumbItem: {
    borderBottom: `1px solid ${theme.palette.common.black}`,
    "& span": {
      background: theme.palette.common.black,
    },
  },
  step: {
    // Custom font for the step number
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "24px",
  },
}));

const StepBreadcrumb = ({ currentStep }) => {
  const classes = useStyle();
  const theme = useTheme();
  const desktopUpScreen = useMediaQuery(theme.breakpoints.up("desktop"));

  return (
    <Box mb={3}>
      <Box mb={2.5}>
        <Divider />
      </Box>
      <Breadcrumbs
        className={classes.breadcrumb}
        separator={null}
        aria-label="breadcrumb"
      >
        {[
          STEPS.CHOOSE_ROOM.STEPPER_TITLE,
          STEPS.SELECT_DINING_TIMES.STEPPER_TITLE,
          STEPS.SELECT_ENTERTAINMENT.STEPPER_TITLE,
          STEPS.SELECT_ADDONS.STEPPER_TITLE,
          STEPS.CHECKOUT.STEPPER_TITLE,
          STEPS.SUMMARY.STEPPER_TITLE,
        ].map((description, index) => {
          const step = index + 1;
          const isSelected = currentStep === step;
          const isActive = step <= currentStep;
          return (
            <div
              className={classNames(
                classes.breadcrumbItem,
                isSelected ? classes.selectedBreadcrumbItem : null,
                isActive ? classes.activeBreadcrumbItem : null
              )}
              key={index}
            >
              <span className={classes.step}>{step}</span>
              {desktopUpScreen || isSelected ? (
                <Typography variant="overline" component="p">
                  {description}
                </Typography>
              ) : null}
            </div>
          );
        })}
      </Breadcrumbs>
      <Divider />
    </Box>
  );
};

StepBreadcrumb.propTypes = {
  currentStep: PropTypes.number,
};

export default StepBreadcrumb;
