import { calculateBookingTotal } from "utils/bookingTotals";
import { guestCountForReservation } from "utils/helpers";
import { DELIMITER } from "utils/constants";
import { formatInTimezone } from "utils/timekeep";

Date.prototype.addDays = function (days) {
  var dat = new Date(this.valueOf());
  dat.setDate(dat.getDate() + days);
  return dat;
};

function calculateReservationTotal(reservation) {
  // Sum of the room total, and all service total
  const roomTotal = reservation.totalGrossAmount.amount;
  const servicesTotal = reservation.services.reduce(
    (accumulator, service) => accumulator + service.totalAmount.grossAmount,
    0
  );

  return roomTotal + servicesTotal;
}

const generateBookingData = (bookingData) => {
  const paymentResponse = bookingData.paymentResponse;

  let booking = {
    paymentAccount: {},
    booker: null,
    reservations: [],
    giftCardNumber: bookingData.giftCard
      ? bookingData.giftCard.card_reference
      : null,
    total: {
      amount: calculateBookingTotal(bookingData.bookingReservations),
      currency: "GBP",
    },
    propertyId: bookingData.propertyId,
    transactionReference: paymentResponse ? paymentResponse.pspReference : null,
  };

  if (paymentResponse) {
    booking["paymentAccount"] = {
      accountNumber: paymentResponse.additionalData.cardSummary,
      accountHolder: paymentResponse.additionalData.cardHolderName,
      expiryMonth: paymentResponse.additionalData.expiryDate.split("/")[0],
      expiryYear: paymentResponse.additionalData.expiryDate.split("/")[1],
      paymentMethod: paymentResponse.additionalData.paymentMethod,
      payerReference: paymentResponse.shopperReference,
      isVirtual: false,
    };
  }

  booking["booker"] = {
    firstName: bookingData.guestDetails.firstName,
    middleInitial: bookingData.guestDetails.middleInitial,
    lastName: bookingData.guestDetails.lastName,
    email: bookingData.guestDetails.email,
    phone: bookingData.guestDetails.phoneNumber,
  };

  let remainingGiftCardBalance_100 = bookingData.giftCard
    ? bookingData.giftCard.balance
    : 0;

  bookingData.bookingReservations.forEach((reservation, idx) => {
    // Calculate the prepayment amount, using gift card balance where possible
    // Word in pennies to avoid floating point rounding errors
    const reservationTotal_100 = calculateReservationTotal(reservation) * 100;

    const giftCardToRedeem_100 = Math.min(
      reservationTotal_100,
      remainingGiftCardBalance_100
    );

    const prepaymentTotal_100 = Math.max(
      reservationTotal_100 - giftCardToRedeem_100,
      0
    );

    remainingGiftCardBalance_100 -= giftCardToRedeem_100;

    let reservationObj = {
      arrival: reservation.arrival,
      departure: reservation.departure,
      channelCode: "Ibe", // HARD CODED
      guaranteeType: "Prepayment", // HARD CODED
      timeSlices: reservation.timeSlices.map(() => ({
        ratePlanId: reservation.ratePlan.id,
      })),
      services: reservation.services.map((service) => {
        return {
          serviceId: service.service.id,
          count: service.count,
        };
      }),
      prepaymentAmount: {
        amount: prepaymentTotal_100 / 100.0, // convert back to £
        currency: reservation.totalGrossAmount.currency,
      },
      adults: reservation.adults,
      childrenAges: reservation.childrenAges,
      companyId: reservation.companyId,
      corporateCode: reservation.corporateCode,
      promoCode: reservation.promoCode,
    };
    if (reservation.unitId) {
      reservationObj.unitId = reservation.unitId;
    }

    if (paymentResponse) {
      reservationObj.externalCode = `${paymentResponse.merchantReference}-${
        idx + 1
      }`; // Set to merchant ref for reconcilliation purposes
    }

    if (reservation.unitGroup.code !== "PARKING") {
      // add room reservation details
      reservationObj = Object.assign({}, reservationObj, {
        guestComment: bookingData.guestDetails.comments,
      });
    }
    booking["reservations"].push(reservationObj);
  });

  // Determine the number of guests, which is needed for our dinner and entertainment reservations
  const totalGuests = guestCountForReservation(
    bookingData.bookingReservations[0]
  );

  // Add the dinner reservations
  // AJD: TODO - This feels a bit hacky, should probably revisit
  if (bookingData.diningSelections) {
    booking["tableReservations"] = [];
    for (const date in bookingData.diningSelections) {
      for (const shift in bookingData.diningSelections[date]) {
        const shiftKey = Object.keys(
          bookingData.diningSelections[date][shift]
        )[0];
        const restaurantInfo = shiftKey.split(DELIMITER);
        booking["tableReservations"].push({
          venueId: restaurantInfo[0].split(":")[0],
          date: date,
          time: bookingData.diningSelections[date][shift][shiftKey],
          partySize: totalGuests,
          notes: restaurantInfo[1],
        });
      }
    }
  }

  // Add the entertainment reservations
  if (bookingData.entertainmentSelections) {
    booking["entertainmentReservations"] = [];
    for (const date in bookingData.entertainmentSelections) {
      const event = bookingData.entertainmentSelections[date];
      booking["entertainmentReservations"].push({
        venueId: event.venueId,
        date,
        time: formatInTimezone(
          event.date,
          bookingData.propertyTimezone,
          "HH:mm"
        ),
        partySize: totalGuests,
        notes: `${event.title} (Event ID: ${event.id})`,
      });
    }
  }

  // Add the afternoon tea reservations
  if (bookingData.afternoonTeaSelections) {
    booking["afternoonTeaReservations"] = [];
    for (const date in bookingData.afternoonTeaSelections) {
      const reservation = bookingData.afternoonTeaSelections[date];
      booking["afternoonTeaReservations"].push({
        venueId: reservation.venueId,
        date,
        time: reservation.time,
        partySize: totalGuests,
        notes: `Afternoon Tea`,
      });
    }
  }

  return booking;
};

export default generateBookingData;
