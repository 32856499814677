import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import ActionSheet from "components/ActionSheet";
import CheckInDatePicker from "components/CheckInDatePicker";

const useStyles = makeStyles((theme) => ({
  calendar: {
    margin: "30px 25px",
  },
}));

const CheckInDateActionSheet = (props) => {
  const classes = useStyles();

  const [date, setDate] = useState(props.date);

  const onCancel = () => {
    // Reset action sheet state to match the caller
    setDate(props.date);

    props.onCancel();
  };

  const onOK = () => {
    // Pass back the new date to the caller
    props.onOK(date);
  };

  return (
    <ActionSheet
      title="Check In Date"
      open={props.open}
      onCancel={onCancel}
      onOK={onOK}
    >
      <div className={classes.calendar}>
        <CheckInDatePicker
          date={date}
          dateUpdated={setDate}
          priceForDate={props.priceForDate}
          onMonthChanged={props.onMonthChanged}
          allowDatesFrom={props.allowDatesFrom}
        />
      </div>
    </ActionSheet>
  );
};

CheckInDateActionSheet.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onOK: PropTypes.func,
  date: PropTypes.instanceOf(Date),
  priceForDate: PropTypes.func,
  onMonthChanged: PropTypes.func,
  allowDatesFrom: PropTypes.instanceOf(moment),
};

export default CheckInDateActionSheet;
