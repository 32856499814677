import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedAfternoonTea: {},
};

const afternoonTeaSlice = createSlice({
  name: "afternoonTea",
  initialState,
  reducers: {
    setSelectedAfternoonTea: (state, action) => {
      state.selectedAfternoonTea = action.payload;
    },
    removeSelectedAfternoonTea: (state) => {
      state.selectedAfternoonTea = initialState.selectedAfternoonTea;
    },
  },
});

export const {
  setSelectedAfternoonTea,
  removeSelectedAfternoonTea,
} = afternoonTeaSlice.actions;
export default afternoonTeaSlice.reducer;
