import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { formatCurrency } from "utils/helpers";
import { Grid, Typography, Button } from "@material-ui/core";
import PayAtMachineImage from "images/pay_at_machine.png";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

const useStyles = makeStyles((theme) => ({
  makeBookingContainer: {
    padding: theme.spacing(3),
  },
  makeBookingButton: {
    width: "100%",
    background: theme.palette.secondary.main,
  },
  lockIcon: {
    marginRight: "5px",
  },
}));

const TerminalPaymentWidget = (props) => {
  const classes = useStyles();

  return (
    <Grid>
      <Typography variant="subtitle1" color="textPrimary">
        Payment
      </Typography>
      <br />
      <Typography variant="body2" color="textPrimary">
        When you’re ready, press ‘pay,’ then pay at the machine.
      </Typography>
      <br />
      <img src={PayAtMachineImage} alt="Pay at machine" width="100%" />
      <br />
      <br />
      <Grid className={classes.makeBookingContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={props.onBookButtonClicked}
          className={classes.makeBookingButton}
        >
          <LockOutlinedIcon className={classes.lockIcon} />
          Pay - {formatCurrency(props.currency, props.amount)}
        </Button>
      </Grid>
    </Grid>
  );
};

TerminalPaymentWidget.propTypes = {
  onBookButtonClicked: PropTypes.func,
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};

export default TerminalPaymentWidget;
