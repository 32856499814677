import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { Grid, Button, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowForward } from "@material-ui/icons";

import StepBreadcrumb from "components/StepBreadcrumb";
import Heading from "components/Heading";
import EditAvailCheckModal from "components/EditAvailCheckModal";
import Navbar from "components/Navbar";
import LoadingSpinner from "components/LoadingSpinner";
import Basket from "components/Basket";
import ChooseRoomFilters from "components/ChooseRoomFilters";
import FooterNavBar from "components/FooterNavBar";

import { useSearchParams } from "hooks";
import { isKioskFromSearchParams } from "utils/helpers";
import { setIndividualRoomAvailability } from "features/room/roomSlice";
import {
  addRoomSelectionToRoomReservation,
  removeRoomSelectionFromRoomReservation,
} from "features/booking/bookingSlice";
import api from "utils/api";
import { SELECT_DINING_TIMES, PLAN_STAY } from "config/routes";
import { STEPS } from "utils/constants";

import roomMap from "images/Room_Map.png";

const useStyles = makeStyles((theme) => ({
  root: {},
  mainContent: {
    flexDirection: "column",
    margin: "0 auto 1rem auto",
    paddingBottom: "5rem",
    width: theme.contentSize.mobilePageWidth,
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      width: theme.contentSize.pageWidth,
      maxWidth: theme.contentSize.maxPageWidth,
    },
  },
  splitContent: {
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      flexDirection: "row",
    },
  },
  detailsContainer: {
    flexGrow: 1,
  },
  basket: {
    width: "100%",
    marginBottom: "20px",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      marginLeft: theme.spacing(8),
      width: "280px",
      flexShrink: 0,
    },
  },
  hidden: {
    display: "none",
  },
  chip: {
    minWidth: "50px",
    marginRight: theme.spacing(0.5),
    marginBottom: "20px",
    backgroundColor: "#FFFFFF",
  },
  chipSelectable: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.border,
    "&:hover, &:active, &:focus": {
      backgroundColor: "#FFFFFF !important", // AJD - Not ideal but need a quick solution for POC
    },
  },
  chipSelected: {
    color: "#FFFFFF",
    backgroundColor: theme.palette.primary.main,
    "&:hover, &:active, &:focus": {
      backgroundColor: "#3F7D91 !important", // AJD - Not ideal but need a quick solution for POC
    },
  },
  chipSelectedDeleteIcon: {
    color: "#FFFFFF",
    "&:hover, &:active, &:focus": {
      color: "#FFFFFF !important", // AJD - Not ideal but need a quick solution for POC
    },
  },
  roomMap: {
    marginTop: "10px",
    maxWidth: "100%",
  },
}));

// AJD: TODO - Refactor into a 'BookingStep' component, which contains all the common elements
const ChooseRoomNumber = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedPropertyId = useSelector(
    (state) => state.property.selectedPropertyId
  );
  const roomReservations = useSelector(
    (state) => state.booking.roomReservations
  );
  const selectedUnitGroupId = roomReservations[0]?.unitGroup?.id;
  const selectedUnitId = roomReservations[0]?.unitId;
  const selectedArrival = roomReservations[0]?.arrival;
  const selectedDeparture = roomReservations[0]?.departure;
  const individualRoomAvailability = useSelector(
    (state) => state.room.individualRoomAvailability
  );
  const searchParams = useSearchParams();

  const [editAvailCheckModalOpen, setEditAvailCheckModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [roomFilters, setRoomFilters] = useState({});

  const handleEditAvailCheckModalClose = () => {
    setEditAvailCheckModalOpen(false);
  };

  const skipToNextStep = () => {
    // Clear any existing selections from redux
    handleRemoveIndividualRoom();

    // Move to next step
    props.history.push({
      pathname: SELECT_DINING_TIMES,
      data: {},
      state: {},
    });
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleContinue = () => {
    // We write any rooms selections directly into redux, so don't need to dispatch any actions here

    // Move to the add-ons step
    props.history.push({
      pathname: SELECT_DINING_TIMES,
      data: {},
      state: {},
    });
  };

  useEffect(() => {
    // Check the room type has been selected - otherwise redirect to the start
    if (!selectedUnitGroupId) {
      props.history.push(PLAN_STAY);
      return;
    }

    // Fetch individual room availability for the chosen unit type
    const individualRoomAvailabilityRequest = api.getIndividualRoomAvailabilities(
      {
        propertyId: selectedPropertyId,
        unitGroupId: selectedUnitGroupId,
        from: selectedArrival,
        to: selectedDeparture,
      }
    );
    Promise.all([individualRoomAvailabilityRequest])
      .then((responses) => {
        const individualRoomAvailabilityResponse = responses[0].data;

        // Collate available and unavailable into one list
        const roomsList = [];
        individualRoomAvailabilityResponse.available_units.map((room) => {
          roomsList.push({
            id: room.id,
            name: room.name,
            description: room.description,
            attributes: room.attributes,
            available: true,
          });
        });
        individualRoomAvailabilityResponse.unavailable_units.map((room) => {
          roomsList.push({
            id: room.id,
            name: room.name,
            description: room.description,
            attributes: room.attributes,
            available: false,
          });
        });

        // Sort by room number
        roomsList.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          return 1;
        });

        // Update Redux
        dispatch(
          setIndividualRoomAvailability({
            unitGroupId: selectedUnitGroupId,
            availability: roomsList,
          })
        );

        // Remove our loading gif
        setLoading(false);
      })
      .catch((error) => {
        props.onNotifOpen(error.message, { variant: "error" });
        props.history.push(PLAN_STAY);
      });
  }, []);

  // Addin roomName does duplicate part of the state. Ideally we should just store the unit ID, and then lookup the name later.
  const handleSelectIndividualRoom = (unitId, roomName) => {
    dispatch(
      addRoomSelectionToRoomReservation({
        reservationIdx: 0,
        unitId,
        roomName,
      })
    );
  };

  const handleRemoveIndividualRoom = () => {
    dispatch(
      removeRoomSelectionFromRoomReservation({
        reservationIdx: 0,
      })
    );
  };

  const applyRoomFilters = (availabilities) => {
    return availabilities.filter((availability) => {
      // Loop through currently selected filter options
      for (const filterKey in roomFilters) {
        // Check if a filter is specified
        const filterValue = roomFilters[filterKey]?.trim();
        if (filterValue) {
          // The filter value could represent multiple attributes, so we need to split the list
          const attributeFilterList = filterValue.split("~");

          // Check each attribute name against the list of filter values, to see if we have a match
          let attributeMatch = false;
          if (availability.attributes) {
            for (let b = 0; b < availability.attributes.length; b += 1) {
              const attributeName = availability.attributes[b].name;
              if (attributeFilterList.indexOf(attributeName) !== -1) {
                attributeMatch = true;
                break;
              }
            }
          }

          // If we didn't match on a specified attribute, filter this unit out
          if (!attributeMatch) {
            return false;
          }
        }
      }

      // All filter checks passed
      return true;
    });
  };

  return (
    <div className={classes.root}>
      {!isKioskFromSearchParams(searchParams) && (
        <Navbar withProfile onNotifOpen={props.onNotifOpen} />
      )}
      <Grid container className={classes.mainContent}>
        <StepBreadcrumb currentStep={STEPS.CHOOSE_ROOM_NUMBER.INDEX} />
        <Heading titleText={STEPS.CHOOSE_ROOM_NUMBER.PAGE_TITLE}>
          <Button endIcon={<ArrowForward />} onClick={skipToNextStep}>
            Skip (Do this later)
          </Button>
        </Heading>
        <LoadingSpinner loading={loading} />
        <Grid
          container
          className={classNames(
            classes.splitContent,
            loading ? classes.hidden : null
          )}
        >
          <ChooseRoomFilters
            currentFilters={roomFilters}
            setFilters={setRoomFilters}
          />
          <Grid item className={classes.detailsContainer}>
            <div>
              {!selectedUnitId &&
                applyRoomFilters(
                  individualRoomAvailability[selectedUnitGroupId] || []
                )?.map((room, index) => {
                  return (
                    <Chip
                      key={index}
                      className={`${classes.chip}${
                        room.available ? ` ${classes.chipSelectable}` : ""
                      }`}
                      variant="outlined"
                      label={room.name}
                      disabled={!room.available}
                      onClick={((unitId) => () =>
                        handleSelectIndividualRoom(unitId, room.name))(room.id)}
                    />
                  );
                })}
              {!!selectedUnitId && (
                <Chip
                  className={classes.chipSelected}
                  classes={{
                    deleteIcon: classes.chipSelectedDeleteIcon,
                  }}
                  variant="default"
                  label={
                    individualRoomAvailability[selectedUnitGroupId]?.find(
                      (room) => room.id === selectedUnitId
                    ).name
                  }
                  onDelete={handleRemoveIndividualRoom}
                />
              )}
            </div>
            <img src={roomMap} alt="Room Map" className={classes.roomMap} />
          </Grid>
          <Basket className={classes.basket} onNotifOpen={props.onNotifOpen} />
        </Grid>
      </Grid>
      <FooterNavBar onBack={handleBack} onContinue={handleContinue} />
      <EditAvailCheckModal
        open={editAvailCheckModalOpen}
        onClose={handleEditAvailCheckModalClose}
      />
    </div>
  );
};

ChooseRoomNumber.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  onNotifOpen: PropTypes.func,
};

export default ChooseRoomNumber;
