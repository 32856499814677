import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  maxRedirects: 0,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export default {
  getRoomDetails: () => axios.get(`${process.env.REACT_APP_CMS_URL}/rooms`),
  getProperties: () =>
    instance({
      method: "GET",
      url: "/api/v1/properties",
    }),
  getBookingAddons: (propertyCode) =>
    axios.get(
      `${process.env.REACT_APP_CMS_URL}/booking-addons?properties.code=${propertyCode}`
    ),
  getAvailabilities: (params) =>
    instance({
      method: "GET",
      url: "/api/v1/availabilities",
      params: params,
    }),
  getIndividualRoomAvailabilities: (params) =>
    instance({
      method: "GET",
      url: "/api/v1/availabilities/unit_availabilities",
      params: params,
    }),
  getPricingIndex: (params) =>
    instance({
      method: "GET",
      url: "/api/v1/availabilities/pricing_index",
      params: params,
    }),
  getServices: (params) =>
    instance({
      method: "GET",
      url: "/api/v1/services",
      params: params,
    }),
  getBooking: (params) =>
    instance({
      method: "GET",
      url: "/api/v1/bookings",
      params: params,
    }),
  getBookingFromReservation: (params) =>
    instance({
      method: "GET",
      url: "/api/v1/bookings/lookup",
      params: params,
    }),
  makeBooking: (data) =>
    instance({
      method: "POST",
      url: "/api/v1/bookings",
      data: data,
    }),
  getReservation: (params) =>
    instance({
      method: "GET",
      url: "/api/v1/reservations",
      params: params,
    }),
  cancelReservation: (params) =>
    instance({
      method: "DELETE",
      url: "/api/v1/reservations",
      params: params,
    }),
  cancelBooking: (propertyId, bookingId) =>
    instance({
      method: "DELETE",
      url: `/api/v1/bookings/${bookingId}?propertyId=${propertyId}`,
    }),
  getPaymentMethods: (data) =>
    instance({
      method: "POST",
      url: "/api/v1/payment_methods",
      data: data,
    }),
  makePayment: (data) =>
    instance({
      method: "POST",
      url: "/api/v1/make_payment",
      data: data,
    }),
  getApplePaySession: () =>
    instance({
      method: "GET",
      url: "/api/v1/applepay_payment_session",
    }),
  getProfile: (firebaseAuthToken) =>
    instance({
      method: "GET",
      url: "/api/v1/profile",
      headers: {
        Authorization: firebaseAuthToken,
      },
    }),
  updateProfile: (firebaseAuthToken, data) =>
    instance({
      method: "PUT",
      url: "/api/v1/profile",
      data: data,
      headers: {
        Authorization: firebaseAuthToken,
      },
    }),
  signIntoFirebaseWithOAuth: (data, params) =>
    axios({
      method: "POST",
      url: "https://identitytoolkit.googleapis.com/v1/accounts:signInWithIdp",
      data: data,
      params: params,
    }),
  verifyFirebaseToken: (firebaseAuthToken) =>
    instance({
      method: "POST",
      url: "/api/v1/verify_firebase_token",
      headers: {
        Authorization: firebaseAuthToken,
      },
    }),
  getGiftCardBalance: (giftCardNumber) =>
    instance({
      method: "GET",
      url: `/api/v1/gift_cards/balance?card_reference=${giftCardNumber}`,
    }),
  payByTerminal: (bookingId, paymentTerminalId) =>
    instance({
      method: "POST",
      url: "/api/v1/payments/by_terminal",
      data: { bookingId, paymentTerminalId },
    }),
  getPaymentStatus: (paymentId, folioId) =>
    instance({
      method: "GET",
      url: `/api/v1/payments/status?paymentId=${paymentId}&folioId=${folioId}`,
    }),
  cancelTerminalPayment: (paymentId, folioId) =>
    instance({
      method: "PUT",
      url: "/api/v1/payments/cancel_terminal",
      data: { paymentId, folioId },
    }),
  getVenuesImages: () =>
    axios.get(`${process.env.REACT_APP_CMS_URL}/facilities?type=Restaurant`),
  getVenuesAvailabilities: (params) =>
    instance({
      method: "GET",
      url: "/api/v1/sevenrooms/availabilities",
      params,
    }),
  getEntertainment: ({
    startDate = new Date().toISOString(),
    endDate,
    performanceValue,
  }) =>
    axios.get(
      `${process.env.REACT_APP_CMS_URL}/events?&start_time_gte=${startDate}${
        endDate ? `&start_time_lte=${endDate}` : ""
      }${
        performanceValue ? `&performance_value=${performanceValue}` : ""
      }&_sort=start_time:ASC`
    ),
};
