import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";

export const selectAvailCheckParams = (roomIndex = 1) =>
  getOr({}, ["booking", "availCheckParams", roomIndex]);

export const selectCommonSearchParams = getOr({}, [
  "booking",
  "commonSearchParams",
]);

export const selectRoomReservations = get(["booking", "roomReservations"]);

export const selectDiningSelections = getOr({}, ["dining", "diningSelections"]);

export const selectPrefetchAvailability = getOr({}, [
  "dining",
  "prefetchAvailability",
]);

export const selectEntertainmentSelections = getOr({}, [
  "entertainment",
  "selectedEntertainment",
]);

export const selectAfternoonTeaSelections = getOr({}, [
  "afternoonTea",
  "selectedAfternoonTea",
]);

export const selectParkingReservations = get([
  "booking",
  "parkingReservations",
]);
