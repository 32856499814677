import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  upcomingEntertainmentHighlights: [],
  duringStayEntertainment: [],
  selectedEntertainment: {},
};

const entertainmentSlice = createSlice({
  name: "entertainment",
  initialState,
  reducers: {
    setUpcomingEntertainmentHighlights: (state, action) => {
      state.upcomingEntertainmentHighlights = action.payload;
    },
    setDuringStayEntertainment: (state, action) => {
      state.duringStayEntertainment = action.payload;
    },
    setSelectedEntertainment: (state, action) => {
      state.selectedEntertainment = action.payload;
    },
    removeSelectedEntertainment: (state) => {
      state.selectedEntertainment = initialState.selectedEntertainment;
    },
  },
});

export const {
  setUpcomingEntertainmentHighlights,
  setDuringStayEntertainment,
  setSelectedEntertainment,
  removeSelectedEntertainment,
} = entertainmentSlice.actions;
export default entertainmentSlice.reducer;
